import React from 'react';
import styled from 'styled-components';
import { Field, useFormState } from 'react-final-form';
import { Box } from '@material-ui/core';
import { colors } from 'kards-components';

import ContributionProvider from '../../../context/contribution';
import ThemeProvider from '../../../context/theme';
import { WindowEdit } from './components';
import { getTemplate } from './components/view/cardTemplate/getTemplate';
import Header from './components/mock/Header';
import Footer from './components/mock/Footer';

const CardEditWrapper = styled.div`
  background: ${(props) => colors[props.color]};
  box-shadow: 0 4px 16px rgba(0,0,0,.12);
  width: 40vw;
  height: 75vh;
  overflow: hidden;
  max-width: 450px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const StructureWrapper = styled.div`
  flex: .88;
  overflow: hidden;
  width: 100%;
`;

const CardEdit = ({ template, theme }) => (
  <ThemeProvider theme={theme}>
    <CardEditWrapper
      style={{
        boxShadow: '0 4px 16px rgba(0,0,0,.12)',
        margin: '-10px',
      }}
      color={theme.color.background}
    >
      <Header template={template} />
      <StructureWrapper>
        {template.structure()}
      </StructureWrapper>
      <Footer />
    </CardEditWrapper>
  </ThemeProvider>
);

const Component = ({ input }) => {
  const formState = useFormState();
  const template = getTemplate(formState.values.template.name);
  const { theme } = formState.values;

  return (
    <ContributionProvider template={template} data={input.value} onChange={input.onChange}>
      <Box display="flex" flexDirection="row" height="100%" width="100%">
        <Box flex={2} display="flex" flexDirection="column">
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              flex={0.6}
              height="100%"
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CardEdit template={template} theme={theme} />
            </Box>
          </Box>
        </Box>
        <Box
          flex={1}
          style={{ overflow: 'scroll', backgroundColor: 'white' }}
          bgcolor="secondary.main"
          mr="0"
          ml="0"
          margin="0"
          boxShadow="0 4px 16px rgba(0,0,0,.12);"
        >
          <WindowEdit template={template} />
        </Box>
      </Box>
    </ContributionProvider>
  );
};

export default ({ record }) => <Field name="components" record={record} component={Component} />;
