/* eslint-disable max-len */
import React from 'react';
import { Box, RadioGroup, Radio } from '@material-ui/core';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const Label = styled.label`
  font-size: 1em;
  font-weight: bold;
`;

const Image = styled.img`
  width: 65px;
  height: 65px;
`;

// const Lydia = styled.img`
//   width: 50px;
// `;

const Description = styled.p`
  text-align: left;
`;

export function Debit(props) {
  return (
    <RadioGroup name={props.name} value={props.input.value} onChange={props.input.onChange}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box flex=".45" padding="1em">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding=".5em"
            >
              <ImageWrapper onClick={() => { }}>
                <Image src="/img/utils/speed.svg" />
              </ImageWrapper>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="row">
              <Box>
                <Radio
                  color="secondary"
                  value="hight"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Box>
              <Box>
                <Label>Haut debit</Label>
                <Description>
                  {`Conseillez pour une consommation "sur place", votre préparateur
                n'a pas besoin de préciser le temps de préparation pour chaque commande.`}
                </Description>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box flex=".45" padding="1em">

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding=".5em"
            >
              <ImageWrapper onClick={() => { }}>
                <Image src="/img/utils/slow.svg" />
              </ImageWrapper>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="row">
              <Box>
                <Radio
                  color="secondary"
                  value="low"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Box>
              <Box>
                <Label>Bas débit</Label>
                <Description>
                  {`Conseillez pour une consommation "à emporter", votre
                préparateur ajustera le temps de préparation à chaque commande.`}
                </Description>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </RadioGroup>
  );
}

// eslint-disable-next-line no-lone-blocks
{ /* <Box flex=".33" padding="1em">
  <Box>
    <Checkbox
      color="primary"
      checked={state.cash}
      onChange={handleChange('cash')}
      value="cash"
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  </Box>
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding=".5em"
    >
      <ImageWrapper onClick={() => { }}>
        <Image src="/img/utils/euro.svg" />
      </ImageWrapper>
      <Label>Espèces</Label>
    </Box>
    <Description>
      Sélectionnez ce mode pour que les utilisateurs puissent payer en espèces.
    </Description>
  </Box>
</Box> */ }
// eslint-disable-next-line no-lone-blocks
{ /* <Box flex=".33" padding="1em">
  <Box>
    <Checkbox
      color="primary"
      checked={state.lydia}
      onChange={handleChange('lydia')}
      value="lydia"
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  </Box>
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding=".5em"
    >
      <ImageWrapper onClick={() => { }}>
        <Lydia src="/img/utils/lydia.png" />
      </ImageWrapper>
      <Label>Lydia</Label>
    </Box>
    <Description>
      Sélectionnez ce mode pour que les utilisateurs puissent payer avec Lydia.
    </Description>
  </Box>
</Box> */ }
