import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Box, ButtonGroup, IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useQuery } from 'src/helpers/useQuery';
import { getOrdersByDate } from 'src/data/accessor/getOrders';
import { getPWAp } from 'src/data/accessor/pwa';
import { Divider } from './components/Divider';
import { Card } from './components/Card';
import { Title } from './components/Title';
import { Table, TableRow, TableCell } from 'src/layout/Table/Table';
import { DatePicker } from 'src/layout/DatePicker';
import { downloadCSV } from 'src/helpers/downloadCSV';
import { getCSV, getCA, getTVA, getStatistics, getDetails, getDisplayFrom, getDisplayTo, getTurnoverByProducts } from './utils';
import { Now } from './components/Now';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'src/types';
import { DayNavigation } from './components/DayNavigation';
import { ZcaissePDF } from './ZcaissePDF';
import { PDFDownloadLink } from '@react-pdf/renderer';

export function Zcaisse() {
  const { from, to, setFrom, setTo, setCount } = useMangeInterval();
  const { data: orders } = useQuery(() => getOrdersByDate(from, to), [from, to]);
  const { data: pwa } = useQuery(() => getPWAp());

  const handleDownloadCSV = () => {
    const csv = getCSV({ orders, from, to });
    downloadCSV({ content: csv, fileName: 'export.csv' });
  };

  const filePDFName = 'ZcaisseDu' + moment().format('DD-MM-YYYY') + '.pdf';

  return (
    <Box style={{ overflow: 'scroll', height: '87vh' }} p={2} display="flex" alignItems="center" flexDirection="column" width="100%">
      <Box my={1} display="flex" justifyContent="space-between" alignItems="center" width="100%" id="zcaisse-actions">
        <Box display="flex">
          <Box mr={2}>
            <DatePicker
              label="Date de début"
              value={new Date(from)}
              dateFormat="dd/MM/yyyy HH:mm"
              timeFormat="HH:mm"
              showTimeSelect
              onChange={(v: Date) => v && setFrom(v.getTime())}
            />
          </Box>

          <DatePicker
            label="Date de fin"
            value={new Date(to)}
            dateFormat="dd/MM/yyyy HH:mm"
            timeFormat="HH:mm"
            showTimeSelect
            onChange={(v: Date) => v && setTo(v.getTime())}
          />
        </Box>

        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
          <PDFDownloadLink document={<ZcaissePDF orders={orders} infos={pwa} from={from} to={to} />} fileName={filePDFName}>
            <IconButton>
              <PrintIcon style={{ fill: 'black' }} />
            </IconButton>
          </PDFDownloadLink>
          <IconButton onClick={handleDownloadCSV}>
            <GetAppIcon />
          </IconButton>
        </ButtonGroup>
      </Box>

      <DayNavigation id="zcaisse-nav-interval" mt={1} mb={2} onLess={() => setCount(c => c - 1)} onMore={() => setCount(c => c + 1)}>
        {moment(from).format('dddd DD MMMM YYYY')}
      </DayNavigation>

      <Card width="500px">
        <Box display="flex" alignItems="center" flexDirection="column" width="100%" py="12px">
          <Box width="100%" display="flex" alignItems="center" flexDirection="column" p={4}>
            {pwa?.logo && <img src={pwa?.logo} alt="logo" width="250px" style={{ boxSizing: 'border-box' }} />}
            <Title mt={3}>{pwa?.phone}</Title>
          </Box>
          <Divider />

          <Title mb="10px">Z de caisse</Title>
          <Title>{getDisplayFrom(from)}</Title>
          <Title mb="10px">{getDisplayTo(to)}</Title>
          <Divider />

          <Title mb="10px">
            Effectué le : <Now />
          </Title>
          <Divider />

          <Title>CA</Title>
          <Table
            showPagination={false}
            data={getCA(orders)}
            renderItem={([title, value]) => (
              <TableRow key={title}>
                <TableCell>{title}</TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            )}
          />
          <Divider />

          <Title>TVA</Title>
          <Table
            showPagination={false}
            data={getTVA(orders)}
            renderItem={([percent, tva, ht, ttc]) => (
              <TableRow key={percent}>
                <TableCell>{percent}</TableCell>
                <TableCell>{tva}</TableCell>
                <TableCell>{ht}</TableCell>
                <TableCell>{ttc}</TableCell>
              </TableRow>
            )}
          />
          <Divider />

          <Title>Statistiques</Title>
          <Table
            showPagination={false}
            data={getStatistics(orders)}
            renderItem={([title, value]) => (
              <TableRow key={title}>
                <TableCell>{title}</TableCell>
                <TableCell align="left">{value}</TableCell>
              </TableRow>
            )}
          />
          <Divider />

          <Title>Détails des tickets</Title>
          <Table
            showPagination={false}
            data={getDetails(orders)}
            renderItem={([id, date, payment, totalTTC]) => (
              <TableRow key={id}>
                <TableCell>{id}</TableCell>
                <TableCell>{date}</TableCell>
                <TableCell>{payment}</TableCell>
                <TableCell>{totalTTC}</TableCell>
              </TableRow>
            )}
          />
          <Divider />

          <Title>Produits par CA</Title>
          <Table
            showPagination={false}
            data={getTurnoverByProducts(orders)}
            renderItem={([name, qte, totalPrice, ca]) => (
              <TableRow key={name}>
                <TableCell>{name}</TableCell>
                <TableCell>{qte}</TableCell>
                <TableCell>{totalPrice}</TableCell>
                <TableCell>{ca}</TableCell>
              </TableRow>
            )}
          />
        </Box>
      </Card>
    </Box>
  );
}

function getDateInterval(count: number = 0) {
  const from = moment()
    .add(count, 'days')
    .hours(8)
    .minutes(0)
    .seconds(0)
    .valueOf();
  const to = moment()
    .add(count + 1, 'days')
    .hours(4)
    .minutes(0)
    .seconds(0)
    .valueOf();
  return [from, to] as const;
}

export function useMangeInterval() {
  const [fromInit, toInit] = getDateInterval();
  const [from, setFrom] = useState<number>(fromInit);
  const [to, setTo] = useState<number>(toInit);

  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    const [start, end] = getDateInterval(count);
    setFrom(start);
    setTo(end);
  }, [count]);

  return {
    from,
    to,
    setFrom,
    setTo,
    setCount,
  };
}

export function useClosMenu() {
  const dispatch = useDispatch();
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);

  useEffect(() => {
    if (open) dispatch({ type: 'RA/TOGGLE_SIDEBAR' });
  }, []);
}
