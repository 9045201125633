import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { CarouselOrList } from '../CarouselOrSimpleList';
import { useGetLastProductsOrder } from 'src/data/accessor/useGetLastProductsOrder';

export default function LastOrders({ data }) {
  const {
    value: { title, description },
  } = data;
  const { productsLast } = useGetLastProductsOrder();

  if (!productsLast?.length) {
    return (
      <div style={{ margin: '10px' }}>
        <Skeleton variant="rect" width="100%" height={250} />
        <Skeleton width="50%" />
        <Skeleton width="60%" />
      </div>
    );
  }

  const titleDefault = 'Les dernières commandes 🔥';

  return (
    <CarouselOrList
      data={{
        products: productsLast,
        title: title || titleDefault,
        description,
      }}
    />
  );
}
