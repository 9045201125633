import React, { useContext } from 'react';
import { Carousel } from 'kards-components';
import { ThemeContext } from 'src/cards/edit/components/context/theme';
import { SimpleList } from 'kards-components';

type CarouselOrListProps = {
  data: {
    products: any[];
    title: string;
    description?: string;
  };
} & any;

export function CarouselOrList({ data, ...props }: CarouselOrListProps) {
  const { theme }: any = useContext(ThemeContext);

  if ((data.products || []).every(p => p.picture)) {
    return <Carousel theme={theme} width="20vw" data={data} {...props} />;
  }

  return (
    <SimpleList theme={theme} data={data} {...props}>
      <span />
    </SimpleList>
  );
}
