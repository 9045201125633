import moment from 'moment';
import Dinero from 'dinero.js';
import { flatMap, groupBy, map, sum } from 'lodash';
import { getTotalPrice, getTotalPriceHT, sumAmount, getAverageBasket, getAverageProduct, getStatProductsByOrder } from '../dashboard';
import { Order } from 'src/data/types';

function toEuro(dinero: Dinero.Dinero) {
  return dinero.setLocale('de-DE').toFormat();
}

function numberToEuro(amount: number) {
  if (!amount) return '';
  return toEuro(Dinero({ amount, currency: 'EUR' }));
}

function getCommissionKards(orders: Order[]) {
  const totalPrice = getTotalPrice(orders);
  return toEuro(Dinero({ amount: totalPrice, currency: 'EUR' }).subtract(Dinero({ amount: totalPrice, currency: 'EUR' }).percentage(4)));
}

export function getCA(orders: Order[]) {
  return [
    ['CA TTC : ', numberToEuro(getTotalPrice(orders))],
    ['CA HT : ', numberToEuro(getTotalPriceHT(orders))],
    ['sans commission Kards (4%) : ', getCommissionKards(orders)],
  ];
}

export function getTVA(orders: Order[]) {
  const baskets = getBaskets(orders);
  const basketsByTVA = groupBy(baskets, 'tva');

  const data = map(basketsByTVA, (values, key) => {
    const TVAPercentage = Number(key) * 100;
    const TTC = Dinero({
      amount: sumAmount(
        map(values, v =>
          Dinero({ amount: v.price, currency: 'EUR' })
            .multiply(v.quantity)
            .getAmount(),
        ),
      ),
      currency: 'EUR',
    });
    const TVA = TTC.percentage(TVAPercentage);
    const HT = TTC.subtract(TVA);

    return [TVAPercentage, toEuro(TVA), toEuro(HT), toEuro(TTC)];
  });

  const header = ['%', 'TVA', 'HT', 'TTC'];

  return [header, ...data];
}

export function getStatistics(orders: Order[]) {
  const nbOrders = orders?.length;
  const averageBasket = numberToEuro(getAverageBasket(orders));
  const baskets = getBaskets(orders);
  const nbProducts = sum(baskets.map(b => b.quantity));
  const averageProduct = getAverageProduct(orders);

  return [
    ['Nombre de commandes : ', nbOrders],
    ['Commande moyenne : ', averageBasket],
    ["Nombre d'articles : ", nbProducts],
    ["Nombre moyen de d'article par commande : ", averageProduct],
  ];
}

export function getDetails(orders: Order[]) {
  const payment = {
    card: 'CB',
    googlePay: 'google pay',
    applePay: 'apple pay',
  };

  const mode = {
    instant: 'sur place',
    preorder: 'à emporter',
  };

  const details = map(orders, order => [
    order.key,
    moment(order.created_at).format('DD/MM/YYYY HH:mm'),
    `${payment[order?.parameters?.payment]} - ${mode[order?.parameters?.mode]}`,
    numberToEuro(order.totalPrice),
  ]);

  const header = ['N°', 'Heure', 'paiement - Couv.', 'Total TTC'];

  return [header, ...details];
}

export function getTurnoverByProducts(orders: Order[]) {
  const stats = getStatProductsByOrder(orders).map(p => [p.name, p.totalQuantity,numberToEuro(p.totalPrice), p.ca]);
  return [['Nom', 'Quantité', 'Prix total €', '% du CA'], ...stats];
}

export function getDisplayFrom(from: number) {
  return `Du ${moment(from).format('dddd DD MMMM YYYY à HH:mm')}`;
}
export function getDisplayTo(to: number) {
  return `au ${moment(to).format('dddd DD MMMM YYYY à HH:mm')}`;
}

type getCSVProps = {
  orders: Order[];
  from: number;
  to: number;
};

export function getCSV({ orders, from, to }: getCSVProps) {
  return [
    ['Z de caisse'],
    [getDisplayFrom(from), getDisplayTo(to)],
    [''],
    ['CA'],
    ...getCA(orders),
    [''],
    ['TVA'],
    ...getTVA(orders),
    [''],
    ['Statistiques'],
    ...getStatistics(orders),
    [''],
    ['Détails des tickets'],
    ...getDetails(orders),
    [''],
    ['Détails produits'],
    ...getTurnoverByProducts(orders),
  ];
}

function getBaskets(orders: Order[]) {
  return flatMap(orders, order => Object.values(order.items));
}
