import React from 'react';
import { Box } from '@material-ui/core';
import { filter, map, sortBy, groupBy } from 'lodash';
import moment from 'moment';
import { GraphBar } from '../components/GraphBar';
import { Card } from '../components/Card';
import { getAnalyticsByEvent } from 'src/data/accessor/getAnalyticsByEvent';
import { useQuery } from 'src/helpers/useQuery';
import { getNbOrdersByPayment, getNbProducts, formatToEuro, getAverageBasket, getAverageProduct, getNbClient } from '../Infos/Infos';
import { GraphPie } from '../components/GraphPie';
import { Order, Period, PaymentType } from 'src/data/types';

type ByOrdersProps = {
  orders: Order[];
  period: Period;
};

export function ByOrders({ orders = [], period }: ByOrdersProps) {
  const graphData = getNbOrdersByTime(orders, period);
  const graphNbOrdersByPayment = getPercentageNbOrdersByPayment(orders);
  const { data: openApplications } = useQuery(() => getAnalyticsByEvent('openApplication'));

  return (
    <>
      <Box my={4} width="100%" height="150px" display="flex" justifyContent="space-between">
        <Card mr={3} height="100%" title="Panier moyen" value={formatToEuro(getAverageBasket(orders))} />
        <Card mr={3} height="100%" title="Nb scan QR code" value={openApplications?.length} />
        <Card mr={3} height="100%" title="Nb moyen d'article par commande" value={getAverageProduct(orders)} />
        <Card mr={3} height="100%" title="Nb d' article total" value={getNbProducts(orders)} />
        <Card height="100%" title="Nb de client" value={getNbClient(orders)} />
      </Box>
      <Box display="flex" height="550px">
        <Box width="100%" height="550px">
          <GraphBar data={graphData} keys={['cb', 'google pay', 'apple pay']} indexBy="time" legendX="temps" legendY="Nb de commande" />
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="space-between">
          <Card mb={2} width="160px" title="Nb de commande" value={orders.length} />
          <Card mb={2} title="CB" value={getNbOrdersByPayment(orders, 'card')} />
          <Card mb={2} title="Apple Pay" value={getNbOrdersByPayment(orders, 'applePay')} />
          <Card title="Google Pay" value={getNbOrdersByPayment(orders, 'googlePay')} />
        </Box>
      </Box>

      <Box display="flex" my={2} height="300px" justifyContent="space-between">
        <Box width="100%" height="300px">
          <GraphPie data={graphNbOrdersByPayment} />
        </Box>
      </Box>
    </>
  );
}

function getNbOrdersByTime(orders: Order[], period: Period) {
  const filterById = filter(orders, o => o?.id);
  const sortOrders = sortBy(filterById, 'created_at');
  const formatDate = { days: 'HH', weeks: 'DD - dd', months: 'DD/MM', years: 'MM/YYYY' }[period];
  const prefix = period === 'days' ? 'H' : '';
  const mapXY = map(sortOrders, (order: Order) => ({
    x: moment(order.created_at).format(formatDate) + prefix,
    y: order?.id,
    paymentType: order?.parameters?.payment,
  }));
  const groupByX = groupBy(mapXY, 'x');
  const data = map(groupByX, (values, key: string) => {
    const byPaymentType = (paymentType: PaymentType) => values.filter(v => v.paymentType === paymentType).length;
    return {
      time: key,
      cb: byPaymentType('card'),
      'google pay': byPaymentType('googlePay'),
      'apple pay': byPaymentType('applePay'),
    };
  });
  return data;
}

function getPercentageNbOrdersByPayment(orders: Order[]) {
  return [
    {
      id: 'CB',
      name: 'CB',
      value: getNbOrdersByPayment(orders, 'card'),
    },
    {
      id: 'applePay',
      name: 'apple pay',
      value: getNbOrdersByPayment(orders, 'applePay'),
    },
    {
      id: 'googlePay',
      name: 'google pay',
      value: getNbOrdersByPayment(orders, 'googlePay'),
    },
  ];
}
