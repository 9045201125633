type downloadCSVProps = {
  content: (string | number)[][];
  fileName: string;
};

export function downloadCSV({ content, fileName = 'export.csv' }: downloadCSVProps) {
  const mimeType = 'text/csv;encoding:utf-8';
  let csvContent = '';
  content.forEach(function(infoArray, index) {
    csvContent += index < content.length ? infoArray.join(';') + '\n' : infoArray.join(';');
  });

  const a = document.createElement('a');
  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(new Blob([csvContent], { type: mimeType }), fileName);
  } else {
    //html5 A[download]
    a.href = URL.createObjectURL(new Blob([csvContent], { type: mimeType }));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
