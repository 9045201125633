import React from 'react';

import CreateButton from 'src/data/injector/CreateButton';

export function Toolbar({ ...props }) {
  return (
    <>
      <div />
      <CreateButton {...props as any} />
      <div />
    </>
  );
}
