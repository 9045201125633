import React from 'react';
import {
  Filter,
  List as ListAdmin,
  Datagrid,
  TextField,
  DateField,
} from 'react-admin';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import SearchInput from 'src/layout/Search/Bar';
import LoadingList from 'src/layout/List/Loading';
import StatusField from '../components/statusField';
import TotalField from '../components/totalField';

const useStyles: any = makeStyles(() => ({
  root: {
    alignItems: 'center',
    marginTop: 0,
    minHeight: '60px',
  },
}));

const DataGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: 75vh;
`;

const AdminListLoaded = (props) => (
  <DataGridWrapper data-cy="list-orders">
    <Datagrid {...props} hasBulkActions={false} rowClick="show">
      <TextField source="key" label="N° commande" />
      <DateField source="created_at" label="Date" />
      <TotalField label="Prix" record={props.record} />
      <StatusField label="Statut" record={props.record} />
    </Datagrid>
  </DataGridWrapper>
);

const OrderFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter className={classes.root} {...props}>
      <SearchInput source="key" alwaysOn />
    </Filter>
  );
};

const OrderTitle = () => <span>Commandes</span>;

const AdminList = ({ loading, ...props }) => (
  // <Listss />
  <ListAdmin
    {...props}
    title={<OrderTitle />}
    sort={{ field: 'created_at', order: 'DESC' }}
    filters={<OrderFilter />}
    actions={null}
    perPage={15}
  >
    {!loading ? <AdminListLoaded /> : <LoadingList {...props} />}
  </ListAdmin>
);

export default AdminList;
