import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';

type CardProps = {
  title: string;
  value: string | number;
} & React.ComponentProps<typeof Box>;

export function Card({ title, value, ...props }: CardProps) {
  return (
    <CardStyled {...props}>
      <Box display="flex" justifyContent="center" alignItems="center" textAlign="center" flexDirection="column" height="100%">
        <Typography>{title}</Typography>
        <Box fontSize="1.8em" fontWeight="bold">{value}</Box>
      </Box>
    </CardStyled>
  );
}

export const CardStyled = styled(Box)`
  display: flex;
  flex: 0.65;
  flex-direction: column;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 100%;
  justify-content: space-between;
  background-color: white;
  padding: 8px;
  margin-right: 5px;
  margin-left: 5px;
  box-sizing: border-box;
`;
