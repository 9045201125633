import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Card = styled(Box)`
  display: flex;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: white;
  box-sizing: border-box;
`;
