import React from 'react';
import { Edit } from 'react-admin';
import FormEditParameters from '../components/form';

const ParametersTitle = () => <span>Mon établissement</span>;

const ParametersList = props => (
  <>
    {console.log('ParametersList')}
    <div style={{ marginTop: '24px' }} />
    <Edit {...props} title={<ParametersTitle />}>
      <FormEditParameters {...props} />
    </Edit>
  </>
);

export default ParametersList;
