import React from 'react';

import Edit from './Edit';

const renderEdit = ({ data }) => <Edit data={data} />;

const lastOrders = {
  icone: '/img/components/carousel.svg',
  explain: '/img/components/explain/carousel.svg',
  description: "Ce composant permet d'afficher les 5 dernières commandes",
  nameId: 'Les 5 dernières commandes',
  type: 'lastOrders',
  init: { products: [] },
  renderEdit,
};

export default lastOrders;
