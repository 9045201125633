import { useState, useEffect } from 'react';

export function useQuery<T>(apiClient: () => Promise<T>, trigger = []) {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    setLoading(true);
    apiClient()
      .then(setData)
      .catch(err => setError(err))
      .then(() => setLoading(false));
  }, trigger);

  return { data, loading, error };
}
