import React from 'react';
import { Box, Typography } from '@material-ui/core';

type TitleProps = {
  children: React.ReactNode;
};

export function Title({ children }: TitleProps) {
  return (
    <Box my={2}>
      <Typography variant="h5">{children}</Typography>
    </Box>
  );
}
