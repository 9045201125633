import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import DatePicker from 'react-datepicker';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { getOrderByDate } from '../accessor/getOrderByDate';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    marginRight: '.5em',
  },
}))(InputBase);

const StyledBootstrapInput = styled(BootstrapInput)`
  width: ${(props) => props.width};
`;

export const GetOrderByDate = ({
  setResultSearchByDateAndFilter, needReload, setNeedReload,
}) => {
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const defaultStart = new Date().getTime();
  const [selectStatus, setSelectedStatus]: any = useState('S3');
  const [selectedStartDate, setSelectedStartDate] = useState(defaultStart);
  const [selectedEndDate, setSelectedEndDate] = useState(addDays(defaultStart, 1));

  const onSearch = async () => {
    getOrderByDate({
      startDate: new Date(selectedStartDate).getTime(),
      endDate: new Date(selectedEndDate).getTime(),
      setSearchDate: setResultSearchByDateAndFilter,
      status: selectStatus,
    });
  };

  useEffect(() => {
    if (needReload) {
      onSearch();
      setNeedReload(false);
    }
  }, [needReload]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" flexWrap="wrap">
      <Box
        padding=".5em"
        color="white"
        fontWeight="bold"
      >
        DU :
      </Box>
      <DatePicker
        id="scheduledAt"
        name="scheduledAt"
        todayButton="Aujourd'hui"
        label="logo.create.timeTitle"
        locale="fr-FR"
        timeFormat="HH:mm"
        timeIntervals={60}
        calendarClassName="datePickerTime"
        dateFormat="dd/MM/yyyy HH:mm"
        onChange={(event) => setSelectedStartDate(event)}
        timeCaption="heure"
        selected={selectedStartDate}
        placeholder="backgroundImage.form.scheduledAt.placeholder"
        customInput={<StyledBootstrapInput width="11em" />}
      />
      <Box
        padding=".5em"
        color="white"
        fontWeight="bold"
      >
        AU :
      </Box>
      <DatePicker
        id="scheduledAt"
        name="scheduledAt"
        todayButton="Aujourd'hui"
        label="logo.create.timeTitle"
        locale="fr-FR"
        timeFormat="HH:mm"
        timeIntervals={60}
        calendarClassName="datePickerTime"
        dateFormat="dd/MM/yyyy HH:mm"
        onChange={(event) => setSelectedEndDate(event)}
        timeCaption="heure"
        selected={selectedEndDate}
        placeholder="backgroundImage.form.scheduledAt.placeholder"
        customInput={<StyledBootstrapInput width="11em" />}
      />
      <Box
        padding=".5em"
        color="white"
        fontWeight="bold"
      >
        STATUT :
      </Box>
      <Select
        value={selectStatus}
        onChange={(event) => setSelectedStatus(event.target.value)}
        input={<StyledBootstrapInput width="7em" />}
        IconComponent={() => <div />}
      >
        <MenuItem value="S3">À faire</MenuItem>
        <MenuItem value="S4">En cours</MenuItem>
        <MenuItem value="S5">Terminé</MenuItem>
      </Select>
      <Box paddingLeft="1em" pt=".5em" pb=".5em">
        <Button variant="contained" color="secondary" onClick={() => onSearch()}>
          Rechercher
        </Button>
      </Box>
    </Box>
  );
};
