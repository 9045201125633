import React from 'react';
import {
  List, Datagrid, TextField,
  Filter,
  CreateButton,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import LoadingList from 'src/layout/List/Loading';
import SearchInput from 'src/layout/Search/Bar';
import { makeStyles } from '@material-ui/core/styles';
import EmptyComponent from 'src/layout/Empty';
import CategoriesIcon from '@material-ui/icons/Category';

const useStyles: any = makeStyles(() => ({
  root: {
    alignItems: 'center',
    marginTop: 0,
    minHeight: '60px',
  },
}));

const CategoriesTitle = () => <span>Categories</span>;

export const Action = ({ basePath }) => <Toolbar><CreateButton data-cy="create-user" basePath={basePath} /></Toolbar>;

export const Empty = ({ basePath, resource, location }) => (
  <EmptyComponent
    basePath={basePath}
    resource={resource}
    location={location}
    titleText="Vous n'avez pas encore de catégorie."
    explainText="Créer votre première catégorie."
    Image={CategoriesIcon}
  />
);

const CategorieFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter className={classes.root} {...props}>
      <SearchInput source="name" alwaysOn />
    </Filter>
  );
};
const CategoriesListLoaded = (props) => (
  <Datagrid style={{overflow: 'auto'}} {...props} hasBulkActions={false}>
    <TextField source="name" label="Nom" data-cy="item-list-user-name" />
  </Datagrid>
);

const CategoriesList = (props, { loading }) => 
  {
    console.log(props)
    
    return (
      <List
        data-cy="list-categories"
        perPage={15}
        filter={{}}
        sort={{ field: 'name', order: 'ASC' }}
        title={<CategoriesTitle />}
        empty={<Empty {...props} />}
        actions={<Action {...props} />}
        filters={<CategorieFilter />}
        {...props}
      >
        {!loading ? (
          <CategoriesListLoaded loading={props.loading} />
        ) : (
          <LoadingList {...props} />
        )}
      </List>
    )
  }

export default CategoriesList;
