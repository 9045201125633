import React, { useState, useEffect } from 'react';
import { FormWithRedirect, sanitizeEmptyValues } from 'react-admin';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box } from '@material-ui/core';
import firebase from 'firebase/app';
import arrayMutators from 'final-form-arrays';
import MainScreen from 'src/layout/Screen/Main';
import {
  TextInputField,
  TextAreaInputField,
  NumberInputField,
  ImagesField,
  SelectInputField,
  ArrayProduct,
} from 'src/form/components/field/index';
import { useApplication } from 'src/data/hooks';
import { getCashRegister, CashRegisterType } from 'src/data/accessor/cashRegister';
import { useFirebaseSnapshot } from 'src/helpers/useFireabseOnSnapshop';
import { SearchProductCashRegister } from 'src/cash-register';
import { useGetCategories } from 'src/categories/components/select';
// import { SearchProductCashRegister } from 'src/cash-register';
import { PriceWithoutTax, PriceInEuro } from './PriceWithoutTax';
import { TabPanel } from './TabPanel';
import { FieldTVA } from './FieldTVA';

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

const options = [
  { value: '0.10', label: 'TVA 10%' },
  { value: '0.20', label: 'TVA 20%' },
  { value: '0.055', label: 'TVA 5.5%' },
];

export default function Form({ Toolbar, ...props }) {
  const [value, setValue] = useState('one');
  const { data: categoryData } = useGetCategories();
  const [productsResource, setProductResource] = useState([
    {
      value: 'all',
      label: 'Tous',
    },
  ]);
  useEffect(() => {
    if (categoryData) setProductResource(categoryData.map(r => ({ value: r.id, label: r.name })));
  }, [categoryData]);

  const { data }: any = useApplication();
  const { data: cashRegister, loading } = useFirebaseSnapshot<CashRegisterType>(getCashRegister);

  const submit = values => {
    props.save(sanitizeEmptyValues(props.record, values));
  };

  const fillValueFromCashRegister = form => async value => {
    let fn = cashRegister?.name === 'zelty' ? getTVAFromZelty : getTaxTiller;
    let tva = await fn(value.tva);

    form.mutators.setValue('reference', value.name);
    // form.mutators.setValue('tag', { label: value.categoryProduct.name, value: value.categoryProduct.id });
    form.mutators.setValue('description', value.description);
    form.mutators.setValue('price', value.price);
    form.mutators.setValue('tva', tva);
    form.mutators.setValue('productIdCashRegister', value.id);
  };

  const getTVAFromZelty = async (tva: number) => {
    const index = options.findIndex(option => Number(option.value) * 10000 === tva);
    return options[index];
  };
  const getTaxTiller = async idTax => {
    const getTillerTax = firebase
      .app()
      .functions()
      .httpsCallable('getTillerTax');
    const taxData = await getTillerTax(idTax);
    const tva = taxData.data.value;
    const optionTVA = String(tva / 100);
    return optionTVA;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <FormWithRedirect
      subscription={defaultSubscription}
      keepDirtyOnReinitialize
      onSubmit={submit}
      key={props.version}
      initialValues={props.initialValues || props.record}
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({ form }) => (
        <>
          <MainScreen
            data_cy_title="title-create-product"
            data_cy_subtitle="subtitle-create-product"
            title="Produit"
            subtitle="Renseignez les informations de votre produit"
            flex={0.95}
          >
            <Box display="flex" flexDirection="column" padding="0 1em">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab value="one" label="Informations générales" />
                <Tab value="two" label="Détails du produit" />
              </Tabs>
              <TabPanel value={value} index="one">
                <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row">
                  <Box flex="0.4">
                    {props.hasCreate && <SearchProductCashRegister onChange={fillValueFromCashRegister(form)} />}
                    <TextInputField required name="reference" label="Titre" data_cy_input="input-name-product" />
                    <SelectInputField data_cy_select="select-tag-product" label="Catégorie" name="tag" options={productsResource} />
                    {data && data.contractors && (
                      <SelectInputField
                        data_cy_select="select-tag-product"
                        label="Fournisseur"
                        name="contractors"
                        options={data.contractors}
                        isMulti
                      />
                    )}
                    <TextAreaInputField required name="description" label="Description" data_cy_input="input-description-product" />
                    <NumberInputField required name="price" label="Prix en centimes ( TTC )" data_cy_number="input-price-product" />
                    <PriceInEuro />
                    <FieldTVA name="tva" data_cy_select="select-tva-product" required />
                    <PriceWithoutTax />
                  </Box>
                  <Box flex="0.4">
                    <ImagesField
                      type="rectangle"
                      name="picture"
                      title="Image du produit"
                      subtitle="Sélectionnez une image pour votre produit"
                      onAccept={picture => form.mutators.setValue('picture', picture)}
                    />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index="two">
                <Box flex="1">
                  <ArrayProduct name="settingFields" label="Ajouter un champ" />
                </Box>
              </TabPanel>
            </Box>
          </MainScreen>
          <Box bgcolor="primary.main" flex={0.05} display="flex" justifyContent="space-between" alignItems="center">
            <Toolbar resource={props.resource} id={props.id} basePath={props.basePath} />
          </Box>
        </>
      )}
    />
  );
}
