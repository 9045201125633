import React from 'react';
import { Create } from 'react-admin';
import { Back } from 'src/layout/Buttons';
import Form from '../components/form';

const CategoriesTitle = () => <span>Créer une categorie </span>;

const CategoriesCreate = (props: any) => (
  <>
    <Back resource="/categories" />
    <Create title={<CategoriesTitle />} {...props}>
      <Form />
    </Create>
  </>
);

export default CategoriesCreate;
