import React, { useState, useContext } from "react";
import firebase from "firebase";

import MaterialModal from "@material-ui/core/Modal";
import { Box } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import { printReceipt } from "src/printers";
// import { find } from 'lodash'
import PrintIcon from "@material-ui/icons/Print";
import DoneIcon from "@material-ui/icons/Done";
import { PreparatorContext } from "../../context";
import { print } from "ionicons/icons";

export function PrintersListPrint({ order }) {
  const [open, setOpen] = useState(false);
  const { printersStatus, printerLoading, printers }: any = useContext(PreparatorContext);
  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAccept = async () => {
    const defaultFirestore = firebase.app().firestore();

    let promises = checked.map((c) => {
      return printReceipt(order, c.ip, c.name);
    });
    const prints:any[] = await Promise.all(promises)
    
    const newPrintState = order.print ? order.print.map((p)=> ({
      ...p, ...(prints.find(print => print.name === p.name) || {})
    })) : prints

    console.log(newPrintState)
    await defaultFirestore
      .collection("orders")
      .doc(order.id)
      .update({ print: newPrintState });
    
    setOpen(false);

  };

  if(printers?.length === 0) return <div></div>


  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <IconButton onClick={() => setOpen(true)}>
          <PrintIcon style={{ fill: "black" }} />
        </IconButton>
      </Box>
      <MaterialModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <>
          <ModalWrapper>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              width="100%"
              pt="2em"
              pb="2em"
            >
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <Text color="black" size="1.5em" weight="300">
                  <p>Vos imprimantes</p>
                  <strong> connecté(es)</strong>
                </Text>
              </Box>
              <PrintListComponent
                handleToggle={handleToggle}
                printersStatus={printersStatus}
                loading={printerLoading}
                checked={checked}
              />
              {printersStatus.map((status) => {
                if (status.errorPrint) return <div>{status.errorPrint}</div>;
              })}
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-around"
                width="100%"
                m="1em"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleAccept()}
                >
                  Imprimer
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpen(false)}
                >
                  Fermer
                </Button>
              </Box>
            </Box>
          </ModalWrapper>
        </>
      </MaterialModal>
    </>
  );
}

export function PrintersListParams({ order = {}, readonly = false }) {
  const [open, setOpen] = useState(false);
  const { printersStatus, printerLoading, printers }: any = useContext(PreparatorContext);
  if(printers?.length === 0) return <div></div>
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ColorButton key={`${open}`} onClick={() => setOpen(true)}>
          Imprimantes
        </ColorButton>
      </Box>
      <MaterialModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <>
          <ModalWrapper>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              width="100%"
              pt="2em"
              pb="2em"
            >
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <Text
                  color="black"
                  size="1.5em"
                  weight="300"
                  textAlign="center"
                >
                  <strong>Toutes</strong> vos imprimantes
                </Text>
              </Box>
              <PrintListComponentSwitch
                printersStatus={printersStatus}
                loading={printerLoading}
                readonly={readonly}
              />
              {printersStatus.map((status) => {
                if (status.errorPrint) return <div>{status.errorPrint}</div>;
              })}
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                width="100%"
                m="1em"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpen(false)}
                >
                  Fermer
                </Button>
              </Box>
            </Box>
          </ModalWrapper>
        </>
      </MaterialModal>
    </>
  );
}

function PrintListComponent({
  printersStatus,
  checked = [],
  handleToggle = null,
  loading,
}) {
  if (loading) return <p>Connexion en cours ...</p>;
  return (
    <List>
      {printersStatus.map((value) => {
        const labelId = `checkbox-list-label-${value}`;
        if (!value.error) {
          return (
            <ListItem
              disabled={value.error}
              key={value}
              role={undefined}
              dense
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disabled={value.error}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
              <ListItemText id={labelId} primary={value.ip} />
            </ListItem>
          );
        }
      })}
    </List>
  );
}

function PrintListComponentSwitch({
  printersStatus,
  loading,
  readonly = false,
}) {
  if (loading) return <p>Connexion en cours ...</p>;
  return (
    <List>
      {printersStatus.map((value) => {
        return <ListItemPrint value={value} />;
      })}
    </List>
  );
}

function ListItemPrint({ value }) {
  const labelId = `checkbox-list-label-${value}`;
  const [anchorEl1, setAnchorEl1] = useState(null);
  const handleClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl1(null);
  };

  const open = Boolean(anchorEl1);
  return (
    <ListItem disabled={value.error} key={value} role={undefined} dense>
      <Popover
        open={open}
        anchorEl={anchorEl1}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          padding="1em"
        >
          <Box fontSize="1em" width="100%" pb=".5em" textAlign="center">
            {value.connexion}
          </Box>
        </Box>
      </Popover>
      <ListItemText id={labelId} primary={value.name} />
      <ListItemText id={labelId} primary={value.ip} />
      {value.error ? (
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => handleClick(e)}
          style={{
            borderRadius: "50px",
            padding: "6px",
            minWidth: "2.5em",
          }}
        >
          ?
        </Button>
      ) : (
        <DoneIcon style={{ fill: "black" }} />
      )}
    </ListItem>
  );
}

const ColorButton = withStyles(() => ({
  root: {
    textTransform: "inherit",
    color: "white",
    borderRadius: "30px",
    backgroundColor: "#6da993",
    boxShadow: "0 4px 6px rgba(0,0,0,.2)",
    "&:hover": {
      backgroundColor: "#6da993",
    },
  },
}))(Button);

const ModalWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  height: 40vh;
  width: ${(props) => (props.mobile ? "80%" : "40%")};
  border-radius: 5px;
`;

const Text = styled.div`
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  font-family: ${(props) => props.family};
  padding: ${(props) => props.padding};
  padding-bottom: ${(props) => props.paddingBottom};
  white-space: ${(props) => props.whiteSpace};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.lettreSpacing};
  display: flex;
  justify-content: center;
  align-items: center;
`;

  