import React, { useState } from 'react';
import { Button, Box } from '@material-ui/core';
import { getCashRegister, setCashRegister, CashRegisterType } from 'src/data/accessor/cashRegister';
import { useFirebaseSnapshot } from 'src/helpers/useFireabseOnSnapshop';
import { ButtonCashRegister, ChooseCashRegister } from 'src/cash-register/ChooseCashRegister';
import { AskForCashRegister } from 'src/cash-register/AskForCashRegister';

import { ConfirmButton } from 'src/layout/Buttons';
import { TillerLogo, ZeltyLogo } from './Logo';

export function ChangeCashRegister() {
  const { data: cashRegister, loading } = useFirebaseSnapshot<CashRegisterType>(getCashRegister);

  if (loading) return <></>;

  switch (cashRegister?.step) {
    case 'done':
      return <CurrentCashRegister />;
    default:
      return <AskForCashRegister />;
  }
}

export function CurrentCashRegister() {
  const { data: cashRegister, loading } = useFirebaseSnapshot<CashRegisterType>(getCashRegister);

  if (loading) return <></>;
  switch (cashRegister?.name) {
    case 'zelty':
      return (
        <CurrentButton name="zelty">
          <ZeltyLogo />
        </CurrentButton>
      );
    case 'tiller':
      return (
        <CurrentButton name="tiller">
          <TillerLogo />
        </CurrentButton>
      );
    default:
      return (
        <Box my={2} display="flex" justifyContent="center">
          <NoCashRegister />
        </Box>
      );
  }
}

const NoCashRegister = () => (
  <Button
    size="small"
    variant="contained"
    color="primary"
    onClick={() =>
      setCashRegister({
        step: 'init',
      })
    }
  >
    Choisir une caisse
  </Button>
);

const CurrentButton = ({ children, name }) => {
  const handleClick = (cashRegister: Partial<CashRegisterType>) => {
    setCashRegister(cashRegister);
  };

  const color = {
    zelty: '#193e34',
    tiller: 'black',
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <ButtonCashRegister bgcolor={color[name]} onClick={() => handleClick({ name, step: 'form-infos' })}>
        {React.cloneElement(children)}
      </ButtonCashRegister>
      <ConfirmButton
        onAccept={() => handleClick({ name, step: 'init' })}
        explain="test"
        OpenButton={props => (
          <Box>
            <Button size="small" variant="contained" color="primary" onClick={props.handleOpen}>
              Changer de caisse
            </Button>
          </Box>
        )}
      />
    </Box>
  );
};
