/* eslint-disable max-len */
import React, { useContext } from 'react';

import { Error } from 'react-admin';
import { Skeleton } from '@material-ui/lab';
import { SimpleList } from 'kards-components';

import { ThemeContext } from 'src/cards/edit/components/context/theme';

import { usePopulateProducts } from 'src/data/hooks';

export default ({
  data: {
    value: { products, title },
  },
}) => {
  const {
    theme,
  }:any = useContext(ThemeContext);
  const { data, error } = usePopulateProducts({ products });
  if (error) return <Error />;
  if (data.length === 0) {
    return (
      <div style={{ margin: '10px' }}>
        <Skeleton variant="text" width={100} />
        <Skeleton variant="rect" width={210} height={118} />
      </div>
    );
  }

  return <SimpleList theme={theme} data={{ title, products: data }}><span /></SimpleList>;
};
