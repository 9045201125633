import { map, find } from 'lodash';
import { useDataProvider } from 'react-admin';
import { useQuery } from 'src/helpers/useQuery';
import { useGetProductsInCard } from './useGetProductsInCard';

function useMostPopular() {
  const dataProvider = useDataProvider();
  const getMostPopular = () => dataProvider.getOne('statProducts', { id: 'mostPopular' }).then(r => r.data);
  const { data, loading, error }: any = useQuery(() => getMostPopular());
  const mostPopular = data?.week;
  return { mostPopular, loading, error };
}

export function useGetMostPopularProducts() {
  const { mostPopular } = useMostPopular();
  const { products } = useGetProductsInCard();

  const productsMostPopular = map(mostPopular, popular => ({ ...popular, ...find(products, p => p.id === popular.id) })).slice(0, 3);

  return { productsMostPopular };
}
