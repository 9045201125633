import React, { useContext } from 'react';

import { ContributionContext } from 'src/cards/edit/components/context/contribution';
import { ThemeContext } from 'src/cards/edit/components/context/theme';
import { CategoriesGallery } from 'kards-components';

const GalleryList = ({ components }) => {
  const {
    cardData,
    currentCategory,
    action: {
      setCurrentCategory,
    },
  }: any = useContext(ContributionContext);

  const {
    theme,
  }: any = useContext(ThemeContext);

  return (
    <CategoriesGallery
      theme={theme}
      currentCategory={currentCategory}
      setCurrentCategory={setCurrentCategory}
      data={cardData}
      components={components}
    />
  );
};

export default GalleryList;
