import firebase from 'firebase/app';
import { Product } from '../types';
import { toArray } from 'src/helpers/toArray';

export async function getProducts() {
  const querySnapshot = await firebase
    .app()
    .firestore()
    .collection('products')
    .get();
  return toArray<Product>(querySnapshot);
}
