import React from 'react';
import styled from 'styled-components';
import { Box, InputBase } from '@material-ui/core';
import { Field } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';

const IconButtonStyled = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
  },
}))(IconButton);

const ErrorField = styled.span`
  color: red;
  font-size: 0.8em;
  position: absolute;
  bottom: -25px;
  white-space: nowrap;
  left: 0;
`;

const InputbaseWrapper = styled(InputBase)`
  border: 1px solid hsl(0,0%,80%);
  border-radius: 5px;
  padding: 1px 10px;
  font-weight: 'bold';
  font-size: '1em';
`;

const Label = styled.label`
  font-size: 0.8em;
`;

export const TypeContractor = ({ name, index, fields }) => (
  <Box display="flex" key={name + index} flexDirection="row" alignItems="center" justifyContent="space-between">
    <Label>Nom du fournisseur</Label>
    <Field
      name={name}
      render={({ input, meta }) => (
        <Box
          display="flex"
          flexDirection="column"
          width="60%"
        >
          <InputbaseWrapper
            defaultValue={input.value.label}
            input={input}
            meta={meta}
            onChange={(e) => {
              input.onChange({
                label: e.target.value,
                value: e.target.value,
                id: input.value.id,
              });
            }}
          />
          {meta.error && <ErrorField>{meta.error}</ErrorField>}
        </Box>
      )}
    />
    <IconButtonStyled onClick={() => fields.remove(index)}>
      <DeleteForeverIcon fontSize="large" />
    </IconButtonStyled>
  </Box>
);
