import { Period } from 'src/data/types';
import moment from 'moment';

export function getNow() {
  const today = new Date();
  return today.getTime();
}

export function getPeriod(period: Period, now = getNow()) {
  const oneDay = 24 * 60 * 60 * 1000;
  return {
    days: new Date(now - oneDay),
    weeks: new Date(now - oneDay * 7),
    months: new Date(now - oneDay * 30),
    years: new Date(now - oneDay * 365),
  }[period].getTime();
}

type getIntervalProps = {
  count: number;
  period: Period;
};

export function getFromMinus({ count, period }: getIntervalProps) {
  const from = moment().subtract(Math.abs(count - 2), period);
  return from.valueOf();
}

export function getToMinus({ count, period }: getIntervalProps) {
  const to = moment().subtract(Math.abs(count - 1), period);
  return to.valueOf();
}

export function getFromPlus({ count, period }: getIntervalProps) {
  const from = moment().add(count, period);
  return from.valueOf();
}

export function getToPlus({ count, period }: getIntervalProps) {
  const to = moment().add(count + 1, period);
  return to.valueOf();
}

export function getDay(to: number, period: Period) {
  const format = {
    days: 'DD dddd MMMM yyyy',
    weeks: 'w',
    months: 'MMMM',
    years: 'YYYY',
  }[period];
  const suffix = period === 'weeks' ? 'Semaine ' : '';
  return `${suffix}${moment(to).format(format)}`;
}

export function isDisabledPlusBtn(from: number) {
  return moment(from).isSameOrAfter(moment(), 'day');
}
