/* eslint-disable import/first */
require('intersection-observer');

import React, { useState } from 'react';
import firebase from 'firebase';
import { FirebaseAuthProvider, FirebaseDataProvider } from 'react-admin-firebase';

import { Admin, Resource } from 'react-admin';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { firebaseConfig } from './firebase';

firebase.initializeApp(firebaseConfig);
firebase
.app()
.functions()
.useFunctionsEmulator('http://localhost:5000');
import tagSelect from './redux/actions/reducer';
import frenchMessage from './i18n/fr';
import { Layout } from './layout';
import { Login } from './auth';
import users from './users';
// import application from './application';
import card from './cards';
import products from './products';
import offer from './offer';
import images from './images';
import categories from './categories';
import { Dashboard } from './dashboard';
import orders from './orders';
import application from './application';
import preparator from './preparator';
import productor from './productor';
import runner from './runner';
import { ADMIN, PREPARATOR, PRODUCTOR, RUNNER } from './helpers/role';
import './App.css';
import './themeIonic/variables.css';
import { Zcaisse } from './accounting';

const windowT: any = window;
export const configuration = windowT.kardsConfiguration;

const i18nProvider = polyglotI18nProvider(locale => {
  if (locale === 'fr') {
    return import('./i18n/fr').then(messages => messages.default);
  }
  return frenchMessage;
}, 'en');

export const productsResource = [
  'soft',
  'beer',
  'aperitif',
  'shooters',
  'cocktail',
  'wine',
  'hot',
  'spirit',
  'tapas',
  'starter',
  'dish',
  'cheese',
  'desert',
  'delivery',
  'glocery',
  'alcool',
  'red_wine',
  'white_wine',
  'pink_wine',
];


const App = () => {
  const dataProvider = FirebaseDataProvider(firebaseConfig, {});
  const authProvider = FirebaseAuthProvider(firebaseConfig, {});
  const [initializing, setInitialized] = useState(true);
  let extendsAuthProvider = (type, params) => {
    if(type === "GET_PERMISSION") return "cequejevxue"
  
    return authProvider(type, params)
  }
  
  const initRoute = role => {
    if (initializing) {
      if (role !== ADMIN) {
        window.location.hash = '#/orders';
        setInitialized(false);
      }
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Admin
        title=""
        dataProvider={dataProvider}
        authProvider={extendsAuthProvider}
        customReducers={{ tag: tagSelect }}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
      >
        {({ role }) => [
          initRoute(role),
          role === ADMIN && <Resource name="dashboard" list={Dashboard} />,
          role === ADMIN && <Resource name="accounting" list={Zcaisse} />,
          role === ADMIN && <Resource name="users" {...users} />,
          role === ADMIN && <Resource name="cards" {...card} />,
          role === ADMIN && <Resource name="products" {...products} />,
          role === ADMIN && <Resource name="offer" {...offer} />,
          role === ADMIN && <Resource name="images" {...images} />,
          role === ADMIN && <Resource name="categories" {...categories} />,
          role === ADMIN && <Resource key="order" name="orders" {...orders} />,
          role === ADMIN && <Resource key="application" name="application" {...application} />,
          role === PREPARATOR && <Resource key="preparator" name="orders" {...preparator} />,
          role === PRODUCTOR && <Resource key="productor" name="orders" {...productor} />,
          role === RUNNER && <Resource key="runner" name="orders" {...runner} />,
        ]}
      </Admin>
    </DndProvider>
  );
};

export default App;
