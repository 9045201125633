import { templateType } from 'kards-components';
import Top from './Top';
import TopWithText from './TopWithText';
import Gallery from './Gallery';
import GalleryInline from './GalleryInline';

export const getTemplate = (type) => {
  switch (type) {
    case templateType.Gallery:
      return Gallery;
    case templateType.Top:
      return Top;
    case templateType.TopWithText:
      return TopWithText;
    case templateType.GalleryInline:
      return GalleryInline;
    default:
  }
};
