import React, { useState } from 'react';
import { Box, Button, Modal } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import { TextInputField } from 'src/form/components/field/index';
import { setCashRegister, CashRegisterType } from 'src/data/accessor/cashRegister';
import get from 'lodash/get';

import { ConfirmButton } from 'src/layout/Buttons';
import { CurrentCashRegister } from './CurrentCashRegister';

type FormCashRegisterInfosProps = {
  cashRegister: CashRegisterType;
};

export function FormCashRegisterInfos({ cashRegister }: FormCashRegisterInfosProps) {
  switch (cashRegister.name) {
    case 'tiller':
      return <FormTiller cashRegister={cashRegister} />;
    case 'zelty':
      return <FormZelty cashRegister={cashRegister} />;
    default:
      return <div />;
  }
}

type FormTillerProps = {
  cashRegister: CashRegisterType;
};

function FormTiller({ cashRegister }: FormTillerProps) {
  const translate = useTranslate();

  const cancel = () => {
    setCashRegister({ step: 'done' });
  };

  const handleSubmit = (credentials: { providerToken: string; restaurantToken: string }) => {
    if (credentials.providerToken && credentials.restaurantToken) {
      // setCashRegister({ credentials, step: 'import-in-progress' });
    } else {
      cancel();
    }
  };

  const initialValues = {
    restaurantToken: get(cashRegister, 'credentials.restaurantToken'),
    providerToken: get(cashRegister, 'credentials.providerToken'),
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={cashRegister.credentials as any}>
      {props => (
        <Box display="flex" flexDirection="row" justifyContent="space-around">
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <CurrentCashRegister />
          </Box>
          <Box display="flex" flex=".8" flexDirection="column" padding={2}>
            <Box display="flex" alignItems="center" justifyContent="center" fontSize="1.2em" fontWeight="400" maxWidth="1000px">
              {translate('cashRegister.formTitle')}
            </Box>
            <form onSubmit={props.handleSubmit}>
              <TextInputField name="providerToken" label="Provider Token" />
              <TextInputField name="restaurantToken" label="Restaurant Token" />
              <ConfirmButton
                onAccept={() => console.log('accept')}
                text="Voulez-vous importer les produits de votre caisse ?"
                OpenButton={props => (
                  <Button variant="contained" color="secondary" type="submit" onClick={props.handleOpen} style={{ marginRight: '1em' }}>
                    Valider
                  </Button>
                )}
              />
              <Button onClick={cancel} variant="contained" style={{ marginRight: '1em' }}>
                Annuler
              </Button>
            </form>
          </Box>
        </Box>
      )}
    </Form>
  );
}

type FormZeltyPros = {
  cashRegister: CashRegisterType;
};

function FormZelty({ cashRegister }: FormZeltyPros) {
  const translate = useTranslate();
  const [data, setData] = useState<any>();

  const cancel = () => {
    setCashRegister({ step: 'init' });
  };

  const handleSubmit = (credentials: { authorizationBearer: string }) => {
    if (credentials.authorizationBearer) {
      setData(credentials);
      // setCashRegister({ credentials, step: 'import-in-progress' });
    } else {
      cancel();
    }
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={cashRegister.credentials as any}>
      {props => (
        <Box display="flex" flexDirection="row" justifyContent="space-around">
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <CurrentCashRegister />
          </Box>
          <Box display="flex" flex=".8" flexDirection="column" padding={2}>
            <Box display="flex" alignItems="center" justifyContent="center" fontSize="1.2em" fontWeight="400" maxWidth="1000px">
              {translate('cashRegister.formTitle')}
            </Box>
            <form onSubmit={props.handleSubmit}>
              <TextInputField name="authorizationBearer" label="Authorization Bearer" />
              <TextInputField name="restaurantId" label="Id du restaurant" />

              <ConfirmButton
                onClose={() => setCashRegister({ credentials: data, step: 'done' })}
                onAccept={() => setCashRegister({ credentials: data, step: 'import-in-progress' })}
                text="Voulez-vous importer les produits de votre caisse ?"
                OpenButton={props => (
                  <Button variant="contained" color="secondary" type="submit" onClick={props.handleOpen} style={{ marginRight: '1em' }}>
                    Valider
                  </Button>
                )}
              />
            </form>
          </Box>
        </Box>
      )}
    </Form>
  );
}
