import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';

export const GetListOffer = ({ children, setError }) => {
  const dataProvider = useDataProvider();
  const [offer, setOffer] = useState([]);

  useEffect(() => {
    const confSearch: any = {
      pagination: { page: 1, perPage: 200 },
      sort: { field: 'reference', order: 'ASC' },
      filter: {},
    };
    // eslint-disable-next-line
    // tag !== 'all' ? confSearch.filter = { tag } : confSearch.filter = {};

    dataProvider
      .getList('offer', confSearch)
      .then(({ data }) => {
        setOffer(data);
      })
      .catch(error => {
        setError(error);
      });
    // eslint-disable-next-line
  }, []);

  return React.cloneElement(children, { offer });
};

export default GetListOffer;
