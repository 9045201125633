import React from 'react';
import { map, sortBy, orderBy } from 'lodash';
import ItemOrder from './ItemOrder';

const ListOrders = (props) => {

  if (props.low === 'low') {
    props.data.map((d) => {
      d.sortKey = parseInt(`${d.parameters.deliveryTime}`, 10);
      return d;
    });
    return <>
      {map(orderBy(props.data, ['sortKey'], ['asc']), (item) => (
        <ItemOrder
          key={item.id}
          item={item}
          low={props.low}
          preparing={props.preparing}
          order
        />
      ))}
    </>
  }

  return <>
    {map(
      sortBy(props.data, (i) => i.created_at),
      (item) => <ItemOrder key={item.id} item={item} low={props.low} preparing={props.preparing} />,
    )}
  </>
};

export default ListOrders;
