/* eslint-disable camelcase */
import React from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

const StyledTitle = styled.h2`
  color: #222428;
  font-size: 1.3em;
  margin: 0;
  padding: 5px;
  flex:.1;
`;

const StyledSubTitle = styled.h2`
  color: #bbbbbb;
  font-size: 1em;
  margin: 0;
  padding-bottom: 10px;
`;

export default ({
  title = null,
  subtitle = null,
  data_cy_title = null,
  data_cy_subtitle = null,
  children,
  flex,
  padding = '20px',
  boxShadow = '0 4px 16px rgba(0,0,0,.12)',
}) => (
  <Box
    flex={flex}
    display="flex"
    padding={padding}
    flexDirection="column"
    borderRadius="borderRadius"
    boxShadow={boxShadow}
    height="75vh"
  >
    {title && (
    <Box
      flex=".07"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <StyledTitle data-cy={data_cy_title}>{title}</StyledTitle>
      {subtitle && <StyledSubTitle data-cy={data_cy_subtitle}>{subtitle}</StyledSubTitle>}
    </Box>
    )}
    <Box flex=".93" overflow="scroll">
      {children}
    </Box>
  </Box>
);
