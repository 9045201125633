/* eslint-disable max-len */
import React, { useState } from 'react';
import { FormWithRedirect, sanitizeEmptyValues } from 'react-admin';
import { Box, Button } from '@material-ui/core';

import styled from 'styled-components';

import MainScreen from 'src/layout/Screen/Main';
import {
  TextInputField,
  ImageProductField,
} from 'src/form/components/field/index';

import UploadInput from './upload';

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

const ImageWrapper = styled.img`
  height: 11vw;
  border-radius: 5px;
`;

const RectangleWrapper = styled.div`
  margin: 1em;
  margin-bottom: 2em;
  overflow: hidden;
  position: relative;
  height: 52%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const SquareStyled = styled.div`
  margin: 1em;
  margin-bottom: 2em;
  overflow: hidden;
  position: relative;
  height: 50%;
  width: 63%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const RectangleInlineStyled = styled.div`
  margin: 1em;
  margin-bottom: 2em;
  overflow: hidden;
  position: relative;
  height: 30%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const FullStyled = styled.div`
  margin: 1em;
  margin-bottom: 2em;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 60%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const ContainerImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 19em;
  padding: 1em;
  box-shadow: 0px 0px 14px rgba(0,0,0,0.1);
  border-radius: 10px;
`;

const Legend = styled.div`
`;

const Title = styled.div`
  font-size: 1.2em;
  font-weight: 500;
`;

export default ({ Toolbar, ...props }) => {
  const [current, setCurrent] = useState();
  const submit = (values) => {
    props.save(sanitizeEmptyValues(props.record, values));
  };

  return (
    <FormWithRedirect
      subscription={defaultSubscription}
      keepDirtyOnReinitialize
      onSubmit={submit}
      key={props.version}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      initialValues={props.initialValues || props.record}
      render={({
        form,
      }) => (
        <form
          style={{ height: '89vh', display: 'flex', flexDirection: 'column' }}
        >
          <MainScreen
            data_cy_title="title-create-image"
            data_cy_subtitle="subtitle-create-image"
            title="Image"
            subtitle="Recardrez vos images, choisissez les formats dont vous avez besoin"
            flex={0.95}
          >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <UploadInput setUploadData={(d) => {
                form.mutators.setValue('edit.original', d.image);
                setCurrent(d.image);
              }}
              />
              <Box
                p="1.5em"
                m=".5em"
                width="50%"
                fontSize="1.1em"
                borderRadius="5px"
                bgcolor="#AAACB3"
                color="white"
                textAlign="justify"
              >
                Après avoir chargé votre image, selectionnez le format que vous souhaitez activer en cliquant sur le bouton correspondant.
                Recadrez votre image comme vous le souhaitez, puis validez.
                Vous pouvez maintenant utiliser votre image dans Kards.
                Attention, par défaut, si vous utilisez un format inactif, le format original sera utilisé.
              </Box>

              {current && (
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
                <Box width="30%">
                  <TextInputField
                    required
                    data_cy_input="input-name-image"
                    name="name"
                    label="Choisissez un titre"
                    tooltip
                    tooltipText="Le titre est informatif, c'est lui qui est affiché dans la liste principale des images"
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="1em 0px"
                  width="70%"
                >
                  <Box
                    display="flex"
                    flex=".5"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ImageWrapper src={current} />
                    <Box padding=".5em">
                      IMAGE ORIGINAL
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flex=".5"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ImageProductField
                      name="edit.rectangle"
                      label="FORMAT RECTANGLE"
                      description="Ce format sera utilisé pour les composant produits, image et carrousel."
                      type="card"
                      form={form}
                      upload={false}
                    />
                    {/* <ImageProductField
                      name="edit.full"
                      label="FORMAT ÉCRAN"
                      description={'Ce format sera utilisé en fond d\'écran lorsque l\'application est inactive.'}
                      type="full"
                      form={form}
                      upload={false}
                    /> */}
                    <ImageProductField
                      name="edit.square"
                      label="FORMAT CARRÉ"
                      type="category"
                      description={'Ce format sera utilisé pour les catégories des templates "Vitrine" et "Mosaïque".'}
                      form={form}
                      upload={false}
                    />
                    <ImageProductField
                      name="edit.rectangleInline"
                      label="FORMAT LIGNE"
                      type="categoryInline"
                      description={'Ce format sera utilisé pour les catégories du template "Galerie".'}
                      form={form}
                      upload={false}
                    />
                  </Box>
                </Box>
              </Box>
              )}
            </Box>
          </MainScreen>
          <Box
            bgcolor="primary.main"
            flex={0.05}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Toolbar
              resource={props.resource}
              id={props.id}
              basePath={props.basePath}
            />
          </Box>
        </form>
      )}
    />
  );
};

const Rectangle = ({
  // eslint-disable-next-line
  nocrop = null, handleOpen = null, src = null, originalLoaded = null, form = null,
}) => (
  <ContainerImageWrapper>
    <RectangleWrapper>
      <ImageWrapper src={src || '/img/utils/picture.svg'} />
      <div
        onClick={handleOpen}
        data-cy="btn-image-rectangle"
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgb(0,0,0,0.4)',
        }}
      >
        <img alt="plus" src="/img/icon/plus.svg" width="30px" />
      </div>
    </RectangleWrapper>
  </ContainerImageWrapper>
);


const Full = ({
  nocrop = null, handleOpen = null, src = null, originalLoaded = null, form = null,
}) => (
  <ContainerImageWrapper>
    <div>
      {!nocrop && <Title>Application inactive</Title>}
      {!nocrop && (
      <Legend>
        {'Ce format sera utilisé en fond d\'écran lorsque l\'application est inactive.'}
      </Legend>
      )}
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          form.mutators.setValue('full', originalLoaded);
        }}
      >
        Activer ce format
      </Button>
    </div>
    <FullStyled>
      <ImageWrapper src={src || '/img/utils/picture.svg'} />
      <div
        onClick={handleOpen}
        data-cy="btn-image-full"
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgb(0,0,0,0.4)',
        }}
      >
        <img alt="plus" src="/img/icon/plus.svg" width="30px" />
      </div>
    </FullStyled>
  </ContainerImageWrapper>
);


const Square = ({
  nocrop = null, handleOpen = null, src = null, originalLoaded = null, form = null,
}) => (
  <ContainerImageWrapper>
    <div>
      {!nocrop && <Title>Categorie</Title>}
      {!nocrop && (
      <Legend>
        {`Ce format sera utilisé pour les
          catégories des templates "Vitrine" et "Mosaïque".`}
      </Legend>
      )}
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          form.mutators.setValue('square', originalLoaded);
        }}
      >
        Activer ce format
      </Button>
    </div>
    <SquareStyled>
      <ImageWrapper src={src || '/img/utils/picture.svg'} />
      <div
        onClick={handleOpen}
        data-cy="btn-image-square"
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgb(0,0,0,0.4)',
        }}
      >
        <img alt="plus" src="/img/icon/plus.svg" width="30px" />
      </div>
    </SquareStyled>

  </ContainerImageWrapper>
);

const RectangleInline = ({
  nocrop = null, handleOpen = null, src = null, originalLoaded = null, form = null,
}) => (
  <ContainerImageWrapper>
    <div>
      {!nocrop && <Title>Categorie Horizontal</Title>}
      {!nocrop && (
      <Legend>
        {`Ce format sera utilisé pour
          les catégories du template "Galerie".`}
      </Legend>
      )}
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          form.mutators.setValue('rectangleInline', originalLoaded);
        }}
      >
        Activer ce format
      </Button>
    </div>
    <RectangleInlineStyled>
      <ImageWrapper src={src || '/img/utils/picture.svg'} />
      <div
        onClick={handleOpen}
        data-cy="btn-image-inline"
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgb(0,0,0,0.4)',
        }}
      >
        <img alt="plus" src="/img/icon/plus.svg" width="30px" />
      </div>
    </RectangleInlineStyled>
  </ContainerImageWrapper>
);


export const ChoiceImage = ({
  nocrop = null, type, handleOpen = null, src = null,
}) => {
  switch (type) {
    case 'square':
      return <Square nocrop={nocrop} handleOpen={handleOpen} src={src} />;
    case 'rectangle':
      return <Rectangle nocrop={nocrop} handleOpen={handleOpen} src={src} />;
    case 'full':
      return <Full nocrop={nocrop} handleOpen={handleOpen} src={src} />;
    case 'rectangleInline':
      return <RectangleInline nocrop={nocrop} handleOpen={handleOpen} src={src} />;
    default:
      break;
  }
};
