/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

export const useGetCategories = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const dataProvider = useDataProvider();
  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList('categories', { filter: {}, sort: {}, pagination: { page: 1, perPage: 1000 } })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  return {
    data, loading, error, setData,
  };
};
