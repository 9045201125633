import React, { useState, useEffect } from 'react';
import MaterialModal from '@material-ui/core/Modal';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import firebase from 'firebase';
import moment from 'moment';

type PWAType = {
  onFire: number;
  [key: string]: any;
};

async function getPWA() {
  const doc = await firebase
    .firestore()
    .collection('application')
    .doc('pwa')
    .get();
  return doc.data() as PWAType;
}

function formatTime(onFireTime: number) {
  if (!onFireTime || onFireTime <= Date.now()) return null;

  const diff = onFireTime - Date.now();
  const seconds = moment.duration(diff).seconds() < 10 ? `0${moment.duration(diff).seconds()}` : moment.duration(diff).seconds();
  const minutes = moment.duration(diff).minutes() < 10 ? `0${moment.duration(diff).minutes()}` : moment.duration(diff).minutes();
  return `${minutes}:${seconds}`;
}

function useGetPWA(trigger: any[]) {
  const [pwa, setPWA] = useState<PWAType>();
  useEffect(() => {
    getPWA().then(pwa => {
      setPWA(pwa);
    });
  }, trigger);
  return { pwa };
}

function useInterval(callback: () => void, trigger: any[], timeout = 1000) {
  useEffect(() => {
    const id = window.setInterval(() => {
      callback();
    }, timeout);

    return () => {
      clearInterval(id);
    };
  }, trigger);
}

export function OnFire({ mobile = false, onAccept = (time: number) => Promise.resolve(true) }) {
  const [triggerOnFire, setTriggerOnFire] = useState(false);
  const [time, setTime] = useState(null);
  const [open, setOpen] = useState(false);

  const { pwa } = useGetPWA([triggerOnFire]);
  const onFire = pwa?.onFire;
  useInterval(() => setTime(formatTime(onFire)), [onFire]);

  useEffect(() => {
    if (!time) onAccept(null);
  }, [time]);

  const handleAccept = async (timeToAdd: number) => {
    const t = !onFire || onFire <= Date.now() ? Date.now() : onFire;
    const time =
      timeToAdd === 0
        ? null
        : parseInt(
            moment(t)
              .add(timeToAdd, 'm')
              .format('x'),
          );
    await onAccept(time);
    setTriggerOnFire(!triggerOnFire);
    setOpen(false);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <ColorButton key={`${open}`} onClick={() => setOpen(true)}>
          {time || 'Pause'}
        </ColorButton>
        <Text color="black" size=".9em" weight="400" textAlign="center" width="70%" padding=".3em" lineHeight="1em">
          Stopper momentanément les commandes
        </Text>
      </Box>
      <MaterialModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <>
          <ModalWrapper mobile={mobile}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Text color="black" size="1.5em" weight="300" textAlign="center" width="70%">
                  Ajouter du temps durant lequel les commandes serront indisponibles.
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              width="100%"
              pt="2em"
              pb="2em"
            >
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%">
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="80%" m="1em">
                  <Button variant="contained" color="secondary" onClick={() => handleAccept(5)}>
                    + 5min
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => handleAccept(10)}>
                    + 10min
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => handleAccept(15)}>
                    + 15min
                  </Button>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-around" width="80%" m="1em">
                  <Button variant="contained" color="secondary" onClick={() => handleAccept(0)}>
                    Reprendre
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
                    Fermer
                  </Button>
                </Box>
              </Box>
            </Box>
          </ModalWrapper>
        </>
      </MaterialModal>
    </>
  );
}

const ColorButton = withStyles(() => ({
  root: {
    textTransform: 'inherit',
    color: 'white',
    borderRadius: '30px',
    width: '60%',
    backgroundColor: '#6da993',
    boxShadow: '0 4px 6px rgba(0,0,0,.2)',
    '&:hover': {
      backgroundColor: '#6da993',
    },
  },
}))(Button);

const ModalWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  height: 40vh;
  width: ${props => (props.mobile ? '80%' : '40%')};
  border-radius: 5px;
`;

const Text = styled.div`
  text-align: ${props => props.textAlign};
  width: ${props => props.width};
  color: ${props => props.color};
  font-size: ${props => props.size};
  font-weight: ${props => props.weight};
  font-family: ${props => props.family};
  padding: ${props => props.padding};
  padding-bottom: ${props => props.paddingBottom};
  white-space: ${props => props.whiteSpace};
  line-height: ${props => props.lineHeight};
  letter-spacing: ${props => props.lettreSpacing};
  display: flex;
  justify-content: center;
  align-items: center;
`;
