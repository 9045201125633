import React, { useContext } from 'react';
import { Skeleton } from '@material-ui/lab';
import { ImageSlides } from 'kards-components';

import { ThemeContext } from 'src/cards/edit/components/context/theme';

export default ({
  data: {
    value: {
      pictures,
    },
  },
}) => {
  const {
    theme,
  }: any = useContext(ThemeContext);
  if (!pictures || pictures.length === 0) {
    return (
      <div style={{ margin: '10px', display: 'flex', flexDirection: 'row' }}>
        <Skeleton variant="rect" width="30%" height={100} />
        <Skeleton variant="rect" width="30%" height={100} />
        <Skeleton variant="rect" width="30%" height={100} />
      </div>
    );
  }
  return (
    <ImageSlides
      theme={theme}
      data={{ pictures }}
    />
  );
};
