import React, { useContext } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import {
  IonButton,
  IonIcon,
  IonHeader,
} from '@ionic/react';
import { arrowBack, menu } from 'ionicons/icons';
import { colors } from 'kards-components';
import { ContributionContext } from 'src/cards/edit/components/context/contribution';
import { ThemeContext } from 'src/cards/edit/components/context/theme';

const WrapperHeader = styled.div`
  background-color: ${(props) => props.color};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const IonButtonWrapper = styled(IonButton)`
  margin: 0;
`;

const IonHeaderWrapper = styled(IonHeader)`
  flex: .1;
  &::after{
    height: 0;
  }
`;

const ContainerButton = styled.div`
  flex: .15;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: ${(props) => props.paddingTop || 0}px;
  padding-bottom: ${(props) => props.paddingBottom || 0}px;
`;

const Header = ({ template }) => {
  const {
    currentCategory,
    action: {
      setCurrentCategory,
    },
  } = useContext(ContributionContext);
  const {
    theme,
  } = useContext(ThemeContext);
  return (
    <IonHeaderWrapper style={{background: colors[get(theme, 'color.backgroundHeader')]}} translucent>
      <WrapperHeader color={colors[get(theme, 'color.backgroundHeader')]} id="main-content">
        <ContainerButton>
          {
            template.name !== 'Vitrine'
            && currentCategory
            && (
              <IonButtonWrapper fill="clear" onClick={() => setCurrentCategory(null)}>
                {currentCategory && <IonIcon slot="icon-only" mode="md" icon={arrowBack} color={get(theme, 'color.buttonHeaderColor')} />}
              </IonButtonWrapper>
            )
          }
        </ContainerButton>
        <ContainerText
          paddingTop={get(theme, 'header.logoPaddingTop')}
          paddingBottom={get(theme, 'header.logoPaddingBottom')}
        >
          <img height={get(theme, 'header.logoSizeHeight')} src={get(theme, 'header.logo')} alt="" />
        </ContainerText>
        <ContainerButton>
          <IonButtonWrapper fill="clear">
            <IonIcon slot="icon-only" mode="md" icon={menu} color={get(theme, 'color.buttonHeaderColor')} />
          </IonButtonWrapper>
        </ContainerButton>
      </WrapperHeader>
    </IonHeaderWrapper>
  );
};

export default Header;
