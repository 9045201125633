import React from 'react';
import { flatMap, groupBy, map, sum, mapValues } from 'lodash';
import { Box } from '@material-ui/core';
import Dinero from 'dinero.js';
import { Table, Th, TableRow, TableCell } from 'src/layout/Table/Table';
import { GraphPie } from '../components/GraphPie';
import { Title } from '../components/Title';
import { Order, Product } from 'src/data/types';
import { useQuery } from 'src/helpers/useQuery';
import { getProducts } from 'src/data/accessor/getProducts';
import { CardStyled } from '../components/Card';
import { sumAmount } from '../Infos/Infos';

type ByProductsProps = {
  orders: Order[];
};

type StatProduct = {
  id?: string;
  name: string;
  totalQuantity: number;
  totalPrice: number;
  ca: number;
};

type DataGraph = {
  id: string;
  name: string;
  value: number;
};

export function ByProducts({ orders }: ByProductsProps) {
  const statsProducts = getStatProductsByOrder(orders);
  const dataGraphProducts = mapToGraph(statsProducts);

  const { data: products } = useQuery(() => getProducts());
  const statsCategories = getStatCategories(products, statsProducts);
  const dataGraphCategories = mapToGraph(statsCategories);

  return (
    <>
      <Template dataGraph={dataGraphProducts} dataTable={statsProducts} />
      <Title>Categories</Title>
      <Template dataGraph={dataGraphCategories} dataTable={statsCategories} />
    </>
  );
}

type TemplateProps = {
  dataGraph: DataGraph[];
  dataTable: StatProduct[];
};

function Template({ dataGraph, dataTable }: TemplateProps) {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box width="100%" height="600px">
        <GraphPie data={dataGraph} />
      </Box>
      <Box width="100%" mt={3}>
        <CardStyled p={2}>
          <Table
            header={
              <TableRow>
                <Th sortKey="name">Nom</Th>
                <Th sortKey="totalQuantity" align="right">
                  Quantité
                </Th>
                <Th sortKey="totalPrice" align="right">
                  Prix total €
                </Th>
                <Th sortKey="ca" align="right">
                  % du CA
                </Th>
              </TableRow>
            }
            data={dataTable}
            sort="totalPrice"
            sortBy="desc"
            renderItem={item => (
              <TableRow key={item.name}>
                <TableCell component="th" scope="row">
                  {item.name}
                </TableCell>
                <TableCell align="right">{item.totalQuantity}</TableCell>
                <TableCell align="right">{item.totalPrice} €</TableCell>
                <TableCell align="right">{item.ca}</TableCell>
              </TableRow>
            )}
          />
        </CardStyled>
      </Box>
    </Box>
  );
}

function mapToGraph(products: StatProduct[]): DataGraph[] {
  return products.map(p => ({ id: p.name, name: p.name, value: p.totalPrice }));
}

export function getStatProductsByOrder(orders: Order[]): StatProduct[] {
  const baskets = getBaskets(orders);
  const basketsByProductId = groupBy(baskets, 'id');
  const products = map(basketsByProductId, values => {
    return ({
      id: values[0]?.id,
      name: values[0]?.reference,
      totalQuantity: sum(map(values, v => v.quantity)),
      totalPrice: sumAmount(map(values, v => v.price * v.quantity).filter(Boolean)),
    })
  }).filter(p => p.name);

  const CA = sum(products.map(v => v.totalPrice));
  const withCA = products.map(p => ({
    ...p,
    totalPrice: Dinero({ amount: p.totalPrice }).toRoundedUnit(0, 'HALF_EVEN'),
    ca: Math.round((p.totalPrice / CA) * 100),
  }));
  return withCA;
}

function getCategoriesByIds(products: Product[]) {
  const simpleProduct = map(products, p => ({ id: p.id, tag: p.tag }));
  const categories = groupBy(simpleProduct, 'tag');
  const categoriesByIds = mapValues(categories, values => values.map(v => v.id));
  return categoriesByIds;
}

function getStatCategories(products: Product[], statsProducts: StatProduct[]): StatProduct[] {
  const categoriesByIds = getCategoriesByIds(products);
  const stat = map(categoriesByIds, (values, categoryName) => {
    const cat = values.map(idProduct => statsProducts.find(p => p.id === idProduct)).filter(Boolean);
    return {
      name: categoryName,
      totalQuantity: sum(map(cat, v => v.totalQuantity)),
      totalPrice: sum(map(cat, v => v.totalPrice)),
    };
  });
  const CA = sum(stat.map(v => v.totalPrice));
  const withCA = stat.map(p => ({
    ...p,
    totalPrice: Dinero({ amount: p.totalPrice }).toRoundedUnit(0, 'HALF_EVEN'),
    ca: Math.round((p.totalPrice / CA) * 100),
  }));
  return withCA;
}

function getBaskets(orders: Order[]) {
  return flatMap(orders, order => Object.values(order.items));
}
