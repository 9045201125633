import React from 'react';

import Edit from './Edit';

const renderEdit = ({ data }) => <Edit data={data} />;

const recommand = {
  icone: '/img/components/carousel.svg',
  explain: '/img/components/explain/carousel.svg',
  description: "Ce composant permet d'afficher 5 produits aléatoires commandés par l'utilisateur",
  nameId: '5 produits aléatoires de l\'utilisateur',
  type: 'recommand',
  init: { products: [] },
  renderEdit,
};

export default recommand;
