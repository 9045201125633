import React, { useState } from 'react';
import { get } from 'lodash';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components';
import { GetOneOrder } from 'src/data/injector/GetOneOrder';
import { GetOrderByDate } from 'src/data/injector/GetOrderByDate';
import ItemOrder from '../list/ItemOrder';
import SearchOrderList from './SearchOrdersList';

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const StyledTabs = withStyles({
  root: {
    width: '50%',
  },
  indicator: {
    backgroundColor: '#5bfcc2',
  },
})(Tabs);

const StyledTab: any = withStyles(() => ({
  root: {
    margin: '0.5em 0',
    textTransform: 'none',
    minWidth: 72,
    fontSize: '1em',
    letterSpacing: '1px',
    color: 'white',
    '&$selected': {
      color: '#5bfcc2',
    },
    '&:focus': {
      color: '#5bfcc2',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;
  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
      width="100%"
    >
      {value === index
        && (
        <Box p={3} display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
          {children}
        </Box>
        )}
    </Box>
  );
};

const SearchOrder = () => {
  const [value, setValue] = React.useState(0);
  const [needReload, setNeedReload] = React.useState(false);
  const [searchById, setSearchById] = useState();
  const [resultSearchByDateAndFilter, setResultSearchByDateAndFilter] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      width="100%"
      alignItems="center"
      flexDirection="column"
    >
      <StyledTabs value={value} onChange={handleChange} variant="fullWidth">
        <StyledTab label="PAR NUMERO" data-cy="btn-tab-to-number" />
        <StyledTab label="PAR DATE" data-cy="btn-tab-to-date" />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <Box>
          <GetOneOrder setSearchById={setSearchById} />
        </Box>
        <ItemOrder
          key={get(searchById, 'id')}
          item={searchById}
          callbackAction={setSearchById}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
          <GetOrderByDate setResultSearchByDateAndFilter={setResultSearchByDateAndFilter} needReload={needReload} setNeedReload={setNeedReload} />
        </Box>
        <ListContainer>
          <SearchOrderList orders={resultSearchByDateAndFilter} callbackAction={() => setNeedReload(true)} />
        </ListContainer>
      </TabPanel>
    </Box>
  );
};

export default SearchOrder;
