import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, InputBase } from '@material-ui/core';
import DatePickerUI from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type DatePickerProps = {
  label?: string;
  value?: Date;
  onChange: (dateInput: Date) => void;
} & Omit<React.ComponentProps<typeof DatePickerUI>, 'onChange'>;

export function DatePicker({ label, onChange, value, ...props }: DatePickerProps) {
  const [dateInput, setDate] = useState<Date>(value);

  useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <Box display="flex" flexDirection="column">
      <Box>{label}</Box>
      <DatePickerUI
        id="scheduledAt"
        name="scheduledAt"
        todayButton="Aujourd'hui"
        calendarClassName="datePickerTime"
        dateFormat="dd/MM/yyyy"
        selected={dateInput}
        onCalendarClose={() => onChange(dateInput)}
        onChange={(date: Date) => setDate(date)}
        customInput={<BootstrapInput />}
        {...props}
      />
    </Box>
  );
}

const BootstrapInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#222428',
    },
  },
}))(InputBase);
