import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { useGetMostPopularProducts } from 'src/data/accessor/useGetMostPopularProducts';
import { CarouselOrList } from '../CarouselOrSimpleList';

export default ({ data }) => {
  const {
    value: { title, description },
  } = data;
  const { productsMostPopular } = useGetMostPopularProducts();
  const titleDefault = 'Les plus populaires 🔥';

  if (!productsMostPopular?.length) {
    return (
      <div style={{ margin: '10px' }}>
        <Skeleton variant="rect" width="100%" height={250} />
        <Skeleton width="50%" />
        <Skeleton width="60%" />
      </div>
    );
  }

  return (
    <CarouselOrList
      data={{
        products: productsMostPopular,
        title: title || titleDefault,
        description,
      }}
    />
  );
};
