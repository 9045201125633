import firebase from 'firebase';

export type CashRegisterType = {
  name?: 'tiller' | 'zelty' | 'no-cash-registery';
  credentials?:
    | {
        providerToken: string;
        restaurantToken: string;
      }
    | { authorizationBearer: string };
  step: 'init' | 'form-infos' | 'import-in-progress' | 'done';
};

export function getCashRegister(cb) {
  return firebase
    .app()
    .firestore()
    .collection('application')
    .doc('cashRegister')
    .onSnapshot(doc => cb(doc.data()));
}

export function setCashRegister(value: Partial<CashRegisterType>) {
  return firebase
    .app()
    .firestore()
    .collection('application')
    .doc('cashRegister')
    .update(value);
}
