//**********************************************************
//* Receipt printing management
//**********************************************************
// Address and Port to connect
import { map } from 'lodash'
import { kMaxLength } from 'buffer';

var PAPER_WIDTH = {
	PAPER_WIDTH_58MM: 0, 	// 58mm Reciept
	PAPER_WIDTH_80MM: 1		// 80mm Reciept
};

const getTimestamp = function() {
	let YEAR = "/";
	let MONTH = "/";
	let DAY = "";
	let HOUR = ":";
	let MINUTE = "";
	var now:any = new Date();

	// Year
	var year = now.getYear();
	if (year < 2000) {
		year += 1900;
	}
	year = year + YEAR;

	// Month
	var month = now.getMonth() + 1;
	if (month < 10) {
		month = "0" + month;
	}
	month = month + MONTH;

	// Date
	var date = now.getDate();
	if (date < 10) {
		date = "0" + date;
	}
	date = date + DAY;

	// Hour
	var hour = now.getHours();
	if (hour < 10) {
		hour = "0" + hour;
	}
	hour = hour + HOUR;

	// Minute
	var minute = now.getMinutes();
	if (minute < 10) {
		minute = "0" + minute;
	}
	minute = minute + MINUTE;

	// ex) 2012/02/20 13:25
	return year + month + date + " " + hour + minute;
}

const  to2ByteChar = function(str) {
	return str
}

const print = function ({ip, request, name='test', orderId }){
	//Set the end point address
  //Create an ePOS-Print object
  	let printAdress = `http://${ip}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`

	return new Promise(resolve =>{
		let typedWindow:any = window
		const epos = new typedWindow.epson.ePOSPrint(printAdress);
    	epos.send(request);
		epos.onreceive = function (res) {
			var msg = ' Print ' + (res.success ? ' Success ' : ' Failure ') + '\n Code:' + res.code + '\n Status:\n';
			// Obtain the printer status
			var asb = res.status;
			if (asb & epos.ASB_NO_RESPONSE)		{	msg = 'Pas de réponse';	}
			if (asb & epos.ASB_PRINT_SUCCESS)		{	msg = 'Impression terminée';	}
			if (asb & epos.ASB_DRAWER_KICK)		{	msg = ' Status of the drawer kick number 3 connector pin = "H"\n';	}
			if (asb & epos.ASB_OFF_LINE)			{	msg = ' Imprimante éteinte\n';	}
			if (asb & epos.ASB_COVER_OPEN)		{	msg = ' Couvercle ouvert\n';	}
			if (asb & epos.ASB_PAPER_FEED)		{	msg = ' Paper feed switch is feeding paper\n';	}
			if (asb & epos.ASB_WAIT_ON_LINE)		{	msg = 'Pas de connexion internet\n';	}
			if (asb & epos.ASB_PANEL_SWITCH)		{	msg = ' Panel switch is ON\n';	}
			if (asb & epos.ASB_MECHANICAL_ERR)	{	msg = ' erreur mechanique\n';	}
			if (asb & epos.ASB_AUTOCUTTER_ERR)	{	msg = ' Auto cutter error generated\n';	}
			if (asb & epos.ASB_UNRECOVER_ERR)		{	msg = ' Unrecoverable error generated\n';	}
			if (asb & epos.ASB_AUTORECOVER_ERR)	{	msg = ' Auto recovery error generated\n';	}
			if (asb & epos.ASB_RECEIPT_NEAR_END)	{	msg = 'Bientot plus de papier\n';	}
			if (asb & epos.ASB_RECEIPT_END)		{	msg = 'Plus de papier \n';	}
			if (asb & epos.ASB_SPOOLER_IS_STOPPED){	msg = ' Stop the spooler\n';	}
		// Display in the dialog box

			if (res.success == false ){
				resolve({ ip: ip, name, printed: false, error: msg, orderId});
			}
			resolve({ip: ip, name,  printed: true,  error: null, orderId})
		}
	})
}

export function connectEpson(printers=[{ip:"192.168.1.34"}]) {
  let typedWindow:any = window
  let connect = (ip, name) => new Promise(resolve=> {
    var eposdev = new typedWindow.epson.ePOSDevice();
    eposdev.connect(ip, 8008, cbCreateDevice)
    function cbCreateDevice(code){
      resolve({name, ip, connexion: code, error: code !== "OK"})
    }
  })

	return new Promise((resolve)=> { 
	let promises = []
	printers.forEach((p:{ip: string, name: string })=> {
	promises.push(connect(p.ip, p.name))
	})
	return Promise.all(promises).then((res)=>{
	resolve(res)
	})
	})	
}
	
export function printReceipt(order, ip ='http://192.168.1.34/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000', name="test") {
	let typedWindow:any = window
	// Slip number 
	let TOTAL = "TOTAL";
	let SHOPID = "KARDS";
	let ADDRESS = "france";
	let SeqNO = 1;
	let paperWidth = 1
	const deviceObj = new typedWindow.epson.ePOSBuilder();
	//Open drawer
	deviceObj.addPulse(deviceObj.DRAWER_1, deviceObj.PULSE_100); 
	
	//Header
	// deviceObj.addTextAlign(deviceObj.ALIGN_CENTER);
	// deviceObj.addImage(logocontext, 0, 0, 256, 60);
	deviceObj.addText('\n');
	deviceObj.addText(SHOPID);
	deviceObj.addText('\n');
	deviceObj.addText(ADDRESS);
	deviceObj.addText('\n\n');
	deviceObj.addText('Merci');
	deviceObj.addText('\n');
	
	deviceObj.addText('\n\n');
	deviceObj.addText(getTimestamp());
	deviceObj.addText('\n');

	deviceObj.addText('\n\n');
	deviceObj.addText(`Numero de commande : ${order.key}`);
	deviceObj.addText('\n');

	if (order?.parameters.onplace_number) {
		deviceObj.addText('\n\n');
		deviceObj.addText(`Numero de table : ${order?.parameters.onplace_number}`);
		deviceObj.addText('\n');
	}


	// Set the individual information of the product of the order list
	for(var i=0; ; i++){
		var purchase = order.items[i]
		if ( purchase == null ){
			break;
		}
		var strQuantity = "";
		var strSubtotal = "";

		switch (paperWidth) {
			case PAPER_WIDTH.PAPER_WIDTH_58MM:
				// Product name
				var strName = to2ByteChar(purchase["reference"]);
				strName = (strName + "            ").slice(0, 12);

				// Quantity
				strQuantity = '   ' + purchase["quantity"];
				strQuantity = strQuantity.slice(strQuantity.length - 3, strQuantity.length);
				purchase.options.map((k, i)=> {
					// strQuantity = '   ' + purchase["quantity"];
					console.log(k, i)
				})
				// Subtotal
				strSubtotal = '        ' + 12;
				strSubtotal = strSubtotal.slice(strSubtotal.length - 8, strSubtotal.length);
				break;

			case PAPER_WIDTH.PAPER_WIDTH_80MM:
				// Product name
				var strName = to2ByteChar(purchase["reference"]);
				strName = (strName + "                    ").slice(0, 20);

				console.log('purchase -->', purchase)
				// Quantity
				strQuantity = '   ' + purchase["quantity"];;
				strQuantity = strQuantity.slice(strQuantity.length - 3, strQuantity.length);

				// Subtotal
				strSubtotal = '             ' + `${purchase["price"]/100} €`;
				strSubtotal = strSubtotal.slice(strSubtotal.length - 13, strSubtotal.length);
				break;

			default:
				break;
		}

		deviceObj.addText('\n');
		deviceObj.addText(strName);
		deviceObj.addText(strQuantity);
		deviceObj.addText(' ');
		deviceObj.addText('\n\n');
		map(purchase?.options, (v, k:any)=> {
			// strQuantity = '   ' + purchase["quantity"];
			if (purchase.type != "offer"){
				deviceObj.addText(k);
				deviceObj.addText('\n\n');
				deviceObj.addText(v);
			}
			if (purchase.type == "offer") {
				console.log(k, v)
				deviceObj.addText(k);
				deviceObj.addText('\n\n');
				deviceObj.addText(v.reference);
				deviceObj.addText('\n\n');
				map(v?.options, (v2, k2)=> {
					console.log("here", v2, k2)
					deviceObj.addText(k2);
					deviceObj.addText('\n\n');
					deviceObj.addText(v2);
				})
			}
		})
		deviceObj.addText('\n\n');
		deviceObj.addText(strSubtotal);
	}

	deviceObj.addText('\n\n');

	// Set total, net and tax for reciept printing
	var strTotal = `${order.totalPrice/100} €`

	switch( paperWidth ){
		case PAPER_WIDTH.PAPER_WIDTH_58MM:
			// Total
			strTotal = '            ' + strTotal;
			strTotal = strTotal.slice(strTotal.length - 10, strTotal.length);

			break;

		case PAPER_WIDTH.PAPER_WIDTH_80MM:
			// Total
			strTotal = '                 ' + strTotal;
			strTotal = strTotal.slice(strTotal.length - 15, strTotal.length);
			break;
		default:
			break;
	}
	// Total
	deviceObj.addTextDouble(true);
	deviceObj.addText(TOTAL);
	deviceObj.addText(strTotal);
	deviceObj.addTextDouble(false);
	deviceObj.addText('\n\n');

	// Footer
	deviceObj.addTextAlign(deviceObj.ALIGN_CENTER);
	deviceObj.addText(order.items.length + ' lines');

	var strSeqNo = '0000' + SeqNO;
	strSeqNo = strSeqNo.slice(strSeqNo.length - 4, strSeqNo.length);
	deviceObj.addText(strSeqNo);
	deviceObj.addFeedLine(3);
	deviceObj.addCut(deviceObj.CUT_FEED);
	var request = deviceObj.toString();
	console.log(request)

	// Increment slip number
	SeqNO += 1;
	// If Slip number is greater than 9999
	if (SeqNO > 9999) {
		// Initialize slip number
		SeqNO = 0;
	}
	// return { print: true}
	return print({request, ip, name, orderId: order.id })

}