import React from 'react';
import { useFormState } from 'react-final-form';
import { useNotify, useRedirect } from 'react-admin';
import firebase from 'firebase';
import { TransparentBtn, PublishButtonConfirme } from '../../layout/Buttons';

// eslint-disable-next-line
export default ({ id, resource, modal = false, basePath = null }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const formState = useFormState();
  const defaultFirestore = firebase.app().firestore();

  const handleClick = () => {
    if (!formState.valid) {
      return;
    }

    console.log(formState)

    // eslint-disable-next-line consistent-return
    return defaultFirestore
      .collection('application')
      .doc('printers')
      .set({
        printers: formState.values?.printers || '',
      })
      .then(() => {
        defaultFirestore
        .collection('application')
        .doc('pwa')
        .update({
          phone: formState.values?.phone || '',
          logo: formState.values?.logo || '',
          open: formState.values.open,
          contractors: formState.values.contractors || [],
        })
        .then(() => {
          notify('ra.notification.updated', 'info', {
            smart_count: 1,
          });
        if (basePath) redirectTo('list', basePath);
      });
    });
  };

  if (modal) {
    return (
      <PublishButtonConfirme
        onAccept={handleClick}
        OpenButton={props => (
          <TransparentBtn variant="extended" onClick={props.handleOpen} data-cy="btn-edit-resource">
            Enregistrer
          </TransparentBtn>
        )}
      />
    );
  }

  return (
    <TransparentBtn variant="extended" onClick={handleClick} data-cy="btn-edit-resource">
      Enregistrer
    </TransparentBtn>
  );
};
