import firebase from 'firebase';

export type PWAType = {
  cardId: string;
  lastupdate: number;
  start: boolean;
  version: string;
  compliledData: any;
  open: any;
  phone: string;
  logo: string;
};

export function getPWAp(): Promise<PWAType> {
  return firebase
    .app()
    .firestore()
    .collection('application')
    .doc('pwa')
    .get()
    .then(doc => doc.data() as PWAType);
}

export function getPwa(cb) {
  return firebase
    .app()
    .firestore()
    .collection('application')
    .doc('pwa')
    .onSnapshot(doc => cb(doc.data()));
}

export function setPWA(value: Partial<PWAType>) {
  return firebase
    .app()
    .firestore()
    .collection('application')
    .doc('pwa')
    .update(value);
}
