import React from 'react';
import { useFormState } from 'react-final-form';
import { get } from 'lodash';
import styled from 'styled-components';
import { useNotify, useDataProvider } from 'react-admin';
import firebase from 'firebase';
import { TransparentBtn } from 'src/layout/Buttons';
import { checkSlotUnicity } from '../accessor/checkCardSlotsUnicity';

const backgroundupdate = async ({ values, id }) => {
  const update = await firebase
    .firestore()
    .collection('cards')
    .doc(id)
    .update(values);
};

const ContainerButton = styled.div`
  justify-content: ${props => props.justifyContent};
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const EditButtonWithCheck = ({ id, ...props }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const formState = useFormState();
  // const [needAutoSave, setNeedAutoSave] = useState(false);

  const handleClick = async ({ autosave = false }) => {
    if (!formState.valid) {
      return;
    }
    if (autosave) {
      notify('autosave_in_progress', 'info', {
        smart_count: 1,
      });
    }
    const currentSlots = get(formState, 'values.slots');
    const isUnique = await checkSlotUnicity({
      currentSlots,
      currentId: props.record.id,
    });
    if (!isUnique) {
      return notify('card.form.errors.unicity', 'warning', {
        smart_count: 1,
      });
    }

    if (autosave) {
      return backgroundupdate({ id, values: formState.values }).then(() => {
        notify('ra.notification.updated', 'info', {
          smart_count: 1,
        });
      });
    }

    return dataProvider.update('cards', { id, data: formState.values }).then(() => {
      notify('ra.notification.updated', 'info', {
        smart_count: 1,
      });
    });
  };

  // MUST BE REACTIVATED WITH LOCK

  // useEffect(() => {
  //   const timeout = setInterval(() => {
  //     setNeedAutoSave(true);
  //   }, 120000);

  //   return () => clearTimeout(timeout);
  // }, []);

  // useEffect(() => {
  //   if (needAutoSave) {
  //     handleClick({ autosave: true });
  //     setNeedAutoSave(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [needAutoSave]);

  return (
    <ContainerButton justifyContent="center">
      <TransparentBtn variant="extended" onClick={handleClick} data-cy="btn-edit-card">
        Enregistrer
      </TransparentBtn>
    </ContainerButton>
  );
};
