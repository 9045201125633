/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import moment from 'moment';
import { updateStepOrder, updateOrder } from 'src/data/accessor/updateStepOrder';
import { ConfirmButton } from 'src/layout/Buttons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PDfOrderBilling } from 'src/helpers/Pdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DoneIcon from "@material-ui/icons/Done";

import { AverageTime } from '../averageTime';
import { getNextStepValue, getCurrentStep } from '../../flow';
import ProductsList from './ProductsList';
import { PrintersListPrint } from '../PrintersModal'

const ContainerCommande = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2em 1em;
`;

const ContentContainerCommande = styled.div`
  background: white;
  padding: 3px;
  border-radius: 8px;
`;

const ContainerTitle = styled.div`
  margin: 1em;
  margin-top: .5em;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const CommandeInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${(props) => props.marginBottom};
  padding-bottom: ${(props) => props.paddingBottom};
  border-bottom: ${(props) => props.borderBottom};
  width: ${(props) => props.width};
`;

const Time = styled.div`
  background-color: ${(props) => props.color && props.color};
  width: 5em;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Bold = styled.div`
  font-size: 1.1em;
  color: ${(props) => props.color || '#222428'};
  font-weight: bold;
`;

const Lien = styled.a`
  font-size: 1.1em;
  color: ${(props) => props.color || '#222428'};
  font-weight: bold;
`;

const ContainerProducts = styled.div`
  border-radius: 5px;
  margin: 0.5em 1em;
`;

const ContainerButtons = styled.div`
  padding: .5em;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const useStyles = makeStyles(() => ({
  button: {
    width: '48%',
    whiteSpace: 'nowrap',
    margin: '0.2em',
  },
  new: {
    backgroundColor: '#5BFCC2 !important',
    width: '100%',
    color: 'black',
    whiteSpace: 'nowrap',
    margin: '0.2em',
    boxShadow: 'none',
  },
  scheduled: {
    backgroundColor: '#5BFCC2 !important',
    width: '100%',
    color: 'black',
    whiteSpace: 'nowrap',
    margin: '0.2em',
    boxShadow: 'none',
  },
  preparing: {
    backgroundColor: '#5BFCC2 !important',
    width: '100%',
    color: 'black',
    whiteSpace: 'nowrap',
    margin: '0.2em',
    boxShadow: 'none',
  },
  ready: {
    backgroundColor: '#5BFCC2 !important',
    width: '100%',
    color: 'black',
    whiteSpace: 'nowrap',
    margin: '0.2em',
    boxShadow: 'none',
  },
  delivered: {
    backgroundColor: '#5BFCC2 !important',
    width: '100%',
    color: 'black',
    whiteSpace: 'nowrap',
    margin: '0.2em',
    boxShadow: 'none',
  },
}));

const text = {
  new: 'Préparer',
  scheduled: 'Préparer',
  preparing: 'Préparer',
  ready: 'Prêt',
  delivered: 'Livrer',
};

moment.locale('fr');

const ButtonNextStep = ({ order, callbackAction }) => {
  const classes = useStyles();
  const currentStep = getCurrentStep(order);
  const step = currentStep ? getNextStepValue(currentStep) : null;
  const updatedItem = async () => {
    const newOrder = await updateStepOrder(order);
    if (callbackAction) callbackAction({ ...newOrder });
  };
  return (
    <>
      {step ? (
        <ConfirmButton
          onAccept={updatedItem}
          OpenButton={
            (props) => (
              <Box>
                <Button
                  className={step && classes[step]}
                  onClick={props.handleOpen}
                  variant="contained"
                  data-cy={`btn-next-step-${get(order, 'key')}`}
                >
                  {text[step]}
                </Button>
              </Box>
            )
          }
        />
      ) : (
        <Box>
          <Button
            className={step && classes[step]}
            disabled
            variant="contained"
            data-cy={`btn-order-end-${get(order, 'key')}`}
          >
            Terminé
          </Button>
        </Box>
      )}
    </>
  );
};

const ButtonNextStepLow = ({ order, callbackAction }) => {
  const updatedItem = async (c) => {
    order.parameters.deliveryTime = moment().add(c.averageTime, 'minute').format('LT');
    await updateOrder(order);
    if (callbackAction) callbackAction();
  };
  return (
    <AverageTime onAccept={updatedItem} order={order} />
  );
};

const ItemOrder = ({ item, callbackAction, low = false }) => {
  const ref = useRef();
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const deliveryMode = get(item, 'parameters.delivery');
  const paymentId = get(item, 'parameters.paymentId');
  const phone = get(item, 'parameters.phone_number');
  const userInformations = get(item, 'parameters.userInformations');
  const deliveryTime = get(item, 'parameters.deliveryTime');
  const eventTime = get(item, 'created_at');
  const dateEventTime = new Date(eventTime);
  const preorder = get(item, 'parameters.preorderSlots');
  const week = [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
  ];

  const handleClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl1(null);
  };

  const open = Boolean(anchorEl1);
  const openCopied = Boolean(anchorEl2);

  const id = open ? 'simple-popover' : undefined;

  const idcopied = open ? 'simple-copied' : undefined;

  const copy = () => {
    setAnchorEl2(ref.current);
    setTimeout(() => setAnchorEl2(null), 1000);
  };

  return item ? (
    <ContainerCommande data-cy={`item-order-${get(item, 'key')}`} key={get(item, 'key')}>
      <ContentContainerCommande>
        <Box>
          <ContainerTitle>
            {
              preorder
              && (
                <Box
                  fontSize="1.1em"
                  color="#222428"
                  fontWeight="bold"
                  bgcolor="#ffce00"
                  width="100%"
                  borderRadius="8px"
                  textAlign="center"
                >
                  {week[dateEventTime.getDay()]}
                  {' '}
                  {dateEventTime.getDate()}
                  {' '}
                  <br />
                  {preorder}
                </Box>
              )
            }
            <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row" width="100%">
              <CommandeInfo>
                cmd n°&nbsp;
                <Bold data-cy={`order-number-${get(item, 'key')}`}>{get(item, 'key')}</Bold>
              </CommandeInfo>
              {!deliveryTime && (eventTime < Date.now()) && (
                <Timer eventTime={eventTime} deliveryTime={deliveryTime} />
              )}
            </Box>
            {
              deliveryTime
              && (
                <CommandeInfo>
                  pour:
                  &nbsp;
                  <Bold>{deliveryTime}</Bold>
                </CommandeInfo>
              )
            }
            {
              deliveryMode === 'onplace'
              && (
                <Bold color="#FF0066">
                  Table n°
                  {get(item, 'parameters.onplace_number')}
                </Bold>
              )
            }
          </ContainerTitle>
          <ContainerProducts>
            <Table size="small">
              <ProductsList items={get(item, 'items')} />
            </Table>
          </ContainerProducts>
        </Box>
        <ContainerButtons>
            <Box flex="column">
            {item.print && 
                <>
                  <p>Etat des impressions:</p>
                  <Box display="flex" flexDirection="row" flexWrap="wrap">
                    {item.print?.map((p)=> {
                      return <PrintItem item={p} />
                    })}
                  </Box>
                </>
            }
            <Button>
              <PrintersListPrint order={item} />
            </Button>
          </Box>
        </ContainerButtons>
        <ContainerButtons>
          {
            low
              ? <ButtonNextStepLow order={item} callbackAction={callbackAction} />
              : <ButtonNextStep order={item} callbackAction={callbackAction} />
          }
          <Box>
            <Button
              aria-describedby={id}
              variant="contained"
              color="primary"
              onClick={handleClick}
              style={{
                borderRadius: '50px',
                padding: '6px',
                minWidth: '2.5em',
              }}
            >
              ?
            </Button>
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl1}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
              padding="1em"
            >
              <Box fontSize="1.3em" fontWeight="bolder" width="100%" pb=".5em" textAlign="center">
                Informations client
              </Box>
              {
                userInformations && (userInformations.name && userInformations.firstname)
                && (
                  <>
                    <CommandeInfo>
                      Nom:
                      &nbsp;
                      <Bold>{userInformations.name}</Bold>
                    </CommandeInfo>
                    <CommandeInfo>
                      Prénom:
                      &nbsp;
                      <Bold>{userInformations.firstname}</Bold>
                    </CommandeInfo>
                  </>
                )
              }
              {
                phone
                && (
                  <CommandeInfo width="100%" marginBottom="1em" paddingBottom=".5em" borderBottom="solid 1px #5BFCC2">
                    Tel:
                    &nbsp;
                    <Bold>{phone}</Bold>
                  </CommandeInfo>
                )
              }
              {
                paymentId
                && (
                  <>
                    <CommandeInfo>
                      N° paiement :
                      &nbsp;
                      <CopyToClipboard
                        text={paymentId}
                        onCopy={copy}
                      >
                        <Bold
                          aria-describedby={idcopied}
                          ref={ref}
                        >
                          {paymentId}
                          <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            style={{ padding: '0 10px', marginLeft: '.5em' }}
                          >
                            Copier

                          </Button>

                          <Popover
                            id={idcopied}
                            open={openCopied}
                            anchorEl={anchorEl2}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          >
                            <Box p=".5em 1em">
                              Copié !
                            </Box>
                          </Popover>
                        </Bold>
                      </CopyToClipboard>
                    </CommandeInfo>
                    <CommandeInfo width="100%" marginBottom="1em" paddingBottom=".5em" borderBottom="solid 1px #5BFCC2">
                      Liens stripe :
                      &nbsp;
                      <Lien href="https://dashboard.stripe.com/login" target="blank">
                        www.stripe.com
                      </Lien>
                    </CommandeInfo>
                  </>
                )
              }
              {
                item
                && (
                  <CommandeInfo>
                    Reçu :
                    &nbsp;
                    <PDFDownloadLink document={<PDfOrderBilling order={item} />} fileName={`reçu_${get(item, 'key')}.pdf`}>
                      {({
                        blob, url, loading, error,
                      }) => (loading
                        ? <Bold>Téléchargement en cours...</Bold>
                        : <Bold>Télécharger le reçu</Bold>
                      )}
                    </PDFDownloadLink>
                  </CommandeInfo>
                )
              }
            </Box>
          </Popover>
        </ContainerButtons>
      </ContentContainerCommande>
    </ContainerCommande>
  ) : (<div />);
};


const PrintItem = ({ item }) => {
    return (
      !item.printed ? <div style={{ margin: '5px', fontSize: "10px", display: 'flex', padding: "2px", justifyContent: 'center', background: 'rgb(242, 70, 70)', color: 'white'}}>
        <p>{item.name}</p>
        <strong style={{ marginLeft: '5px'}}>{item.error}</strong>
      </div>: 
      <div style={{ margin: '5px', fontSize: "10px", display: 'flex', padding: "2px",  justifyContent: 'center', background: 'rgb(4, 155, 74)', color: 'white'}}>
        <p>{item.name}</p>
        {/* <DoneIcon style={{ fill: "black" }} /> */}
      </div>
    )
}

const Timer = ({ eventTime, deliveryTime }) => {
  const [time, setTime] = useState(0);
  const [color, setColor] = useState('#79DBA7');

  const updateTime = () => {
    const currentTime = Date.now();
    const diffTime = currentTime - eventTime;
    const minutes = Math.floor(diffTime / 60000);
    const duration = moment.duration({
      hours: 0,
      minutes,
      seconds: parseInt(((diffTime % 60000) / 1000).toFixed(0), 10),
      milliseconds: 0,
    });

    if (minutes > 9 && minutes < 20) setColor('#F3D733');
    if (minutes >= 20) setColor('#D90100');

    setTime((): any => {
      const newDuration = duration.add(1, 'seconds');
      const second = newDuration.seconds() > 9
        ? newDuration.seconds()
        : `0${newDuration.seconds()}`;

      return `${newDuration.minutes()}:${second}`;
    });
  };

  useEffect(() => {
    if (eventTime < Date.now()) {
      let id;
      if (!deliveryTime) {
        id = window.setInterval(updateTime, 1000);
      }
      return () => clearInterval(id);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Time color={color}>
      <p key={time} style={{ color: 'white' }}>{time}</p>
    </Time>
  );
};

export default ItemOrder;
