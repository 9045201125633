/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { find, map, get } from 'lodash';
import styled from 'styled-components';
// context
import { ContributionContext } from 'src/cards/edit/components/context/contribution';
import { ThemeContext } from 'src/cards/edit/components/context/theme';

import EditingItem from '../item';
import { PositionDropTarget, InitialDropTarget } from '../../../dragAndDrop/index';
import { TOP, BOTTOM } from '../../../dragAndDrop/helpers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  height: ${(props) => (props.template.name !== 'Vitrine' ? '95%' : '74%')};
  ::-webkit-scrollbar {
    display: none;
  }
`;

const MarginBottom = styled.div`
  padding: 6vh;
`;

export const Components = () => {
  const {
    cardData,
    currentCategory,
    template,
    action: { moveComponent },
  }: any = useContext(ContributionContext);

  const { theme }: any = useContext(ThemeContext);

  const value = find(cardData, (c) => c.id === currentCategory);
  const components = get(value, 'components');

  return (
    <Wrapper theme={theme} template={template}>
      {(!components || components.length === 0) && <InitialDropTarget />}
      {map(components, (item, index) => (
        <div key={index}>
          <div>
            <PositionDropTarget
              isLast={false}
              direction={TOP}
              id={item.id}
              position={Math.max(0, Number(index))}
            />
            <EditingItem
              item={item}
              moveComponent={moveComponent}
              position={index}
            />
            <PositionDropTarget
              direction={BOTTOM}
              id={item.id}
              position={index + 1}
              isLast={components.length === index + 1}
            />
          </div>
        </div>
      ))}
      <MarginBottom />
    </Wrapper>
  );
};
