import React, { useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { Notification } from 'react-admin';
// eslint-disable-next-line
import { Location } from 'history';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
  width: 90%;
`;

const Input = styled.div`
  margin-top: 1em;
  height: 5em;
`;

const ImgWrapper = styled.img`
  width: 20em;
  padding: 1em;
`;

const useStyles: any = makeStyles((theme) => ({
  card: {
    width: '45vw',
    maxWidth: '42em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    boxShadow: '0px 2px 1px 1px rgba(0,0,0,0.1), 0px 2px 1px 0px rgba(0,0,0,0.14), 1px 0px 11px 0px rgba(0,0,0,0.12)',
    padding: '1em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
  },
}));


const ValidationTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#5bfcc2',
      },
      '&:hover fieldset': {
        borderColor: '#cacaca',
        color: '#cacaca',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#5bfcc2',
      },
    },
    '& label.Mui-focused': {
      color: '#222428',
    },
    '& input': {
      color: '#222428',
    },
    '& input:valid + fieldset': {
      borderColor: '#5bfcc2',
      borderWidth: 2,
      color: '#222428',
    },
    '& label': {
      color: '#222428',
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderColor: '#5bfcc2',
      padding: '4px !important', // override inline-style
    },
  },
})(TextField);

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <ValidationTextField
    error={!!(touched && error)}
    helperText={touched && error}
    variant="outlined"
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  username?: string;
  password?: string;
}

const { Form } = withTypes<FormValues>();

const Login = ({ location }: { location: Location }) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    const loc: any = location;
    login(auth, loc.state ? loc.state.nextPathname : '/').catch(
      (error: Error) => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
          'warning',
        );
      },
    );
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <Container>
            <ImgWrapper src="/img/kards/logo.svg" alt="logo" />
            <Typography style={{
              textAlign: 'center', fontSize: '1.5em', fontWeight: 'bold', color: '#222428', padding: '1em',
            }}
            >
              {'L\'application qui digitalise vos cartes.'}
            </Typography>
            <Card className={classes.card}>
              <Box>
                <Typography style={{ textAlign: 'center', fontSize: '1.2em', color: '#222428' }}>
                  Identifiez-vous
                </Typography>
              </Box>
              <ContainerInput>
                <Input>
                  <Field
                    autoFocus
                    name="username"
                    data-cy="usermail"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('ra.auth.username')}
                    disabled={loading}
                  />
                </Input>
                <Input>
                  <Field
                    name="password"
                    data-cy="password"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('ra.auth.password')}
                    type="password"
                    disabled={loading}
                  />
                </Input>
              </ContainerInput>
              <CardActions>
                <Button
                  variant="contained"
                  type="submit"
                  data-cy="submit_login"
                  style={{ backgroundColor: '#222428', color: 'white' }}
                  disabled={loading}
                  fullWidth
                >
                  {translate('ra.auth.sign_in')}
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </Container>
        </form>
      )}
    />
  );
};


const LoginWithTheme = (props: any) => <Login {...props} />;

export default LoginWithTheme;
