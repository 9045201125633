import React from 'react';
import {
  View, Document, Image,
} from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import get from 'lodash/get';
import map from 'lodash/map';
import moment from 'moment';
import Dinero from 'dinero.js';

const PageWrapper = styled.Page`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
`;

const Title = styled.Text`
  font-size: 25px;
  padding: 20px;
`;

const Title2 = styled.Text`
  font-size: 18px;
  padding: 10px;
`;

const SmallText = styled.Text`
  font-size: 12px;
`;

const TextInfoTitle = styled.Text`
  font-size: 20px;
  color: #5BFCC2;
`;

const TextInfo = styled.Text`
  font-size: 14px;
`;

const ImgContainer = styled.View`
  width: 100px;
  height: 100px;
  padding: 10px;
`;

const Section = styled.View`
  margin: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SectionInfo = styled.View`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1pt solid #5BFCC2;
  padding-bottom: 10px;
`;

const SectionTotal = styled.View`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1pt solid #5BFCC2;
`;

const RowTitleProduct = styled.Text`
  flex: ${(props) => props.flex};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #5BFCC2;
`;

const RowProduct = styled.Text`
  flex: ${(props) => props.flex};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

const RowTotalTitle = styled.Text`
  flex: ${(props) => props.flex};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  color: #5BFCC2;
`;

const RowTotal = styled.Text`
  flex: ${(props) => props.flex};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const orderingMode = {
  delivered: 'À emporter',
  onplace: 'Sur place',
};

export const getQuantity = (item) => (item.quantity ? item.quantity : item.number);

export const PDfOrderBilling = ({ order }) => {
  console.log('Log: PDfOrderBilling -> order', order)
  let TotalTVA05 = 0;
  let TotalTVA10 = 0;
  let TotalTVA20 = 0;
  let TotalTTC = 0;
  const logo = get(window, 'kardsConfiguration.headerLogo');
  return (
    <Document>
      <PageWrapper size="A5">
        <Section>
          {logo
            && (
              <ImgContainer>
                <Image src={get(window, 'kardsConfiguration.headerLogo')} />
              </ImgContainer>
            )}
          <Title>{get(window, 'kardsConfiguration.name')}</Title>
        </Section>
        <SectionInfo>
          <View style={{ paddingLeft: '10px' }}>
            <TextInfoTitle>
              N° facture: &nbsp;
            </TextInfoTitle>
            <TextInfo>
              {get(order, 'key')}
              &nbsp;
              -
              &nbsp;
              {orderingMode[get(order, 'parameters.delivery')]}
            </TextInfo>
          </View>
          <View style={{ paddingLeft: '10px' }}>
            <TextInfoTitle>
              N° client: &nbsp;
            </TextInfoTitle>
            <TextInfo>
              {get(order, 'userId')[0]}
            </TextInfo>
          </View>
          <View style={{ paddingLeft: '10px' }}>
            <TextInfoTitle>
              Date:&nbsp;
            </TextInfoTitle>
            <TextInfo>
              {moment(get(order, 'created_at')).format('L , H:m')}
            </TextInfo>
          </View>
        </SectionInfo>
        <Section>
          <View style={{
            width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row',
          }}
          >
            <RowTitleProduct flex=".6">Référence</RowTitleProduct>
            <RowTitleProduct flex=".2">P.U.</RowTitleProduct>
            <RowTitleProduct flex=".2">Prix</RowTitleProduct>
          </View>
          {
            map(get(order, 'items'), (item) => {
              const price = get(item, 'price');
              const tva = get(item, 'tva');
              const number = getQuantity(item);
              const total = get(item, 'price') * number

              if (price && tva && number && tva === '0.055') TotalTVA05 += (price * tva * number);
              if (price && tva && number && tva === '0.10') TotalTVA10 += (price * tva * number);
              if (price && tva && number && tva === '0.20') TotalTVA20 += (price * tva * number);
              if (price && tva && number) TotalTTC += (price * number);

              return (
                <View
                  key={get(item, 'id')}
                  style={{
                    width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row',
                  }}
                >
                  <RowProduct flex=".6">{`${number} x ${get(item, 'reference')}`}</RowProduct>
                  <RowProduct flex=".2">
                    {`${Dinero({ amount: parseInt(get(item, 'price'), 10) }).toFormat('0.00')} €`}
                  </RowProduct>
                  <RowProduct flex=".2">
                    {`${Dinero({ amount: total }).toFormat('0.00')} €`}
                  </RowProduct>
                </View>
              );
            })
          }
        </Section>
        <SectionTotal>
          <View style={{
            width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row',
          }}
          >
            <RowTotalTitle>HT</RowTotalTitle>
            <RowTotalTitle>TVA</RowTotalTitle>
            <RowTotalTitle>TTC</RowTotalTitle>
          </View>
          <View style={{
            width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row',
          }}
          >
            <RowTotal>
              {`${Dinero({ amount: TotalTTC - (TotalTVA05 + TotalTVA10 + TotalTVA20) }).toFormat('0.00')} €`}
            </RowTotal>
            <RowTotal>
              {`${Dinero({ amount: TotalTVA05 + TotalTVA10 + TotalTVA20 }).toFormat('0.00')} €`}
            </RowTotal>
            <RowTotal>
              {`${Dinero({ amount: TotalTTC }).toFormat('0.00')} €`}
            </RowTotal>
          </View>
        </SectionTotal>
        <Section>
          <Title2>
            Merci de votre visite
          </Title2>
          <SmallText>
            service by
          </SmallText>
          <ImgContainer>
            <Image src="/img/kards/logo.png" />
          </ImgContainer>
        </Section>
      </PageWrapper>
    </Document>
  );
};
