/* eslint-disable prefer-const */
import { useEffect, useState, useContext } from 'react';
import firebase from 'firebase';
import { findIndex, orderBy, groupBy, forEach } from 'lodash';
import { PreparatorContext } from 'src/preparator/context'
import { printReceipt } from "src/printers";

const countAudio = new Audio('/sounds/score.mp3');

export const useWatchPreparatorOrderCount = ({ entitieWatch }) => {
  let [count, setCount] = useState(0);

  useEffect(() => {
    // scor.src =
    const defaultFirestore = firebase.app();
    const subscribe = defaultFirestore
      .firestore()
      .collection('orders')
      .where(`status.${entitieWatch}`, '==', true)
      .orderBy('created_at', 'desc')
      .onSnapshot(
        {},
        async snapshot => {
          snapshot.docChanges().forEach(async change => {
            const doc = await change.doc.data();
            if (doc && change.type === 'added') {
              count++;
              countAudio.play();
              setCount(count);
            }
            if (doc && change.type === 'removed') {
              count--;
              setCount(count);
            }
          });
        },
        e => console.log('error', e),
      );

    return () => subscribe;
    // eslint-disable-next-line
  }, []);

  return count;
};
export const useWatchPreparatorOrder = ({ entitieWatch, low, preparing, dateToShow = null }) => {
  const [data, setData] = useState([]);
  const [dataToPrint, setDataToPrint] = useState([]);
  const { printersStatus }:any = useContext(PreparatorContext)
  const defaultFirestore = firebase.app().firestore();

  let limite = 6;
  if (low || preparing) {
    limite = 50;
  }
  const limiteDate = dateToShow || Date.now() + 4 * 60 * 60 * 1000;
  
  // check pool to print and print if needed
  useEffect(()=> {
    // chopper le tableau de commande a mettre a jour
    let promisesPrint = []
    console.log('dataToPrint', dataToPrint)
    printersStatus.forEach((p)=> {
      if(!p.error){
        dataToPrint.forEach((order)=> promisesPrint.push(printReceipt(order, p.ip, p.name)))
      }
    })
    // pour chaque commande et pour chaque commande creer une promese d 'impression 
    Promise.all(promisesPrint).then(async (prints)=> {
      console.log('prints', promisesPrint, prints)

      let printByOrderId = groupBy(prints, "orderId")
      console.log('prints', printByOrderId)
      let promises = []
      // pour chaque commande et pour chaque resultat d'impression grouper par orderId et mettre 
      // a jour l'attribut print
      forEach(printByOrderId, (async (print, orderId)=> {
        promises.push(defaultFirestore
          .collection("orders")
          .doc(orderId)
          .update({ print }))
        })
      )
      Promise.all(promises).then(async (prints)=> {
        console.log('PRINTS', prints)
      })
    })
  }, [dataToPrint, printersStatus])

  useEffect(() => {
    const defaultFirestore = firebase.app();
    const subscribe = defaultFirestore
      .firestore()
      .collection('orders')
      .where('created_at', '<', limiteDate)
      .where(`status.${entitieWatch}`, '==', true)
      .orderBy('created_at', 'desc')
      .limitToLast(limite)
      .onSnapshot(
        {},
        async snapshot => {
          let dataToPrints =[]
          snapshot.docChanges().forEach(async change => {
            const doc = await change.doc.data();
            if (doc && change.type === 'added') {
              // filter for low dashboard first onglet
              if (low && !doc.parameters.deliveryTime) {
                data.push(doc);
                if(!doc.print) dataToPrints.push(doc); 
                // doc.print?.forEach((p)=> {
                //   if(!p.printed){
                //     dataToPrints.push(doc); 
                //   }
                // })
              }
              // filter for normal dashboard
              if (!low && !preparing) {
                data.push(doc);
                if(!doc.print) dataToPrints.push(doc); 
                // doc.print?.forEach((p)=> {
                //   if(!p.printed){
                //     dataToPrints.push(p); 
                //   }
                // })              
              }
              // filter for low dashboard sec onglet
              if (preparing && doc.parameters.deliveryTime) {
                data.push(doc);
              }
            }
            if (doc && change.type === 'removed') {
              const id = findIndex(data, o => o.key === doc.key);
              data.splice(id, 1);
            }
            if (doc && change.type === 'modified') {
              const id = findIndex(data, o => o.key === doc.key);
              if (low && doc.parameters.deliveryTime) {
                data.splice(id, 1);
              } else {
                data[id] = doc;
              }
            }
            setData([...data]);
            setDataToPrint([...dataToPrints])
          });
        },
        e => console.log('error', e),
      );

    return () => subscribe;
    // eslint-disable-next-line
  }, []);
  
  if (!low) {
    const dataMap = data.map(d => {
      if (d.parameters.deliveryTime) {
        d.sortKey = parseInt(
          `${d.parameters.deliveryTime.split(':')[0]}
        ${d.parameters.deliveryTime.split(':')[1]}`,
          10,
        );
      }
      return d;
    });
    return orderBy(dataMap, ['sortKey'], ['asc']);
  }

  return data;
};
