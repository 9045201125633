import React, { useState } from 'react';
import { Box, ButtonGroup, Button, Fab, Tabs, Tab } from '@material-ui/core';
import { useQuery } from 'src/helpers/useQuery';
import { getOrdersByDate } from 'src/data/accessor/getOrders';
import { ByOrders } from './ByOrders/ByOrders';
import { ByTurnover } from './ByTurnover/ByTurnover';
import { ByProducts } from './ByProducts/ByProduct';
import { Period } from 'src/data/types';
import { DatePicker } from 'src/layout/DatePicker';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { getPeriod, getFromMinus, getToMinus, getDay, getFromPlus, getToPlus, isDisabledPlusBtn, getNow } from './utils';

export function Dashboard() {
  const { period, to, from, setTo, setFrom, selectPeriod, navigateMinus, navigateAdd } = useSelectDate();
  const { data: orders, loading } = useQuery(() => getOrdersByDate(from, to), [period, to, from]);
  const [panel, setPanel] = useState(0);

  const isSelected = (type: Period) => (period === type ? 'secondary' : 'primary');
  const disabledPlusBtn = isDisabledPlusBtn(to);

  return (
    <Box style={{ overflow: 'scroll', height: '87vh' }} pb={7} width="100%">
      <Box pt="12px">
        <Box my={1} display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
              <Button color={isSelected('days')} onClick={() => selectPeriod('days')}>
                24H
              </Button>
              <Button color={isSelected('weeks')} onClick={() => selectPeriod('weeks')}>
                7j
              </Button>
              <Button color={isSelected('months')} onClick={() => selectPeriod('months')}>
                30j
              </Button>
              <Button color={isSelected('years')} onClick={() => selectPeriod('years')}>
                Année
              </Button>
            </ButtonGroup>
          </Box>

          <Box display="flex" flexWrap="nowrap">
            <Box mr={2}>
              <DatePicker label="Date de début" value={new Date(from)} onChange={(v: Date) => v && setFrom(v.getTime())} />
            </Box>
            <DatePicker label="Date de fin" value={new Date(to)} onChange={(v: Date) => v && setTo(v.getTime())} />
          </Box>
        </Box>
      </Box>

      <Box my={1} display="flex" justifyContent="center" alignItems="center">
        <Fab color="secondary" size="small" onClick={navigateMinus} style={{ boxShadow: 'none' }}>
          <ArrowBackIosIcon fontSize="inherit" />
        </Fab>
        <Box px={2}>{getDay(to, period)}</Box>
        <Fab color="secondary" size="small" disabled={disabledPlusBtn} onClick={navigateAdd} style={{ boxShadow: 'none' }}>
          <ArrowForwardIosIcon fontSize="inherit" />
        </Fab>
      </Box>

      <Box mb={2}>
        <Tabs value={panel} onChange={(e, panelNum) => setPanel(panelNum)}>
          <Tab label="Chiffre d'affaire" />
          <Tab label="Commandes" />
          <Tab label="Produits" />
        </Tabs>
      </Box>

      {!loading && !orders?.length ? (
        <Box fontWeight="bold" fontSize="22px">
          Pas de données
        </Box>
      ) : loading && !orders?.length ? (
        <Box>...Chargement</Box>
      ) : (
        <>
          {panel === 0 && <ByTurnover orders={orders} period={period} />}
          {panel === 1 && <ByOrders orders={orders} period={period} />}
          {panel === 2 && <ByProducts orders={orders} />}
        </>
      )}
    </Box>
  );
}

function useSelectDate() {
  const [period, setPeriod] = useState<Period>('weeks');
  const [to, setTo] = useState<number>(getNow());
  const [from, setFrom] = useState<number>(getPeriod(period));
  const [count, setCount] = useState(0);

  const selectPeriod = (p: Period) => {
    setFrom(getPeriod(p));
    setTo(getNow());
    setPeriod(p);
    setCount(0);
  };

  const navigateMinus = () => {
    setFrom(getFromMinus({ count, period }));
    setTo(getToMinus({ count, period }));
    setCount(c => c - 1);
  };

  const navigateAdd = () => {
    setFrom(getFromPlus({ count, period }));
    setTo(getToPlus({ count, period }));
    setCount(c => c + 1);
  };

  return {
    period,
    to,
    from,
    setTo,
    setFrom,
    selectPeriod,
    navigateMinus,
    navigateAdd,
  };
}
