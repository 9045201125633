import React, { useContext } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Offer } from 'kards-components';

import { usePopulateOffer } from 'src/data/hooks';
import { ThemeContext } from 'src/cards/edit/components/context/theme';

export default ({
  data: {
    value: { offer, title, description },
  },
}) => {
  const {
    theme,
  }:any = useContext(ThemeContext);
  const { data }:any = usePopulateOffer({ offer });
  if (!offer || data.length === 0) {
    return (
      <div style={{ margin: '10px' }}>
        <Skeleton variant="rect" width="100%" height={250} />
        <Skeleton width="50%" />
        <Skeleton width="60%" />
      </div>
    );
  }

  return (
    <Offer
      read={false}
      theme={theme}
      name={data.reference}
      tva={data.tva}
      stock={null}
      children={null}
      settingFields={data.settingFields}
      id={data.id}
      picture={data.picture}
      description={data.description}
      price={data.price}
      width="100%"
    />
  );
};
