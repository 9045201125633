import { flatMap, sum, uniq, map, forEach } from 'lodash';
import Dinero from 'dinero.js';
import { Order, Basket, PaymentType } from 'src/data/types';

export const getQuantity = (item) => (item.quantity ? item.quantity : item.number);

export function getNbClient(orders: Order[]): number {
  return uniq(map(orders, (o: Order) => o.userId)).length;
}

export function getNbProducts(orders: Order[]): number {
  return sum(flatMap(orders, (order: Order) => {
    if(Array.isArray( order.items)) return order.items
    return Object.values(order.items)
  }).map((basket: Basket) => getQuantity(basket)));
}

// function de retrocompatibilité de data
export function getOrderTotalPrice(order: Order) {
  if (order?.totalPrice) {
    return (order?.totalPrice)
  } else {
    let total = 0
    forEach(order?.items, (item) => {
      total += (item.price * getQuantity(item))
    })
    return total
  }
}

export function getTotalPrice(orders: Order[]): number {
  return sumAmount(map(orders, order => {
    return getOrderTotalPrice(order)
  }).filter(Boolean));
}

export function formatToEuro(amount: number): string {
  if (!amount) return '0 €';
  return Dinero({ amount, currency: 'EUR' })
    .setLocale('fr-FR')
    .toFormat();
}

export function getAverageBasket(orders: Order[]): number {
  return Math.round(getTotalPrice(orders) / orders?.length);
}

export function getAverageProduct(orders: Order[]): number {
  return Math.round(getNbProducts(orders) / orders?.length);
}

export function getTotalPriceHT(orders: Order[]) {
  const baskets: Order['items'] = flatMap(orders, (o: Order) => Object.values(o.items));

  const priceHTByProducts = baskets.map(basket => {
    const price = Math.floor(basket.price)
    if (!basket || !Number.isInteger(price)) return 0

    const tva = Dinero({ amount: price, currency: 'EUR' }).percentage((Number(basket.tva) || 0) * 100);

    return Dinero({ amount: price, currency: 'EUR' })
      .subtract(tva)
      .multiply(getQuantity(basket))
      .getAmount();
  });
  return sumAmount(priceHTByProducts);
}

export function sumAmount(items: number[]) {
  let total = Dinero({ amount: 0, currency: 'EUR' });
  for (const item of items) {
    if (Number.isInteger(item)) {
      total = total.add(Dinero({ amount: item, currency: 'EUR' }));
    }
  }
  return total.getAmount();
}

export function getTotalPriceByPayment(orders: Order[], payment: PaymentType): number {
  return sumAmount(
    orders
      .filter(order => order?.parameters?.payment === payment)
      .map(order => getOrderTotalPrice(order))
      .filter(Boolean),
  );
}

export function getNbOrdersByPayment(orders: Order[], payment: PaymentType): number {
  return orders.filter(order => order?.parameters?.payment === payment)?.length;
}
