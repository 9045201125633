import React, { createContext, useState, useEffect } from 'react';
import firebase from 'firebase';
import { usePrinters } from 'src/data/hooks';
import { connectEpson } from 'src/printers'

export const PreparatorContext = createContext({});

const updatePWA = (value) => {
  return firebase
    .firestore()
    .collection('application')
    .doc('pwa')
    .update(value);
};
  
const getPWA = async () => {
  const data = await firebase
    .firestore()
    .collection('application')
    .doc('pwa')
    .get();
  return data.data();
};

function PreparatorProvider({
  children
}) {
    const [printersStatus, setPrintersStatus] = useState([]);
    const [start, setStart] = useState();
    const [compiledData, setCompiledData] = useState({});
    const [loading, setLoading] = useState(true);
    const { data:printers } = usePrinters();
    const [printerLoading, setPrinterLoading ] = useState(false);

    // connect printers 
    useEffect(()=> {
      if(printers.length > 0){
        setPrinterLoading(true)
        connectEpson(printers)
        .then((status:any)=> {
          setPrintersStatus(status)
          setPrinterLoading(false)
        })
      }
    }, [printers])
  
    useEffect(() => {
      getPWA().then((data) => {
        setCompiledData(data?.compiledData);
        setStart(data?.start);
        setLoading(false);
      });
    }, []);
  
    const value= {
      compiledData: compiledData,
      loadingPwa: loading,
      printers: printers,
      printersStatus,
      setPrintersStatus,
      printerLoading,
      updatePWA,
      start
    }
    return (
        <PreparatorContext.Provider value={value}>
          {children}
        </PreparatorContext.Provider>
      );
    }
export default PreparatorProvider;
  