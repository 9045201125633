import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

export const useApplication = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const dataProvider = useDataProvider();
  useEffect(() => {
    setLoading(true);
    dataProvider
      .getOne('application', { id: 'pwa' })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  return {
    data,
    loading,
    error,
    setData,
  };
};

export const usePrinters = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const dataProvider = useDataProvider();
  useEffect(() => {
    setLoading(true);
    dataProvider
      .getOne('application', { id: 'printers' })
      .then(({ data }) => {
        setData(data?.printers)
        setLoading(false)
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  return {
    data,
    loading,
    error,
  };
};

export const usePopulateOffer = ({ offer }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const dataProvider = useDataProvider();
  useEffect(() => {
    if (offer) {
      setLoading(true);
      dataProvider
        .getOne('offer', {
          id: offer,
        })
        .then(({ data }) => {
          setData(data);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          setError(error);
          setLoading(false);
        });
    }
  }, [dataProvider, offer]);

  return {
    data,
    loading,
    error,
    setData,
  };
};

export const usePopulateProducts = ({ products }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const dataProvider = useDataProvider();
  useEffect(() => {
    if (products) {
      setLoading(true);
      dataProvider
        .getMany('products', {
          ids: products,
        })
        .then(({ data }) => {
          data.filter(d => d.price != null);
          setData(data.filter(d => d.price != null));
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          setError(error);
          setLoading(false);
        });
    }
  }, [dataProvider, products]);

  return {
    data,
    loading,
    error,
    setData,
  };
};

export const usePopulateProduct = ({ id, product }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const dataProvider = useDataProvider();
  useEffect(() => {
    if (product) {
      setLoading(true);
      dataProvider
        .getOne('products', {
          id: product,
        })
        .then(({ data }) => {
          if (data.price) {
            setData(data);
          }
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    }
  }, [product]);

  return {
    data,
    loading,
    error,
    setData,
  };
};
