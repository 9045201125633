import { flatMap, filter, pipe, map } from 'lodash/fp';
import firebase from 'firebase';
import { useQuery } from 'src/helpers/useQuery';

type Product = {
  id: string;
  reference: string;
  tag: string;
  description: string;
  picture?: string;
  price: number;
  tva: number;
  lastupdate: Date;
  createdby: string;
  updatedby: string;
  stock?: {
    stockNumber: string;
    unlimited: boolean;
  };
  available?: boolean;
  preorder?: boolean;
  instant?: boolean;
  productIdCashRegister?: number;
  syncWithCashRegister?: boolean;
  contractors?: {
    id: string;
    value: string;
  }[];
};

function extractProducts(componentsDisplays: any[] = []): Product[] {
  const products: Product[] = pipe(
    flatMap((c: any) => c.components),
    filter(c => c.type === 'product' || c.type === 'offer'),
    map(c => ({ ...c.value, type: c.type })),
  )(componentsDisplays);

  const productsFromList: Product[] = pipe(
    flatMap((c: any) => c.components),
    filter(c => c.type === 'simpleList' || c.type === 'carousel'),
    flatMap(c => c.value.products.map(p => ({ ...p, type: 'product' }))),
  )(componentsDisplays);

  return [...products, ...productsFromList];
}

function getPWA() {
  return firebase
    .app()
    .firestore()
    .collection('application')
    .doc('pwa')
    .get()
    .then(doc => doc.data());
}

export function useGetProductsInCard() {
  const { data: pwa, loading, error } = useQuery(() => getPWA());

  const mode = pwa?.compiledData?.order_mode === 'noOrder' ? 'instant' : pwa?.compiledData?.order_mode;
  const card = pwa?.compiledData?.[mode];
  const products = extractProducts(card);

  return { products, loading, error };
}
