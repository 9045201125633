import React from 'react';
import { Box } from '@material-ui/core';
import { map, sortBy, groupBy, sum, filter } from 'lodash';
import Dinero from 'dinero.js';
import moment from 'moment';
import { GraphLine } from '../components/GraphLine';
import { Order, Period } from 'src/data/types';
import { Card } from '../components/Card';
import { formatToEuro, getTotalPrice, getTotalPriceHT, getTotalPriceByPayment, getOrderTotalPrice } from '../Infos/Infos';

type ByTurnoverProps = {
  orders: Order[];
  period: Period;
};

export function ByTurnover({ orders, period }: ByTurnoverProps) {
  const graphData = getTurnoverByTimeByPaymentType(orders, period);

  return (
    <Box display="flex" height="550px">
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Card mb={1} title="CA Total TTC" value={formatToEuro(getTotalPrice(orders))} />
        <Card mb={1} title="CA HT" value={formatToEuro(getTotalPriceHT(orders))} />
        <Card mb={1} title="CB" value={formatToEuro(getTotalPriceByPayment(orders, 'card'))} />
        <Card mb={1} title="Apple Pay" value={formatToEuro(getTotalPriceByPayment(orders, 'applePay'))} />
        <Card title="Google Pay" value={formatToEuro(getTotalPriceByPayment(orders, 'googlePay'))} />
      </Box>
      <Box width="100%" height="550px">
        <GraphLine data={graphData} legendX="temps" legendY="CA en euro" />
      </Box>
    </Box>
  );
}

function getTurnoverByTimeByPaymentType(orders: Order[], period: Period) {
  const filterByTotalPrice = filter(orders, (o: Order) => o ? getOrderTotalPrice(o) : null);
  const sortOrders = sortBy(filterByTotalPrice, 'created_at');
  const formatDate = { days: 'HH', weeks: 'DD - dd', months: 'DD/MM', years: 'MM/YYYY' }[period];
  const prefix = period === 'days' ? 'H' : '';

  const mapXY = map(sortOrders, (order: Order) => ({
    x: moment(order.created_at).format(formatDate) + prefix,
    y: getOrderTotalPrice(order),
    paymentType: order?.parameters?.payment,
  }));
  const groupByX = groupBy(mapXY, 'x');

  const getData = (byPaymentType: string) =>
    map(groupByX, (values, time) => {
      const valuesByType = byPaymentType === 'total' ? values : values.filter(v => v.paymentType === byPaymentType);
      const amount = sum(valuesByType.map(v => v.y));
      if (!Number.isInteger(amount)) return
      return {
        x: time,
        y: Dinero({ amount, currency: 'EUR' }).toRoundedUnit(0, 'HALF_EVEN'),
      };
    });

  return ['googlePay', 'applePay', 'card', 'total'].map(payment => ({
    id: payment,
    data: getData(payment),
  }));
}
