import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Fab from '@material-ui/core/Fab';
import { useLogout } from 'react-admin';
import { useWatchPreparatorOrderCount } from 'src/data/hooks/watchPreparatorOrders';
import { LoadingPreparator } from 'src/layout/List/Loading';
import { LogoutButtonConfirme } from 'src/layout/Buttons';
import { get } from 'lodash'
import SearchOrder from '../components/search';
import PreparatorView from '../components/Preparator';
import { AverageTime } from '../components/averageTime';
import { OnFire } from '../components/onFire';
import { PrintersListParams } from '../components/PrintersModal';
import { Switch } from '../components/switch';
import PreparatorProvider, { PreparatorContext } from 'src/preparator/context'

const MainPreparator = styled.div`
  display: flex;
  height: 100vh;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 84vh;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
`;

const Logo = styled.img`
  padding: 1em 1em 0em 1em;
  width: 6em;
`;

const Text = styled.div`
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  font-family: ${(props) => props.family};
  padding: ${(props) => props.padding};
  padding-bottom: ${(props) => props.paddingBottom};
  white-space: ${(props) => props.whiteSpace};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.lettreSpacing};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemSideBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainScreen = styled.div`
  flex: 0.9;
  background: #222428;
`;

const SideBar = styled.div`
  flex: 0.1;
  display: flex;
  background: #5bfcc2;
  color: white;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Round = styled.div`
  background: #222428;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 7vh;
  height: 7vh;
  border-radius: 50%;
  font-size: 1.6em;
  font-weight: bold;
`;

const useStyles = makeStyles(() => ({
  logout: {
    background: '#6da993',
    color: 'white',
    boxShadow: 'none',
    width: '4em',
    height: '4em',
  },
  totalNumber: {
    background: '#222428',
    color: 'white',
    boxShadow: 'none',
    width: '6em',
    height: '6em',
  },
}));

const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

const StyledTabs = withStyles({
  root: {

  },
  indicator: {
    backgroundColor: '#5bfcc2',
  },
})(Tabs);

const StyledTab = withStyles(() => ({
  root: {
    margin: '0.5em 0',
    textTransform: 'none',
    minWidth: 72,
    fontSize: '1em',
    letterSpacing: '1px',
    color: 'white',
    '&$selected': {
      color: '#5bfcc2',
    },
    '&:focus': {
      color: '#5bfcc2',
    },
  },
  selected: {},
}))<any>((props) => <Tab disableRipple {...props} />);

const RenderLogout = () => {
  const logout = useLogout();
  const classes = useStyles();
  const handleClick = () => logout();
  return (
    <LogoutButtonConfirme
      onAccept={handleClick}
      OpenButton={(props) => (
        <ItemSideBar>
          <Fab
            className={classes.logout}
            onClick={props.handleOpen}
          >
            <PowerSettingsNewIcon style={{ width: '1.3em', height: '1.3em' }} />
          </Fab>
        </ItemSideBar>
      )}
    />
  );
};

export const RenderActiveCard = ({ updatePWA, start }) => {
  const [status, setStatus ] = useState(start)
  useEffect(()=> {
    setStatus(start)
  }, [start])
  return (
    <ItemSideBar>
      <Switch
        key={`${status}`}
        size="medium"
        checked={status}
        onChange={async () => {
          await updatePWA({ start: !status })
          setStatus(!status)
        }}
      />
      <Text color="black" size=".9em" weight="400" textAlign="center" width="70%" padding=".3em" lineHeight="1em">
          {status ? 'Ouvrir' : 'Fermer'} établissement
        </Text>
    </ItemSideBar>
  )
}

const RenderTotalNumber = () => {
  const count = useWatchPreparatorOrderCount({ entitieWatch: 'S2_S3' });
  return (
    <ItemSideBar>
      <Round>
        {count}
      </Round>
      <Text color="black" size=".9em" weight="400" textAlign="center" width="70%" padding=".3em" lineHeight="1em">
        Commandes en cours
      </Text>
    </ItemSideBar>
  );
};

const RenderLogo = () => (
  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    <Logo src="/img/kards/logo.svg" alt="kards" />
    <Text color="black" size=".6em" weight="400" textAlign="center" width="60%" padding=".3em" lettreSpacing="1px">
      PRÉPARATEUR
    </Text>
  </Box>
);

const List = (props) => {
  const [ value, setValue ] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { compiledData, updatePWA, loading, start, printers }:any = useContext(PreparatorContext)
  if (loading) return <LoadingPreparator {...props} />;

  return (
    <MainPreparator>
      {get(compiledData, 'debit_mode') ?
        (get(compiledData, 'debit_mode') === 'low' ?
          <LowDashBoard handleChange={handleChange} value={value} /> :
          <HightDashBoard handleChange={handleChange} value={value} />
        ) :
        <div style={{ flex: 0.9, background: '#222428' }} />
      }
      <SideBar>
        <Box display="flex" flex=".6" justifyContent="space-between" alignItems="center" flexDirection="column">
          <RenderLogo />
          <span style={{ color: 'black' }}>{get(window, 'kardsConfiguration.name')}</span>
          <RenderActiveCard updatePWA={updatePWA} start={start} />
          <RenderTotalNumber />
          <AverageTime onAccept={averageTime => updatePWA(averageTime)} />
          <OnFire onAccept={onFire => updatePWA({ onFire })} />
          <PrintersListParams readonly />
        </Box>
        <Box display="flex" flex=".1" justifyContent="center" alignItems="center">
          <RenderLogout />
        </Box>
      </SideBar>
    </MainPreparator>
  );
};

const PreparatorList = (props) => <PreparatorProvider><List {...props} /></PreparatorProvider>;

const HightDashBoard = ({ value, handleChange }) => (
  <MainScreen>
    <StyledTabs value={value} onChange={handleChange} variant="fullWidth">
      <StyledTab label="EN PREPARATION" data-cy="btn-tab-to-do" />
      <StyledTab label="À LIVRER" data-cy="btn-tab-to-delivery" />
      <StyledTab label="RECHERCHER" data-cy="btn-tab-search" />
    </StyledTabs>
    <TabPanel value={value} index={0}>
      <ListWrapper data-cy="list-orders-to-do">
        <PreparatorView entitieWatch="S2_S3" />
      </ListWrapper>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <ListWrapper data-cy="list-orders-to-delivery">
        <PreparatorView entitieWatch="S4" />
      </ListWrapper>
    </TabPanel>
    <TabPanel value={value} index={2}>
      <ListWrapper data-cy="list-orders-to-delivery">
        <SearchOrder />
      </ListWrapper>
    </TabPanel>
  </MainScreen>
);

const LowDashBoard = ({ value, handleChange }) => (
  <MainScreen>
    <StyledTabs value={value} onChange={handleChange} variant="fullWidth">
      <StyledTab label="A TRAITER" data-cy="btn-tab-to-treat" />
      <StyledTab label="EN PREPARATION" data-cy="btn-tab-to-do" />
      <StyledTab label="À LIVRER" data-cy="btn-tab-to-delivery" />
      <StyledTab label="RECHERCHER" data-cy="btn-tab-search" />
    </StyledTabs>
    <TabPanel value={value} index={0}>
      <ListWrapper data-cy="list-orders-to-add-time">
        <PreparatorView entitieWatch="S2_S3" low />
      </ListWrapper>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <ListWrapper data-cy="list-orders-to-do">
        <PreparatorView entitieWatch="S2_S3" preparing />
      </ListWrapper>
    </TabPanel>
    <TabPanel value={value} index={2}>
      <ListWrapper data-cy="list-orders-to-delivery">
        <PreparatorView entitieWatch="S4" />
      </ListWrapper>
    </TabPanel>
    <TabPanel value={value} index={3}>
      <ListWrapper data-cy="list-orders-to-do">
        <SearchOrder />
      </ListWrapper>
    </TabPanel>
  </MainScreen>
);

export default PreparatorList;
