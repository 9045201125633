import React, { useContext } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Video } from 'kards-components';
import { ThemeContext } from 'src/cards/edit/components/context/theme';

export default ({
  data: {
    title,
    description,
    video,
  },
}) => {
  const {
    theme,
  }: any = useContext(ThemeContext);
  if (!video) {
    return (
      <div style={{ margin: '10px' }}>
        <Skeleton variant="rect" width="100%" height={250} />
        <Skeleton width="50%" />
        <Skeleton width="60%" />
      </div>
    );
  }
  return (
    <Video
      theme={theme}
      title={title}
      description={description}
      video={video}
    />
  );
};
