import React from 'react';
import { Box, Fab } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export function DayNavigation({ onLess, onMore, children, ...props }) {
  return (
    <Box mt={1} mb={2} display="flex" justifyContent="center" alignItems="center" id="zcaisse-nav-interval" {...props}>
      <Fab color="secondary" size="small" onClick={onLess} style={{ boxShadow: 'none' }}>
        <ArrowBackIosIcon fontSize="inherit" />
      </Fab>
      <Box px={3}>{children}</Box>
      <Fab color="secondary" size="small" onClick={onMore} style={{ boxShadow: 'none' }}>
        <ArrowForwardIosIcon fontSize="inherit" />
      </Fab>
    </Box>
  );
}
