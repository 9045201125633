import React from 'react';
import { getCashRegister, CashRegisterType } from 'src/data/accessor/cashRegister';
import { useFirebaseSnapshot } from 'src/helpers/useFireabseOnSnapshop';
import { ChooseCashRegister } from 'src/cash-register/ChooseCashRegister';
import { FormCashRegisterInfos } from './FormCashRegisterInfos';
import { ImportInProgress } from './ImportInProgress';

export function AskForCashRegister({ children }:any) {
  const { data: cashRegister, loading } = useFirebaseSnapshot<CashRegisterType>(getCashRegister);

  if (loading) return <>{children}</>;

  switch (cashRegister?.step) {
    case 'form-infos':
      return <FormCashRegisterInfos cashRegister={cashRegister} />;
    case 'import-in-progress':
      return <ImportInProgress>{children}</ImportInProgress>;
    case 'done':
      return <>{children}</>;
    default:
      return <ChooseCashRegister />;
  }
}
