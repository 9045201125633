import CategoriesIcon from '@material-ui/icons/Category';

import CategoriesList from './list';
import CategoriesCreate from './create';

export default {
  list: CategoriesList,
  create: CategoriesCreate,
  icon: CategoriesIcon,
};
