import React from 'react';
import moment from 'moment';
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';
import { getCA, getTVA, getStatistics, getDetails, getTurnoverByProducts, getDisplayFrom } from './utils';
import { Order } from 'src/data/types';
import RobotoFont from './font/Roboto-Regular.ttf';

Font.register({ family: 'Roboto', src: RobotoFont });

type ZcaissePDFProps = {
  orders: Order[];
  infos: {
    logo: string;
    phone: string;
  };
  from: number;
  to: number;
};

export function ZcaissePDF({ orders, infos, from, to }: ZcaissePDFProps) {
  return (
    <Document>
      <Page wrap={false}>
        <PageWrapper>
          <Container>
            {infos?.logo && <Image src={infos?.logo} style={{ margin: 10, width: 250 }} />}
            <Text style={{ color: '#285b49', marginTop: 10 }}>{infos?.phone}</Text>
          </Container>
          <Divider />

          <Container>
            <Title>Z de caisse</Title>
            <Title>{getDisplayFrom(from)}</Title>
            <Title>{getDisplayFrom(to)}</Title>
          </Container>
          <Divider />

          <Container>
            <Title>Effectué le {moment().format('DD/MM/YYYY')}</Title>
          </Container>

          <Table title="CA" data={getCA(orders)} />
          <Table title="TVA" data={getTVA(orders)} />
          <Table title="Statistiques" data={getStatistics(orders)} />
        </PageWrapper>
      </Page>
      <Page>
        <PageWrapper>
          <Table title="Détails des tickets" data={getDetails(orders)} />
          <Table title="Produits par CA" data={getTurnoverByProducts(orders)} />
        </PageWrapper>
      </Page>
    </Document>
  );
}

function Table({ data, title }: { data: (string | number)[][]; title: string }) {
  return (
    <View style={{ border: '1 solid black', margin: '10 0' }}>
      <Text style={{ color: '#285b49', fontFamily: 'Roboto', fontSize: 18, margin: 5 }}>{title}</Text>
      <View>
        {data.map(column => (
          <View style={{ display: 'flex', flexDirection: 'row', borderBottom: '1 solid #e0e0e0' }}>
            {column.map(row => (
              <Text
                style={{
                  width: `${Math.round(100 / column.length)}%`,
                  padding: '0 5pt',
                  fontFamily: 'Roboto',
                  fontSize: 14,
                  color: 'rgb(17, 36, 20)',
                }}
              >
                {row}
              </Text>
            ))}
          </View>
        ))}
      </View>
    </View>
  );
}

function Divider() {
  return <View style={{ width: '100%', borderBottom: '1 solid black' }} />;
}

function Title(props) {
  return <Text style={{ fontFamily: 'Roboto', fontSize: 14, fontWeight: 700, color: '#285b49' }} {...props} />;
}

function Container(props) {
  return (
    <View
      style={{
        margin: 5,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}
    />
  );
}

function PageWrapper({ children }) {
  return (
    <View style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <View style={{ width: 500 }}>{children}</View>
    </View>
  );
}
