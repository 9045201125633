import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { setCashRegister, CashRegisterType } from 'src/data/accessor/cashRegister';
import { TillerLogo, ZeltyLogo } from './Logo';

export function ChooseCashRegister() {
  const translate = useTranslate();

  const handleClick = (cashRegister: Partial<CashRegisterType>) => () => {
    setCashRegister(cashRegister);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box my={2} display="flex" justifyContent="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="1.2em"
          fontWeight="400"
          maxWidth="1000px"
        >
          {translate('cashRegister.choiseTitle')}
        </Box>
      </Box>
      <Box p={2} display="flex" justifyContent="space-around" flexWrap="wrap">
        <ButtonCashRegister
          bgcolor="black"
          onClick={handleClick({ name: 'tiller', step: 'form-infos' })}
        >
          <TillerLogo />
        </ButtonCashRegister>
        <ButtonCashRegister
          bgcolor="#193e34"
          onClick={handleClick({ name: 'zelty', step: 'form-infos' })}
        >
          <ZeltyLogo />
        </ButtonCashRegister>
        <ButtonCashRegister
          bgcolor="white"
          border="1px solid black"
          color="black"
          textAlign="center"
          onClick={handleClick({ step: 'done', name: 'no-cash-registery' })}
        >
          <Typography variant="body1">{translate('cashRegister.noCashRegister')}</Typography>
        </ButtonCashRegister>
      </Box>
    </Box>
  );
}

export function ButtonCashRegister(props) {
  return (
    <Box
      width="200px"
      height="200px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      boxShadow="0px 2px 8px 0px rgba(0,0,0,0.5)"
      mb={2}
      css={{
        cursor: 'pointer',
      }}
      {...props}
    />
  );
}
