import React, { useContext } from 'react';
import { Skeleton } from '@material-ui/lab';
import { WheelComponent } from 'kards-components';
import { ThemeContext } from 'src/cards/edit/components/context/theme';

export default ({
  data: {
    title,
    description,
    titleModal,
  },
}) => {
  const {
    theme,
  }: any = useContext(ThemeContext);
  if (!title) {
    return (
      <div style={{ margin: '10px' }}>
        <Skeleton variant="circle" width="100%" height={250} />
      </div>
    );
  }
  return (
    <WheelComponent
      theme={theme}
      data={{ title, description, titleModal }}
    />
  );
};
