import firebase from 'firebase/app';
import { flatMap, uniq, map, find } from 'lodash';
import { useQuery } from 'src/helpers/useQuery';
import { useGetProductsInCard } from './useGetProductsInCard';

function getLastOrders() {
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .orderBy('created_at', 'desc')
    .limitToLast(10)
    .get()
    .then(q => {
      let result = [];
      q.forEach(d => {
        result.push(d.data());
      });
      return result;
    });
}

function getLastProductsId(orders) {
  return uniq(
    map(
      flatMap(orders, order => order.items),
      p => p.id,
    ),
  );
}

export function useGetLastProductsOrder(nbProduct = 5) {
  const { data: orders } = useQuery(() => getLastOrders());
  const productsId = getLastProductsId(orders);
  const { products } = useGetProductsInCard();

  const productsLast = map(productsId, id => find(products, p => p.id === id))
    .filter(p => p?.id)
    .slice(0, nbProduct);
  return { productsLast };
}
