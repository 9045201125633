import React, { useState, createContext, useContext } from 'react';
import { Table as TableUI, TableBody, TableCell, TableHead, TableRow, TablePagination, TableSortLabel } from '@material-ui/core';

type TableProps<T> = {
  header?: React.ReactNode;
  data: T[];
  renderItem: (item: T) => React.ReactNode;
  sort?: string;
  sortBy?: 'asc' | 'desc';
  perPage?: number;
  showPagination?: boolean;
};

export { TableRow, TableCell };

const context = createContext(undefined as any);
const useSort = () => useContext(context);

export function Table<T>({ header, data, renderItem, sort, sortBy = 'asc', perPage = 25, showPagination = true }: TableProps<T>) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(perPage);
  const [order, setOrder] = React.useState(sortBy);
  const [orderBy, setOrderBy] = React.useState(sort);

  const handleChangePage = (event, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <context.Provider value={{ order, orderBy, setOrder, setOrderBy }}>
      <TableUI aria-label="simple table" size="small">
        <TableHead>{header}</TableHead>
        <TableBody>
          {sortByFn(data, orderBy, order)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item: T) => renderItem(item))}
        </TableBody>
      </TableUI>
      {showPagination && (
        <TablePagination
          component="div"
          labelRowsPerPage="ligne par page"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `more than ${to}`}`}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </context.Provider>
  );
}

export function Th({ ...props }: any) {
  const { order, orderBy, setOrder, setOrderBy } = useSort();

  const handleSort = (sortKey: string) => {
    const isAsc = orderBy === sortKey && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(sortKey);
  };

  return <TableCellSort order={order} orderBy={orderBy} onClick={handleSort} {...props} />;
}

function TableCellSort({ order, orderBy, sortKey, onClick, children, ...props }) {
  return (
    <TableCell sortDirection={orderBy === sortKey ? order : false} {...props}>
      <TableSortLabel active={orderBy === sortKey} direction={orderBy === sortKey ? order : 'asc'} onClick={() => onClick(sortKey)}>
        {children}
        {orderBy === sortKey ? (
          <span
            style={{
              border: 0,
              clip: 'rect(0 0 0 0)',
              height: 1,
              margin: -1,
              overflow: 'hidden',
              padding: 0,
              position: 'absolute',
              top: 20,
              width: 1,
            }}
          >
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
}

function sortByFn(array, orderBy, order) {
  return stableSort(array, getComparator(order, orderBy));
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
