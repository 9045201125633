/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';
import { FormWithRedirect } from 'react-admin';
import { Box } from '@material-ui/core';
import MainScreen from 'src/layout/Screen/Main';
import {
  TextInputField,
} from 'src/form/components/field';
import { EditToolbar } from '../bottomBar/index';

// export default ({ Toolbar, ...props }) => {
export default ({ ...props }) => {
  const [loading, setLoading] = React.useState(false);
  return (
    <FormWithRedirect
      key={props.version}
      render={() => (
        <form>
          <MainScreen
            data_cy_title="title-create-category"
            data_cy_subtitle="subtitle-create-category"
            title="Catégorie"
            subtitle="Création d'une catégorie"
            flex={1}
          >
            <TextInputField label="Nom" name="name" required data_cy_input="input-name-category" />
          </MainScreen>
          <Box
            bgcolor="primary.main"
            flex={0.05}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <EditToolbar
              record={props.record}
              id={props.id}
              basePath={props.basePath}
              resource={props.resource}
              setLoading={setLoading}
            />
          </Box>
        </form>
      )}
    />
  );
};
