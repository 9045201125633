import firebase from 'firebase/app';
import { Order } from '../types';
import { toArray } from 'src/helpers/toArray';

export async function getAnalyticsByEvent(event: string) {
  const querySnapshot = await firebase
    .app()
    .firestore()
    .collection("analytics")
    .where("type", "==", "openApplication")
    .get();
  return toArray<Order>(querySnapshot);
}
