import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useField } from 'react-final-form';

export function FieldTVA({ name, ...props }) {
  const { input } = useField(name);

  const handleChange = (e: any) => {
    input.onChange(e.target.value);
  };

  return (
    <FormControl component="fieldset" {...props}>
      <FormLabel component="legend" >TVA</FormLabel>
      <RadioGroup row aria-label="tva" name="tva" value={input.value} onChange={handleChange}>
        <FormControlLabel data-cy="btn-tva-05" value="0.055" control={<Radio />} label="TVA 5.5%" />
        <FormControlLabel data-cy="btn-tva-10" value="0.1" control={<Radio />} label="TVA 10%" />
        <FormControlLabel data-cy="btn-tva-20" value="0.2" control={<Radio />} label="TVA 20%" />
      </RadioGroup>
    </FormControl>
  );
}
