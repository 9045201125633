import React, { useState, useEffect } from 'react';
import moment from 'moment';

export function Now() {
  const now = useTime();
  return <>{now}</>;
}

function useTime(format = 'DD/MM/YYYY HH:mm') {
  const [time, setTime] = useState<string>(moment().format(format));

  useEffect(() => {
    const t = setInterval(() => {
      setTime(moment().format(format));
    }, 1000 * 60);

    return () => {
      clearInterval(t);
    };
  });

  return time;
}
