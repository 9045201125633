import React from 'react';
import MaterialModal from '@material-ui/core/Modal';
import get from 'lodash/get';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { TextInputField, CheckboxField } from 'src/form/components/field';
import { Form, useForm } from 'react-final-form';
import ButtonUpdateStock from 'src/data/injector/UpdateStockProduct';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Tooltip } from 'src/layout/Tooltip';

const ModalWrapper = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 3em;
  border-radius: 5px;
  outline: none;
  min-width: 65em;
`;

const Text = styled.div`
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  font-family: ${(props) => props.family};
  padding: ${(props) => props.padding};
  padding-bottom: ${(props) => props.paddingBottom};
  padding-top: ${(props) => props.paddingTop};
  white-space: ${(props) => props.whiteSpace};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.lettreSpacing};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'solid 1px #efefef',
    boxShadow: 'none',
  },
  button: {
    margin: theme.spacing(1),
  },
  success: {
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
    background: theme.palette.success.main,
  },
  paper: {
    width: '70%',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const Stock = ({
  onClose,
  OpenButton = null,
  product,
  openFromParent,
  setStock,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose) onClose();
    return setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
  };

  const defaultChecked = {
    monday: { midday: true, night: true },
    tuesday: { midday: true, night: true },
    wednesday: { midday: true, night: true },
    thursday: { midday: true, night: true },
    friday: { midday: true, night: true },
    saturday: { midday: true, night: true },
    sunday: { midday: true, night: true },
  };

  const defaultUnchecked = {
    monday: { midday: false, night: false },
    tuesday: { midday: false, night: false },
    wednesday: { midday: false, night: false },
    thursday: { midday: false, night: false },
    friday: { midday: false, night: false },
    saturday: { midday: false, night: false },
    sunday: { midday: false, night: false },
  };

  const CheckAll = ({ type }) => {
    const form = useForm();
    const handleChange = (e) => {
      if (e.target.checked) {
        form.mutators.setValue(type, defaultChecked);
      }
      if (!e.target.checked) {
        form.mutators.setValue(type, defaultUnchecked);
      }
    };
    return <Checkbox onChange={handleChange} color="primary" />;
  };

  return (
    <>
      {OpenButton && React.cloneElement(OpenButton, { handleOpen })}
      <MaterialModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEnforceFocus
        open={OpenButton ? open : openFromParent}
        onClose={handleClose}
        className={classes.modal}
      >
        <Box>
          <Form
            onSubmit={handleSubmit}
            initialValues={{
              instant: get(product, 'instant') || defaultChecked,
              preorder: get(product, 'preorder') || defaultUnchecked,
              disponibility: get(product, 'disponibility'),
              unlimited: get(product, 'stock.unlimited'),
              stockNumber: get(product, 'stock.stockNumber') || null,
              checkAllInstant: false,
              checkAllPreorder: false,
            }}
            validate={(values) => {
              const number = parseInt(values.stockNumber, 10);
              const errors = {};
              if (!values.unlimited) {
                if (!Number.isInteger(number)) {
                  errors.stockNumber = 'Seul les caractères numériques sont autorisés';
                } else if (number < 0) {
                  errors.stockNumber = 'Le nombre doit etre positif';
                } else if (number % 1 !== 0) {
                  errors.stockNumber = 'Le nombre doit etre entier';
                }
              }
              return errors;
            }}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            render={({
              handleSubmit, values,
            }) => (
                <ModalWrapper onSubmit={handleSubmit}>
                  <Box height="75vh" overflow="scroll" width="90%" padding="1em">
                    <Text size="1.4em" weight="400" textAlign="center">
                      Réglage du produit
                  </Text>
                    <Box
                      margin="20px 0"
                      display="flex"
                      width="100%"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                      textAlign="center"
                      borderBottom="solid #5bfcc2"
                    >
                      <Text size="1.1em" weight="400" textAlign="center" paddingBottom="1em">
                        Gestion du stock
                    </Text>
                      <CheckboxField
                        name="unlimited"
                        label="Illimité"
                        tooltip
                        tooltipText="Si activé le nombre de produits ne sera pas pris en compte"
                      />
                      <Box minHeight="6.5em">
                        {
                          values && !values.unlimited
                          && (
                            <TextInputField
                              name="stockNumber"
                              label="Nombre de produits restants"
                              tooltip
                              tooltipText="Ce nombre sera influencé par les commandes passées sur l'application client"
                              type="number"
                            />
                          )
                        }
                      </Box>
                    </Box>
                    <Box
                      margin="20px 0"
                      display="flex"
                      width="100%"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                      textAlign="center"
                      borderBottom="solid #5bfcc2"
                      pb="1.5em"
                    >
                      <Text size="1.1em" weight="400" textAlign="center" paddingBottom="1em">
                        Disponibilité du produit
                    </Text>
                      <Text size="1.1em" weight="200" textAlign="center" paddingTop="1em">
                        Sur place
                      <Tooltip text="Les produits seront disponibles immédiatement selon les créneaux renseignés" />
                      </Text>
                      <TableContainer className={classes.root} component={Paper}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="start">
                                <CheckAll type="instant" />
                              </TableCell>
                              <TableCell align="start">Lundi</TableCell>
                              <TableCell align="start">Mardi</TableCell>
                              <TableCell align="start">Mercredi</TableCell>
                              <TableCell align="start">Jeudi</TableCell>
                              <TableCell align="start">Vendredi</TableCell>
                              <TableCell align="start">Samedi</TableCell>
                              <TableCell align="start">Dimanche</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Midi
                            </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.monday.midday" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.tuesday.midday" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.wednesday.midday" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.thursday.midday" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.friday.midday" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.saturday.midday" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.sunday.midday" />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Soir
                            </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.monday.night" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.tuesday.night" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.wednesday.night" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.thursday.night" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.friday.night" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.saturday.night" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="instant.sunday.night" />
                              </TableCell>
                            </TableRow>

                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Text size="1.1em" weight="200" textAlign="center" paddingTop="1em">
                        En précommande
                      <Tooltip text="Les produits seront disponibles en précommande selon les créneaux renseignés" />
                      </Text>

                      <TableContainer className={classes.root} component={Paper}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="start">
                                <CheckAll type="preorder" />
                              </TableCell>
                              <TableCell align="start">Lundi</TableCell>
                              <TableCell align="start">Mardi</TableCell>
                              <TableCell align="start">Mercredi</TableCell>
                              <TableCell align="start">Jeudi</TableCell>
                              <TableCell align="start">Vendredi</TableCell>
                              <TableCell align="start">Samedi</TableCell>
                              <TableCell align="start">Dimanche</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Midi
                            </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.monday.midday" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.tuesday.midday" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.wednesday.midday" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.thursday.midday" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.friday.midday" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.saturday.midday" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.sunday.midday" />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Soir
                            </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.monday.night" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.tuesday.night" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.wednesday.night" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.thursday.night" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.friday.night" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.saturday.night" />
                              </TableCell>
                              <TableCell align="start">
                                <CheckboxField name="preorder.sunday.night" />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="center">
                      <ButtonUpdateStock
                        product={product}
                        classes={classes.success}
                        setStock={setStock}
                      >
                        Valider
                    </ButtonUpdateStock>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleClose}
                        data-cy="btn-close"
                      >
                        Fermer
                    </Button>
                    </Box>
                  </Box>
                </ModalWrapper>
              )}
          />
        </Box>
      </MaterialModal>
    </>
  );
};
