import React, { useContext } from 'react';
import { find, filter, get } from 'lodash';

import { ContributionContext } from 'src/cards/edit/components/context/contribution';
import EditBox from './editBoxMain';
import EditComponentBox from './editBoxComponent';

export function WindowEdit({ template }) {
  const { cardData, currentCategory, currentItem }: any = useContext(
    ContributionContext,
  );

  const value = find(cardData, (c) => c.id === currentCategory);
  const components = get(value, 'components');
  const currentLightboxItem = filter(
    components,
    (item) => item.id === currentItem,
  )[0];

  return currentLightboxItem ? (
    <EditComponentBox item={currentLightboxItem} />
  ) : (
    <EditBox template={template} />
  );
}
