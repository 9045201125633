import React from 'react';

import Edit from './Edit';

const renderEdit = ({ data }) => <Edit data={data} />;

const mostPopular = {
  icone: '/img/components/carousel.svg',
  explain: '/img/components/explain/carousel.svg',
  description: "Ce composant permet d'ajouter les produits les plus populaire",
  nameId: 'Produits les plus populaires',
  type: 'mostPopular',
  init: { products: [] },
  renderEdit,
};

export default mostPopular;
