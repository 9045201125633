import React from 'react';
import { DropTarget as reactDndDropTarget } from 'react-dnd';
import styled from 'styled-components';

import DropTargetComponent from './dropTarget';

const DropComponent = ({ connectDropTarget, ...props }) => connectDropTarget(
  <div>
    <DndContainer
      isOver={props.isOver}
      className={`drop-target ${props.isOver ? 'drop-target--hovered' : ''}`}
    >
      <DropTargetComponent {...props} />
    </DndContainer>
  </div>,
);

// eslint-disable-next-line react/no-multi-comp
export const DndDropTarget = (props) => {
  const dropTargetSpec = {
    drop(ownProps, monitor) {
      console.log(monitor.getItem());
      props.componentDropCallback({ ...props, item: monitor.getItem() });
    },
  };
  const dropTargetCollect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  });
  const DecoratedDropComponent = reactDndDropTarget(
    'Component',
    dropTargetSpec,
    dropTargetCollect,
  )((res) => <DropComponent {...res} {...props} />);

  return React.createElement(DecoratedDropComponent, null, props.children);
};

const DndContainer = styled.div`
  background: ${(props) => (props.isOver ? '#5bfcc2' : 'none')};
  border: 1px dashed #5bfcc2;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70px;
`;
