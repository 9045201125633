import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { CarouselOrList } from '../CarouselOrSimpleList';
import { useGetRecommendedProducts } from 'src/data/accessor/useGetRecommendedProducts';

// Etant dans une fonctionnalité nécessitant un User, j'utilise le hook 'useGetLastOrders' pour faire un mock
export default function Recommend({ data }) {
  const { value: { title, description } } = data;
  const { productsRecommended } = useGetRecommendedProducts();

  if (!productsRecommended?.length) {
    return (
      <div style={{ margin: '10px' }}>
        <Skeleton variant="rect" width="100%" height={250} />
        <Skeleton width="50%" />
        <Skeleton width="60%" />
      </div>
    );
  }

  const titleDefault = 'Recommander 🔁';

  return (
    <CarouselOrList
      data={{
        products: productsRecommended,
        title: title || titleDefault,
        description,
      }}
    />
  );
};
