import { useState, useEffect } from 'react';

export function useFirebaseSnapshot<T extends any>(getDataOnSnapshot) {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let didCancel = true
    const unsuscribe = getDataOnSnapshot((d: T) => {
      if (didCancel) {
        setData(d);
        setLoading(false);
      }
    });

    return () => {
      unsuscribe()
      didCancel = false 
    }
    
  }, [getDataOnSnapshot]);

  return { data, loading };
}
