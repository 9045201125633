import React, { useContext } from 'react';
import { CategoriesHeader } from 'kards-components';
import { CategoriesHeaderText } from 'kards-components';
import { ContributionContext } from 'src/cards/edit/components/context/contribution';
import { ThemeContext } from 'src/cards/edit/components/context/theme';

const CategoryList = () => {
  const {
    cardData,
    currentCategory,
    action: {
      setCurrentCategory,
    },
  }: any = useContext(ContributionContext);
  const {
    theme,
  }: any = useContext(ThemeContext);

  return (
    <CategoriesHeader
      theme={theme}
      currentCategory={currentCategory}
      setCurrentCategory={setCurrentCategory}
      data={cardData}
    />
  );
};

export const CategoryListWithText = () => {
  const {
    cardData,
    currentCategory,
    action: {
      setCurrentCategory,
    },
  }: any = useContext(ContributionContext);
  const {
    theme,
  }: any = useContext(ThemeContext);

  return (
    <CategoriesHeaderText
      theme={theme}
      currentCategory={currentCategory}
      setCurrentCategory={setCurrentCategory}
      data={cardData}
    />
  );
};

export default CategoryList;
