import firebase from 'firebase/app';
import { Order } from '../types';
import { getNow } from '../../dashboard/utils';
import { toArray } from 'src/helpers/toArray';

export async function getOrdersByDate(from: number, to: number = getNow()) {
  const querySnapshot = await firebase
    .app()
    .firestore()
    .collection('orders')
    .where('created_at', '>=', from)
    .where('created_at', '<=', to)
    .orderBy('created_at', 'desc')
    .get();
  return toArray<Order>(querySnapshot);
}
