import React from 'react';
import MaterialModal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import firebase from 'firebase';
import { get } from 'lodash';

const ModalWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  height: 40vh;
  width: ${(props) => (props.mobile ? '80%' : '40%')};
  border-radius: 5px;
`;

const Text = styled.div`
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  font-family: ${(props) => props.family};
  padding: ${(props) => props.padding};
  padding-bottom: ${(props) => props.paddingBottom};
  white-space: ${(props) => props.whiteSpace};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.lettreSpacing};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TimeInput = withStyles({
  root: {
    width: '40%',
    '& input': {
      borderColor: '#222428',
      border: '2px solid',
      textAlign: 'center',
      color: '#222428',
      padding: '.5em 1em',
      borderRadius: '5px',
      fontSize: '1.6em',
    },
    '& ::before': {
      display: 'none',
    },
    '& ::after': {
      display: 'none',
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2,
    },
  },
})(TextField);

const getAverageTime = async () => {
  const data = await firebase
    .firestore()
    .collection('application')
    .doc('pwa')
    .get();
  return data.data();
};

export const AverageTime = ({
  IconConfirm = null,
  mobile = false,
  onAccept,
  order = false,
}) => {
  const [averageTime, setAverageTime] = React.useState();
  const [time, setTime] = React.useState();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    getAverageTime().then(({ averageTime }) => {
      setAverageTime(averageTime);
      setTime(averageTime)
    });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleAccept = () => {
    if (onAccept) onAccept({ averageTime: time });
    setAverageTime(time);
    return setOpen(false);
  };

  const ColorButton = withStyles(() => ({
    root: {
      textTransform: 'inherit',
      color: 'white',
      borderRadius: '30px',
      width: '60%',
      backgroundColor: '#6da993',
      boxShadow: '0 4px 6px rgba(0,0,0,.2)',
      '&:hover': {
        backgroundColor: '#6da993',
      },
    },
  }))(Button);

  const ColorButtonOrder = withStyles(() => ({
    root: {
      color: 'black',
      backgroundColor: '#5BFCC2 !important',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#6da993',
      },
    },
  }))(Button);

  const ButtonValide = withStyles(() => ({
    root: {
      color: 'black',
      borderRadius: '30px',
      width: '80%',
      backgroundColor: '#5bfcc2',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#5bfcc2',
      },
    },
  }))(Button);

  const ButtonClose = withStyles(() => ({
    root: {
      color: 'white',
      borderRadius: '30px',
      width: '80%',
      backgroundColor: '#222428',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#222428',
      },
    },
  }))(Button);

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {order
          ? (
            <ColorButtonOrder data-cy={`btn-next-step-${get(order, 'key')}`} key={`${open}`} onClick={handleOpen}>
              Prendre en charge
            </ColorButtonOrder>
          )
          : (
            <>
              <ColorButton key={`${open}`} onClick={handleOpen}>
                {averageTime || '00:00'} min
              </ColorButton>
              <Text color="black" size=".9em" weight="400" textAlign="center" width="70%" padding=".3em" lineHeight="1em">
                Tems moyen par commande
              </Text>
            </>
          )}
      </Box>
      <MaterialModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <>
          <ModalWrapper mobile={mobile}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Text color="black" size="1.5em" weight="300" textAlign="center" width="70%">
                  {order ? 'Renseignez le temps de préparation de cette commande.' : 'Renseignez le temps moyen de préparation de vos commande.'}
                </Text>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center" pt="2em" pb="2em">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <TimeInput data-cy="time-input" type="number" defaultValue={averageTime} onChange={(event) => setTime(get(event, 'target.value'))} />
                minutes
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" width="50%">
              <Box flex=".5" display="flex" justifyContent="center" alignItems="center">
                <ButtonValide
                  data-cy="btn-modal-confirm"
                  onClick={handleAccept}
                  startIcon={IconConfirm}
                >
                  Valider
                </ButtonValide>
              </Box>
              <Box flex=".5" display="flex" justifyContent="center" alignItems="center">
                <ButtonClose
                  onClick={handleClose}
                >
                  Fermer
                </ButtonClose>
              </Box>
            </Box>
          </ModalWrapper>
        </>
      </MaterialModal>
    </>
  );
};
