import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Circle from 'react-color/lib/Circle';
import { map, findKey, get } from 'lodash';
import { Field, useForm } from 'react-final-form';
import tinycolor from 'tinycolor2';
import { colors } from 'kards-components';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ImagesField, NumberInputField } from 'src/form/components/field/index';


const ColorField = styled.div`
  width: 15vw;
  height: 4vh;
  border-radius: 4px;
  margin: 5px;
  border: 1px solid #eeeeee;
  position: relative;
  display: flex;
  cursor: pointer;
  justify-content: center;
  outline: ${(props) => (props.current ? '#32a1ce solid 2px' : null)};
  background-color: ${(props) => props.background};
  align-items: center;
  :active,
  :cheched {
    border: solid greenyellow 2px;
  }
`;

const CircleWrapper = styled(Circle)`
  width: auto !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

export const ChoiceColorField = ({
  name, title, setColor, color,
}) => {
  const current = name === get(color, 'name');
  return (
    <Field
      name={name}
      render={({ input }) => (
        <>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => setColor(input)}
          >
            <ColorField background={colors[input.value]} current={current}>
              <p
                style={{
                  fontSize: '1em',
                  textAlign: 'center',
                  color: tinycolor(colors[input.value]).isLight()
                    ? 'black'
                    : 'white',
                }}
              >
                {title}
              </p>
            </ColorField>
          </div>
        </>
      )}
    />
  );
};

export const ActiveHeaderField = ({ name }) => (
  <Field
    name={name}
    render={({ input }) => (
      <FormControlLabel
        control={<Checkbox checked={input.value} onChange={input.onChange} value={input.value} />}
        label="Activer"
      />
    )}
  />
);

function BtnUpload({ handleOpen = null }) {
  return (
    <Button onClick={() => handleOpen()} variant="outlined" color="primary">
      Charger un logo
    </Button>
  );
}

export default function ParameterTheme() {
  const form = useForm();
  const [color, setColor] = React.useState({ onChange: (props) => console.log(props, 'empty') });
  return (
    <Box flex={1} display="flex" justifyContent="space-between" alignItems="center" flexDirection="row" overflow="hidden">
      <Box height="100%" overflow="scroll">
        <Box
          padding={1}
          borderRadius="borderRadius"
          margin="1em"
          fontWeight="bold"
          fontSize="1.3em"
          textAlign="center"
        >
          En-tête
        </Box>
        <Box flex="0.4">
          <ImagesField
            type="original"
            name="theme.header.logo"
            title="Image du logo"
            subtitle="Le format original est pris en compte pour ce format"
            onAccept={(picture) => form.mutators.setValue('theme.header.logo', picture)}
          >
            <BtnUpload />
          </ImagesField>
        </Box>
        <NumberInputField
          label="Hauteur du logo"
          name="theme.header.logoSizeHeight"
          pixel
        />
        <NumberInputField
          label="Espace du logo haut"
          name="theme.header.logoPaddingTop"
          pixel
        />
        <NumberInputField
          label="Espace du logo bas"
          name="theme.header.logoPaddingBottom"
          pixel
        />
        <ChoiceColorField
          color={color}
          setColor={setColor}
          title="Fond de l'en-tête"
          name="theme.color.backgroundHeader"
        />
        <ChoiceColorField
          color={color}
          setColor={setColor}
          title="Couleur bouton en-tête"
          name="theme.color.buttonHeaderColor"
        />
        <Box
          padding={1}
          borderRadius="borderRadius"
          margin="1em"
          fontWeight="bold"
          fontSize="1.3em"
          textAlign="center"
        >
          Pied de page
        </Box>
        <ChoiceColorField
          color={color}
          setColor={setColor}
          title="Fond du pied de page"
          name="theme.color.backgroundFooter"
        />
        <ChoiceColorField
          color={color}
          setColor={setColor}
          title="Texte commander"
          name="theme.color.buttonOrder"
        />
        <Box
          padding={1}
          borderRadius="borderRadius"
          margin="1em"
          fontWeight="bold"
          fontSize="1.3em"
          textAlign="center"
        >
          Couleurs générales
        </Box>
        <ChoiceColorField
          color={color}
          setColor={setColor}
          title="Fond principal"
          name="theme.color.background"
        />
        <ChoiceColorField
          color={color}
          setColor={setColor}
          title="Titre"
          name="theme.color.title"
        />
        <ChoiceColorField
          color={color}
          setColor={setColor}
          title="Sous-titre"
          name="theme.color.subtitle"
        />
        <Box
          padding={1}
          borderRadius="borderRadius"
          margin="1em"
          fontWeight="bold"
          fontSize="1.3em"
          textAlign="center"
        >
          Couleurs des produits
        </Box>
        <ChoiceColorField
          color={color}
          setColor={setColor}
          title="Fond produits carrousel"
          name="theme.color.productBackground"
        />
        <ChoiceColorField
          color={color}
          setColor={setColor}
          title="Titre des produits"
          name="theme.color.productTitle"
        />
        <ChoiceColorField
          color={color}
          setColor={setColor}
          title="Description des produits"
          name="theme.color.productDescription"
        />
        <ChoiceColorField
          color={color}
          setColor={setColor}
          title="Prix des produits"
          name="theme.color.productPrice"
        />
        <ChoiceColorField
          color={color}
          setColor={setColor}
          title="Fond des prix"
          name="theme.color.backgroundPrice"
        />
      </Box>
      <Box>
        <Box
          padding={1}
          bgcolor="primary.light"
          borderRadius="borderRadius"
          margin="1em"
        >
          <CircleWrapper
            colors={map(colors, (value) => value)}
            onChange={(c) => {
              if (color) color.onChange(findKey(colors, (color) => color === c.hex));
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
