import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { setCashRegister } from 'src/data/accessor/cashRegister';
import { useFirebaseSnapshot } from 'src/helpers/useFireabseOnSnapshop';
import {
  fetchStart, fetchEnd, useRedirect, useRefresh,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';

function getProducts(cb) {
  return firebase
    .app()
    .firestore()
    .collection('products')
    .onSnapshot((querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      cb(data);
    });
}

type ImportInProgressProps = {
  children: React.ReactNode
}

export function ImportInProgress({ children }: ImportInProgressProps) {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const { data: products = [] } = useFirebaseSnapshot(getProducts);

  useEffect(() => {
    dispatch(fetchStart());

    return () => {
      dispatch(fetchEnd());
      refresh();
    };
  }, [dispatch, redirect]);

  const reset = () => {
    setCashRegister({ step: null, name: null, credentials: null });
  };

  return (
    <>
      <Box
        p={2}
        position="absolute"
        width="370px"
        zIndex="50000"
        bgcolor="#222428"
        right="2%"
        top="7%"
        borderRadius="10px"
        boxShadow="0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="1.2em"
          fontWeight="400"
          maxWidth="1000px"
          color="white"
        >
          Import des produits depuis votre caisse en cours ...
          <br />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="1em"
          fontWeight="300"
          maxWidth="1000px"
          color="white"
        >
          Nombre de produits importés
          {' '}
          {products.length}
          <br />
        </Box>
        <Box display="flex" justifyContent="center" p="1em">
          <Button onClick={reset} variant="contained">
            Annuler
          </Button>
        </Box>
      </Box>
      {children}
    </>
  );
}
