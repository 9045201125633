import React from 'react';
import { map, sortBy } from 'lodash';
import ItemOrder from '../list/ItemOrder';

const ListOrders = ({ orders, callbackAction }) => (
  <>
    {map(
      sortBy(orders, (i) => i.created_at),
      (item) => <ItemOrder key={item.id} item={item} callbackAction={callbackAction} />,
    )}
  </>
)

export default ListOrders;
