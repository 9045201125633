import React from 'react';
import AsyncSelect from 'react-select/async';
import firebase from 'firebase/app';
import { Box, Typography } from '@material-ui/core';

function tillerProductToOptions(productByCategories: any) {
  return productByCategories.map(productByCategory => ({
    type: 'tiller',
    label: productByCategory.name,
    options: productByCategory.products.map(p => ({
      label: p.name,
      value: p.id,
      ...p,
      categoryProduct: productByCategory,
    })),
  }));
}

function zeltyProductToOptions(products) {
  return products.map(product => ({
    label: product.name,
    value: product.id,
    name: product.name,
    description: product.description,
    price: product.price,
    tva: product.tva,
    id: product.id,
    type: 'zelty',
  }));
}

async function getProducts() {
  const getCashRegisterProducts = firebase
    .app()
    .functions()
    .httpsCallable('getCashRegisterProducts');
  const result = await getCashRegisterProducts();
  const { name, data } = result.data;

  switch (name) {
    case 'tiller':
      const options = tillerProductToOptions(data);
      return options;
    case 'zelty':
      return zeltyProductToOptions(data);
    default:
      return [];
  }
}

function formatGroupLabel(data) {
  if (data.type === 'zelty') {
    return <span>{data.label}</span>;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <span>{data.label}</span>
      <div>
        <Box
          bgcolor="#EBECF0"
          borderRadius="2em"
          color="#172B4D"
          display="inline-block"
          fontSize="12"
          minWidth="1"
          padding="0.16666666666667em 0.5em"
          textAlign="center"
        >
          {data.options.length}
        </Box>
      </div>
    </Box>
  );
}

export function SearchProductCashRegister(props) {
  return (
    <Box mb={1}>
      <Typography style={{ fontWeight: 200 }}>Rechercher un produit depuis votre caisse</Typography>
      <AsyncSelect
        cacheOptions
        defaultOptions
        formatGroupLabel={formatGroupLabel}
        loadOptions={getProducts}
        placeholder="Rechercher ..."
        {...props}
      />
    </Box>
  );
}
