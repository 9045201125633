/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { get } from 'lodash';
import styled from 'styled-components';
import {
  IonButton,
} from '@ionic/react';
import { updateStepOrder } from 'src/data/accessor/updateStepOrder';
import { ConfirmButton } from 'src/layout/Buttons';
import { ModalResumeOrder } from './ModalResumeOrder';
import { getCurrentStep } from '../../flow';

const ContainerCommande = styled.div`
  width: ${(props) => (props.type === 'ready' ? '45%' : '98%')};
  height: ${(props) => (props.type === 'ready' ? '10em' : '5em')};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin: 0.5em;
  background-color:${(props) => props.color};
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${(props) => (props.type === 'ready' ? '.5em' : '0')};
`;

const Bold = styled.span`
  font-size: 1.2em;
  font-weight: bold;
`;

const ContainerInfo = styled.div`
  display: flex;
  justify-content: ${(props) => (props.type === 'ready' ? 'center' : 'space-around')};
  flex-direction: ${(props) => (props.type === 'ready' ? 'column' : 'row')};
  align-items: center;
  width: 100%;
`;

const ContainerButtons = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const IonbuttonWrapper = styled(IonButton)`
  --padding-start: 0;
  --padding-end: 0;
  width: 45%;
`;

const ButtonNextStep = ({ order, callbackAction }) => {
  const currentStep = getCurrentStep(order);
  const updatedItem = async () => {
    const newOrder = await updateStepOrder(order);
    if (callbackAction) callbackAction({ ...newOrder });
  };
  if (currentStep === 'ready') {
    return (
      <ConfirmButton
        mobile
        onAccept={updatedItem}
        OpenButton={
          (props) => (
            <IonbuttonWrapper
              color="primary"
              size="small"
              onClick={props.handleOpen}
              variant="contained"
              data-cy={`btn-next-step-${get(order, 'key')}`}
            >
              Livrer
            </IonbuttonWrapper>
          )
        }
      />
    );
  }
  return null;
};

const ButtonDetail = ({ order }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IonbuttonWrapper size="small" variant="contained" color="warning" onClick={() => setOpen(true)}>
        Detail
      </IonbuttonWrapper>
      <ModalResumeOrder order={order} open={open} handleClose={() => setOpen(false)} />
    </>
  );
};

const ItemOrder = ({ item, callbackAction, type }) => {
  const [checked, setChecked] = useState(false);
  return (
    <ContainerCommande
      type={type}
      color={checked ? '#9bff9b' : 'white'}
      data-cy={`item-order-${get(item, 'key')}`}
    >
      <ContainerInfo type={type} onClick={() => setChecked(!checked)}>
        <Info type={type}>
          n° : &nbsp;
          <Bold data-cy={`order-number-${get(item, 'key')}`}>{get(item, 'key')}</Bold>
        </Info>
        <Info type={type}>
          table° :&nbsp;
          <Bold>{get(item, 'parameters.onplace_number')}</Bold>
        </Info>
      </ContainerInfo>
      <ContainerButtons>
        <ButtonDetail order={item} />
        <ButtonNextStep order={item} callbackAction={callbackAction} />
      </ContainerButtons>
    </ContainerCommande>
  );
};

export default ItemOrder;
