import { componentType } from 'kards-components';

import Carousel from './Carousel';
import Offer from './Offer';
import Product from './Product';
import SimpleList from './SimpleList';
import Separator from './Separator';
import Image from './Image';
import Video from './Video';
import Wheel from './Wheel';
import ImageSlides from './ImageSlides';
import Social from './Social';
import FlappyBird from './FlappyBird';
import MostPopular from './MostPopular';
import LastOrders from './LastOrders';
import Recommand from './Recommand';

type ComponentType =
  | 'suggestionsOnWeather'
  | 'weather'
  | 'simpleList'
  | 'product'
  | 'separator'
  | 'carousel'
  | 'category'
  | 'galleryCategory'
  | 'galleryCategoryInline'
  | 'image'
  | 'slide'
  | 'video'
  | 'wheel'
  | 'social'
  | 'offer'
  | 'flappyBird'
  | 'mostPopular'
  | 'lastOrders'
  | 'recommand';

export const getBox = (type: ComponentType) => {
  switch (type) {
    case 'recommand':
      return Recommand;
    case 'lastOrders':
      return LastOrders;
    case 'mostPopular':
      return MostPopular;
    case componentType.Carousel:
      return Carousel;
    case componentType.Offer:
      return Offer;
    case componentType.Product:
      return Product;
    case componentType.List:
      return SimpleList;
    case componentType.Separator:
      return Separator;
    case componentType.Image:
      return Image;
    case componentType.Video:
      return Video;
    case componentType.Wheel:
      return Wheel;
    case componentType.Slide:
      return ImageSlides;
    case componentType.Social:
      return Social;
    case componentType.FlappyBird:
      return FlappyBird;
    default:
  }
};
