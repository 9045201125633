import firebase from 'firebase/app';

type PwaType = {
  active: boolean;
  averageTime: string;
  cardId: string;
  compiledData: object;
  id: string;
  lastupdate: number;
  screenOff: string;
  start: boolean;
  updatedby: string;
  version: string;
};

export const getCurrentPublishedCard = async () => {
  const defaultFirestore = firebase.app().firestore();
  return defaultFirestore
    .collection('application')
    .doc('pwa')
    .get()
    .then(res => {
      const pwa = res.data();
      return pwa as PwaType;
    });
};
