import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { linkToRecord } from 'ra-core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import get from 'lodash/get';
// import moment from 'moment';
import { Button } from '@material-ui/core';

import { SpeedDialWithAction } from 'src/data/injector/SpeedDial';
import { SettingOfferButton } from 'src/layout/Buttons';

import 'moment/min/locales';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
  margin: .5em 2em;
  border-bottom: solid 1px ${(props) => props.borderColor};
  min-height: 4em;
`;

const Picture = styled.img`
  height: 3em;
  width: 4em;
  border-radius: 3px;
  `;

const LinkWrapper = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex: .5;
  text-decoration: none;
  color: inherit;
`;

const ContainerSpeedDial = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  flex:.2;
`;

const ContainerStock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
  flex: .25;
`;

export const RowOffer = ({
  data,
  basePath,
  duplicateData,
  resource,
}) => {
  const theme = useTheme();
  return (
    <Row borderColor={theme.palette.primary.main}>
      <LinkWrapper
        to={linkToRecord(basePath, data.id)}
        data-cy={`item-list-${data.id}`}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '.15',
        }}
        >
          <Picture src={get(data, 'picture')} />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '.8',
        }}
        >
          <Typography
            gutterBottom
            component="h2"
            style={{
              fontSize: '1.3em',
              lineHeight: '1.1em',
              marginBottom: '.2em',
            }}
          >
            {get(data, 'reference')}
          </Typography>
          {/* <Typography
            style={{ color: 'rgb(200,200,200)' }}
            variant="body2"
            component="p"
            gutterBottom
          >
            Dernière mise à jour le
            {' '}
            {moment(get(data, 'lastupdate')).format('DD.MM.YY')}
          </Typography> */}
        </div>
      </LinkWrapper>
      <ContainerStock>
        <Box ml="2em">
          <SettingOfferButton
            offer={data}
            OpenButton={
              (props) => (
                <Button size="small" variant="contained" color="primary" onClick={props.handleOpen}>
                  Réglage
                </Button>
              )
            }
          />
        </Box>
      </ContainerStock>
      <ContainerSpeedDial>
        <SpeedDialWithAction
          resource={resource}
          id={data.id}
          duplicateData={duplicateData}
          direction="left"
        >
          <Box margin="20px" textAlign="center">
            Voulez vous vraiment supprimer cet element?
            <br />
            {resource === 'products'
              && 'Attention celà peut avoir un impact si le produit est présent dans l\'une de vos cartes'}
          </Box>
        </SpeedDialWithAction>
      </ContainerSpeedDial>
    </Row>
  );
};
