import React, { useEffect } from 'react';
import { FormWithRedirect } from 'react-admin';
import { Box, Button } from '@material-ui/core';
import { setCashRegister } from 'src/data/accessor/cashRegister';
import arrayMutators from 'final-form-arrays';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MainScreen from 'src/layout/Screen/Main';
import { FielMiddayNight, ArrayContractor, TextInputField, ImagesField, PrinterFieldArrayField } from 'src/form/components/field/index';
import get from 'lodash/get';
import { Tooltip } from 'src/layout/Tooltip';
import Toolbar from '../bottomBar/index';
import { TabPanel } from './TabPanel';
import { ChangeCashRegister } from 'src/cash-register/CurrentCashRegister';
import { useField } from 'react-final-form';

export default function Form({ ...props }) {
  const [value, setValue] = React.useState('zero');

  const initialValues = {
    contractors: get(props, 'record.contractors'),
    open: {
      monday: {
        midday: {
          start: get(props, 'record.open.monday.midday.start') || '10:00',
          end: get(props, 'record.open.monday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.monday.night.start') || '17:00',
          end: get(props, 'record.open.monday.night.end') || '22:00',
        },
      },
      tuesday: {
        midday: {
          start: get(props, 'record.open.tuesday.midday.start') || '10:00',
          end: get(props, 'record.open.tuesday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.tuesday.night.start') || '17:00',
          end: get(props, 'record.open.tuesday.night.end') || '22:00',
        },
      },
      wednesday: {
        midday: {
          start: get(props, 'record.open.wednesday.midday.start') || '10:00',
          end: get(props, 'record.open.wednesday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.monday.night.start') || '17:00',
          end: get(props, 'record.open.monday.night.end') || '22:00',
        },
      },
      thursday: {
        midday: {
          start: get(props, 'record.open.thursday.midday.start') || '10:00',
          end: get(props, 'record.open.thursday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.thursday.night.start') || '17:00',
          end: get(props, 'record.open.thursday.night.end') || '22:00',
        },
      },
      friday: {
        midday: {
          start: get(props, 'record.open.friday.midday.start') || '10:00',
          end: get(props, 'record.open.friday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.friday.night.start') || '17:00',
          end: get(props, 'record.open.friday.night.end') || '22:00',
        },
      },
      saturday: {
        midday: {
          start: get(props, 'record.open.saturday.midday.start') || '10:00',
          end: get(props, 'record.open.saturday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.saturday.night.start') || '17:00',
          end: get(props, 'record.open.saturday.night.end') || '22:00',
        },
      },
      sunday: {
        midday: {
          start: get(props, 'record.open.sunday.midday.start') || '10:00',
          end: get(props, 'record.open.sunday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.sunday.night.start') || '17:00',
          end: get(props, 'record.open.sunday.night.end') || '22:00',
        },
      },
    },
    logo: props?.record?.logo,
    phone: props?.record?.phone,
    printers: props?.record?.printers,
  };

  useEffect(() => {
    return () => setCashRegister({ step: 'done' });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <FormWithRedirect
      key={props.version}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          console.log(field, value);
          changeValue(state, field, () => value);
        },
      }}
      render={({ form }) => {
        return (
          <form>
            <MainScreen
              data_cy_title="title-edit-application"
              data_cy_subtitle="subtitle-edit-application"
              title="Configuration générale"
              subtitle="Réglages de l'application"
              flex={1}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab value="zero" label="Profil" />
                <Tab value="one" label="Horaire" />
                <Tab value="two" label="Fournisseurs" />
                <Tab value="three" label="Caisse" />
                <Tab value="four" label="Imprimantes" />
              </Tabs>
              <TabPanel value={value} index="zero">
                <Box p={2}>
                  <TextInputField label="Phone" name="phone" />
                  <LogoField name="logo" />
                </Box>
              </TabPanel>
              <TabPanel value={value} index="one">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  borderBottom="solid #5bfcc2"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    width="90%"
                    fontSize="1.2em"
                    fontWeight="400"
                    maxWidth="1000px"
                  >
                    Réglage des créneaux horaires d'ouverture
                    <Tooltip text="Ces creneaux servent a 2 choses, premierement définir les creneaux de retrait pour les precommandes, deuxiemement definir les heures de disponibilité de vos produit sur place" />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="90%"
                    margin="2em"
                    maxWidth="1000px"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      pr="0px"
                      pl="0px"
                      pb=".5em"
                      mb=".5em"
                      width="100%"
                      alignItems="center"
                      justifyContent="flex-start"
                      borderBottom="solid 2px #222428"
                    >
                      <Box flex=".1" />
                      <Box flex=".9" display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                        <Box flex=".5" display="flex" alignItems="center" justifyContent="center" fontSize="1.2em" fontWeight="400">
                          Midi
                        </Box>
                        <Box flex=".5" display="flex" alignItems="center" justifyContent="center" fontSize="1.2em" fontWeight="400">
                          Soir
                        </Box>
                      </Box>
                    </Box>
                    <FielMiddayNight pb=".5em" mb=".5em" name="open.monday" label="Lundi" />
                    <FielMiddayNight pb=".5em" mb=".5em" name="open.tuesday" label="Mardi" />
                    <FielMiddayNight pb=".5em" mb=".5em" name="open.wednesday" label="Mercredi" />
                    <FielMiddayNight pb=".5em" mb=".5em" name="open.thursday" label="Jeudi" />
                    <FielMiddayNight pb=".5em" mb=".5em" name="open.friday" label="Vendredi" />
                    <FielMiddayNight pb=".5em" mb=".5em" name="open.saturday" label="Samedi" />
                    <FielMiddayNight pb=".5em" mb=".5em" name="open.sunday" label="Dimanche" />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index="two">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  borderBottom="solid #5bfcc2"
                >
                  <Box
                    width="90%"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    fontSize="1.2em"
                    fontWeight="400"
                    maxWidth="1000px"
                  >
                    Vos fournisseurs
                    <Tooltip text="Vos fournisseurs pourons être ajoutés aux paramètres de vos produits. Cette donnée sera utilisée dans l'export de donnée de l'écran “Tableau de bord“." />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="90%"
                    margin="2em"
                    maxWidth="1000px"
                  >
                    <ArrayContractor name="contractors" label="Ajouter un fournisseur" />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index="three">
                <ChangeCashRegister />
              </TabPanel>
              <TabPanel value={value} index="four">
                <PrinterFieldArrayField />
              </TabPanel>
            </MainScreen>
            <Box bgcolor="primary.main" flex={0.05} display="flex" justifyContent="space-between" alignItems="center">
              <Toolbar resource={props.resource} />
            </Box>
          </form>
        );
      }}
    />
  );
}

function BtnUpload({ handleOpen = null }) {
  return (
    <Button onClick={() => handleOpen()} variant="outlined" color="primary">
      Charger un logo
    </Button>
  );
}

function LogoField({ name }) {
  const { input } = useField(name);

  return (
    <Box display="flex">
      {input?.value && <img src={input?.value} width="120px" style={{ marginRight: '12px' }} />}
      <ImagesField
        type="original"
        name={name}
        title="Image du logo"
        subtitle="Le format original est pris en compte pour ce format"
        onAccept={picture => input.onChange(picture)}
      >
        <BtnUpload />
      </ImagesField>
    </Box>
  );
}
