import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import arrayMove from 'array-move';
import Typography from '@material-ui/core/Typography';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { Field } from 'react-final-form';
import {
  AddListproducts,
  DeleteButton,
} from 'src/layout/Buttons';
import { usePopulateProducts, usePopulateProduct } from 'src/data/hooks';

const ListItem = styled.li`
  align-items: center;
  background: white;
  display: flex;
  flex: 1;
  border-radius: 5px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin: 5px;
  flex-direction: row;
  height: 2.5em;
  justify-content: space-between;
  list-style: none;
  padding: 5px;
`;

const DragZone = styled.div`
  height: 100%;
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.1;
  :active {
    cursor: grabbing;
  }
`;

const Dots = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: black;
  box-shadow: 0px -9px 0px black, 0px 9px 0px black;
`;

const TitleZone = styled.div`
  flex: 0.9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ContainerItem = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 10px;
  width: 100%;
`;

const ContainerButton = styled.div`
  margin-top: 10px;
`;

const SortableItems = SortableContainer(({ children }) => (
  <ul
    style={{
      padding: 0,
      margin: 0,
      marginTop: '10px',
      width: '100%',
      overflow: 'scroll',
      maxHeight: '30vh',
    }}
  >
    {children}
  </ul>
));

const DragHandle = SortableHandle(() => (
  <DragZone>
    <Dots />
  </DragZone>
));

const SortableItem = SortableElement(
  ({
    position, pop, name, itemsLength, onChange,
  }) => (
      <ItemMany
        onChange={onChange}
        itemsLength={itemsLength}
        name={name}
        position={position}
        pop={pop}
      />
    ),
);

const ItemMany = ({ name, pop, position }) => (
  <ListItem>
    <DragHandle />
    <TitleZone>
      <Typography>{name}</Typography>
    </TitleZone>
    <DeleteButton
      onAccept={() => pop(position)}
      OpenButton={(props) => (
        <div style={{ marginRight: '10px' }} onClick={props.handleOpen}>
          <img width="10px" alt="delete" src="/img/icon/delete.svg" />
        </div>
      )}
    />
  </ListItem>
);

const Item = ({ name }) => (
  <ListItem>
    <TitleZone>
      <Typography>{name}</Typography>
    </TitleZone>
  </ListItem>
);

const ProductsArrayComponent = ({ value, pop, move }) => {
  const { data, setData } = usePopulateProducts({ products: value });
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const arrayMoveItem = [...arrayMove(data, oldIndex, newIndex)];
    if (oldIndex !== newIndex) setData(arrayMoveItem);
    move(oldIndex, newIndex);
  };

  return (
    <>
      {data && data.length > 0 && (
        <SortableItems
          useDragHandle
          pressDelay={150}
          helperClass="sortableHelper"
          axis="y"
          onSortEnd={onSortEnd}
        >
          {data.map((product, index) => (
            <SortableItem
              itemsLength={data.length}
              index={index}
              position={index}
              key={product.id}
              pop={pop}
              name={product.reference}
            />
          ))}
        </SortableItems>
      )}
    </>
  );
};

const ProductComponent = ({ value, pop }) => {
  const { data } = usePopulateProduct({ product: value });
  return (
    <>
      {data && data.reference && (
        <ContainerItem>
          <Item name={data.reference} pop={pop} />
        </ContainerItem>
      )}
    </>
  );
};

const ListProduct = ({
  name, many, title, subtitle,
}) => (
    <Field
      name={name}
      render={({ input }) => (
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          alignItems="center"
          width="100%"
          pb="2em"
        >
          <ContainerButton>
            <AddListproducts
              title={title}
              subtitle={subtitle}
              many={many}
              initialData={input.value}
              onAccept={(itemsToAdd) => {
                if (many) input.onChange(itemsToAdd);
                if (!many) input.onChange(itemsToAdd);
              }}
              OpenButton={(props) => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props.handleOpen}
                  data-cy="btn-add-products"
                >
                  {!many ? 'Ajouter un produit' : 'Ajouter des produits'}
                </Button>
              )}
            />
          </ContainerButton>

          {many ? (
            <ProductsArrayComponent
              pop={(position) => {
                input.value.splice(position, 1);
                input.onChange([...input.value]);
              }}
              move={(from, to) => {
                const array = arrayMove(input.value, from, to);
                input.onChange(array);
              }}
              value={input.value}
            />
          ) : (
              <ProductComponent value={input.value} />
            )}
        </Box>
      )}
    />
  );

export default ListProduct;
