import React, { useContext } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Image } from 'kards-components';
import { ThemeContext } from 'src/cards/edit/components/context/theme';

export default ({
  data: {
    title,
    description,
    picture,
    link,
    full,
  },
}) => {
  const {
    theme,
  }: any = useContext(ThemeContext);
  if (!picture) {
    return (
      <div style={{ margin: '10px' }}>
        <Skeleton variant="rect" width="100%" height={250} />
        <Skeleton width="50%" />
        <Skeleton width="60%" />
      </div>
    );
  }
  return (
    <Image
      theme={theme}
      title={title}
      link={link}
      description={description}
      picture={picture}
      full={full}
      dev
    />
  );
};
