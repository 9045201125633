import React from 'react';
import styled from 'styled-components';
import { ImagesField } from 'src/form/components/field';
import { Button } from '@material-ui/core';

const OpenBtn = ({ handleOpen }) => (
  <Button
    variant="contained"
    color="primary"
    onClick={handleOpen}
  >
    Selectionner une image
  </Button>
);

const EditWindow = ({ setFieldValue }) => (
  <FlexContainer>
    <p> Choisissez l'image de fond </p>
    <ImagesField
      name="background"
      type="rectangle"
      title="Ajouter un fond d'ecran"
      subtitle="Sélectionnez l'image que vous souhaitez afficher en fond d'écran"
      onAccept={(background) => setFieldValue('background', background)}
    >
      <OpenBtn />
    </ImagesField>
  </FlexContainer>
);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;

export default EditWindow;
