import React from 'react';
import { map, sortBy } from 'lodash';
import ItemOrder from './ItemOrder';

const ListOrders = (props) => (
  <>
    {map(
      sortBy(props.data, (i) => i.created_at),
      (item) => <ItemOrder key={item.id} item={item} type={props.type} />,
    )}
  </>
)

export default ListOrders;
