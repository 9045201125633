import React from 'react';
import { useFormState } from 'react-final-form';
import { useRedirect, useNotify, useDataProvider } from 'react-admin';
import { TransparentBtn } from 'src/layout/Buttons';

export default ({ resource, basePath }) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const formState = useFormState();
  const handleClick = () => {
    if (!formState.valid) {
      return;
    }
    return dataProvider
      .create(resource, { data: formState.values })
      .then(() => {
        notify('ra.notification.created', 'info', {
          smart_count: 1,
        });
        // eslint-disable-next-line
        basePath && redirectTo('list', basePath);
      })
      .catch(() => { });
  };

  return (
    <TransparentBtn variant="extended" onClick={handleClick} data-cy="btn-create-resource">
      Enregistrer
    </TransparentBtn>
  );
};
