import React from 'react';
import Edit from './Edit';

const renderEdit = () => <Edit edit />;

const flappy = {
  icone: '/img/components/flappy.svg',
  description: 'Ce composant permet de jouer a flappy bird',
  explain: '/img/components/explain/product.svg',
  nameId: 'Flappybird',
  type: 'flappyBird',
  renderEdit,
};

export default flappy;
